import React, {useState, useEffect} from 'react';
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import Moment from "moment/moment";
import {useSelector} from "react-redux";

const DetailComment = () => {

    const [comment, setComment] = useState("")

    const { id } = useParams();

    const getSingleComment = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`comment/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        setComment(data)

    }

    useEffect(()=>{
        getSingleComment()
    }, [])

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin
    let redirect = ''
    if (userInfo){
        if (userInfo.group === "AdminSuper"){
            redirect = '/active_work_orders'
        } else if (userInfo.group === "Finance") {
            redirect = '/finance_active_work_orders'
        } else if (userInfo.group === "Logistics") {
            redirect = '/logistics_active-work_orders'
        } else if (userInfo.group === "Tech") {
            redirect = '/tech_work_orders'
        }
    }


    return (
        <div className={"banner_heading"}>
            <Link style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop:'5px'
            }} className={'btn btn-primary'} to={redirect}>Active Work Orders Dashboard</Link>
            <br />
            <br />


            <h1>Comments Detail: </h1>
            <div>
                <p>Created: {Moment(comment.priority_date).format("MM/DD/YYYY,  hh:mm:ss a")}</p>
                <p>Technician Comment: {comment.tech_hour_comments}</p>
                <p>Office Comments: {comment.office_hour_comments}</p>
                <br />
                <br />
                <Link className={'btn btn-primary'} to={`/comments/update/${comment.client}/`}>Update Comment</Link>
            </div>
        </div>
    )
}

export default DetailComment