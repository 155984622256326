import React from "react";
import {Row, Col, Card, Dropdown,} from "react-bootstrap";

function FinanceReports() {

    return (
        <div>
            <h1 className={"banner_heading"}>Finance Reports Dash</h1>
            <br />
            <Row>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>All Invoice Reports</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Line item reports of invoices paid, not paid in full, and custom reports
                            </Card.Text>
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    List of all Invoice Reports
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="/paid_invoices">Paid Invoices</Dropdown.Item>
                                    <Dropdown.Item href="/invoiced_jobs_not_paid">Unpaid Invoices</Dropdown.Item>
                                    <Dropdown.Item href="/custom_invoice_report">Custom Invoice Report</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>All Proposal Reports</Card.Header>
                        <Card.Body>
                            <Card.Text>
                               Line item reports for signed, non-signed, approved, and non-approved jobs with and custom proposal reports
                            </Card.Text>
                            <Dropdown>
                                <Dropdown.Toggle variant="info" id="dropdown-basic">
                                    List of all Proposal Reports
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="/signed_proposals">Signed Proposals</Dropdown.Item>
                                    <Dropdown.Item href="/proposals_not_signed">Non-signed Proposals & Unapproved Jobs</Dropdown.Item>
                                    <Dropdown.Item href="/approved_jobs">Approved Jobs</Dropdown.Item>
                                    <Dropdown.Item href="/custom_proposals">Custom Proposal Report</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>All Service Order Reports</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Line item reports for open, closed and custom service order reports.
                            </Card.Text>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    List of all Service Order Reports
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="/open_service_order_report">Open Service Order Report</Dropdown.Item>
                                    <Dropdown.Item href="/closed_service_orders_report">Closed Service Orders</Dropdown.Item>
                                    <Dropdown.Item href="/custom_service_order_report">Custom Service Order Report</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
        </div>
    )
}

export default FinanceReports