import React from "react";
import {Row, Col, Card, Button,} from "react-bootstrap";
import { useNavigate } from 'react-router-dom'

function AdminDash() {

    let navigate = useNavigate();

    return (
        <div>
            <h1 className={"banner_heading"}>Admin Dash</h1>
            <br />
            <Row>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Operations Dash</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to the operations Dash</Card.Title>
                            <Card.Text>
                                Controls Managing Work orders including creating clients, active work orders, and finished work orders
                            </Card.Text>
                            <Button variant="primary"
                            onClick={ () => navigate('/operations_dash')}
                            >Operations</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Finance Dash</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to the Finance Dash</Card.Title>
                            <Card.Text>
                                Access to the Reporting dash, active work orders, closed work orders, and calender.
                            </Card.Text>
                            <Button variant="primary"
                            onClick={ () => navigate('/finance_dash')}
                            >Finance</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Logistics Dash</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to the Logistics Dash</Card.Title>
                            <Card.Text>
                                Access to Unscheduled Work Orders, Scheduled Work Orders, Calendar for assigned employees, and Calender for scheduled Jobs.
                            </Card.Text>
                            <Button variant="primary"
                            onClick={ ()=> navigate('/logistics_dash')}
                            >Logistics</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <br /> <br />

            <Row>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Safety Dash</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to the Safety Dash</Card.Title>
                            <Card.Text>
                                Access to Safety Data Sheets, employee training records, incident reports
                            </Card.Text>
                            <Button variant="primary"
                            onClick={ ()=> navigate('/safety_dash')}
                            >Safety</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Inventory Dash</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to the Inventory Dash</Card.Title>
                            <Card.Text>
                                Access to... TBD
                            </Card.Text>
                            <Button variant="primary"
                            onClick={ ()=> navigate('/inventory_dash')}
                            >Inventory</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Tech Dash</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to the Tech Dash</Card.Title>
                            <Card.Text>
                                Access to Tech Dash where they have current work orders, service orders and calender.
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ ()=> navigate('/tech_dash')}
                            >Tech</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <br /><br />

            <Row>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Job Status</Card.Header>
                        <Card.Body>
                            <Card.Title>Quick Status Report</Card.Title>
                            <Card.Text>
                                Access to Quick Status Reports
                            </Card.Text>
                            <Button variant="primary" onClick={ ()=> navigate('/status_reports')}>Status Report</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default AdminDash

