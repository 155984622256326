import React, {useState, useEffect} from 'react';
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import Moment from "moment/moment";
import {useSelector} from "react-redux";

const DetailPriority = () => {

    const [priority, setPriority] = useState("")

    const { id } = useParams();
    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const getSinglePriority = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`priority_reports/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        setPriority(data)

    }

    useEffect(()=>{
        getSinglePriority()
    }, [])


    let redirect = ''
    if (userInfo){
        if (userInfo.group === "AdminSuper"){
            redirect = '/active_work_orders'
        } else if (userInfo.group === "Finance") {
            redirect = '/finance_active_work_orders'
        } else if (userInfo.group === "Logistics") {
            redirect = '/logistics_active-work_orders'
        } else if (userInfo.group === "Tech") {
            redirect = '/tech_work_orders'
        }
    }


    return (
        <div className={"banner_heading"}>
            <Link style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop:'5px'
            }} className={'btn btn-primary'} to={redirect}>Active Work Orders Dashboard</Link>
            <br />
            <br />


            <h1>Priority Detail: </h1>
            <div>
                <p>Priority Date: {Moment(priority.priority_date).format("MM/DD/YYYY,  hh:mm:ss a")}</p>
                <p>Priority: {priority.priority}</p>
                <p>Finished: {priority.finished}</p>
                <br />
                <br />
                <Link className={'btn btn-primary'} to={`/priority_reports/update/${priority.client}/`}>Update Priority</Link>
            </div>
        </div>
    )
}

export default DetailPriority