import React, {useState, useEffect} from 'react';
import {Form, Button, Row, Col} from "react-bootstrap";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const UpdateWorkHours = () => {

    let navigate = useNavigate();
    // const [address, setAddress] = useState()

    const [client, setClient] = useState()
    // console.log(client)

    const [useId, setId] = useState()

    const [employees_onsite, set_employees_onsite] = useState('N/A');
    const [work_description, set_work_description] = useState('N/A');
    const [rework, set_rework] = useState('DEFAULT');

    const [hours ,set_hours] = useState('N/A')
    const [minutes, set_minuets] = useState('N/A')

    const [date, setDate] = useState(new Date());

    const finished = date.toLocaleString()
    const [newFinished, setNewFinished] = useState()

    const [photo1, set_photo1] = useState(null);
    const [photo_1_description, set_photo_1_description] = useState('N/A');

    const [photo2, set_photo2] = useState(null);
    const [photo_2_description, set_photo_2_description] = useState('N/A');

    const [photo3, set_photo3] = useState(null);
    const [photo_3_description, set_photo_3_description] = useState('N/A');

    const [photo4, set_photo4] = useState(null);
    const [photo_4_description, set_photo_4_description] = useState('N/A');

    const [photo5, set_photo5] = useState(null);
    const [photo_5_description, set_photo_5_description] = useState('N/A');

    const [photo6, set_photo6] = useState(null);
    const [photo_6_description, set_photo_6_description] = useState('N/A');

    const [photo7, set_photo7] = useState(null);
    const [photo_7_description, set_photo_7_description] = useState('N/A');

    const [photo8, set_photo8] = useState(null);
    const [photo_8_description, set_photo_8_description] = useState('N/A');

    const [photo9, set_photo9] = useState(null);
    const [photo_9_description, set_photo_9_description] = useState('N/A');

    const [photo10, set_photo10] = useState(null);
    const [photo_10_description, set_photo_10_description] = useState('N/A');

    const [photo11, set_photo11] = useState(null);
    const [photo_11_description, set_photo_11_description] = useState('N/A');

    const [photo12, set_photo12] = useState(null);
    const [photo_12_description, set_photo_12_description] = useState('N/A');

    const [photo13, set_photo13] = useState(null);
    const [photo_13_description, set_photo_13_description] = useState('N/A');

    const [photo14, set_photo14] = useState(null);
    const [photo_14_description, set_photo_14_description] = useState('N/A');

    const [photo15, set_photo15] = useState(null);
    const [photo_15_description, set_photo_15_description] = useState('N/A');


    const {id} = useParams();
    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;

    const loadWorkHour = async () => {
        const {data} = await axios.get(process.env.REACT_APP_API+`workhour/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        });

        set_employees_onsite(data.employees_onsite)
        set_work_description(data.work_description)
        set_rework(data.rework)
        set_hours(data.hours)
        set_minuets(data.minutes)

        // set_started(data.started)

        set_photo1(data.photo1)
        set_photo_1_description(data.photo_1_description)

        set_photo2(data.photo2)
        set_photo_2_description(data.photo_2_description)

        set_photo3(data.photo3)
        set_photo_3_description(data.photo_3_description)

        set_photo4(data.photo4)
        set_photo_4_description(data.photo_4_description)

        set_photo5(data.photo5)
        set_photo_5_description(data.photo_5_description)

        set_photo6(data.photo6)
        set_photo_6_description(data.photo_6_description)

        set_photo7(data.photo7)
        set_photo_7_description(data.photo_7_description)

        set_photo8(data.photo8)
        set_photo_8_description(data.photo_8_description)

        set_photo9(data.photo9)
        set_photo_9_description(data.photo_9_description)

        setNewFinished(data.finished)


        set_photo10(data.photo10)
        set_photo_10_description(data.photo_10_description)

        set_photo11(data.photo11)
        set_photo_11_description(data.photo_11_description)

        set_photo12(data.photo12)
        set_photo_12_description(data.photo_12_description)

        set_photo13(data.photo13)
        set_photo_13_description(data.photo_13_description)

        set_photo14(data.photo14)
        set_photo_14_description(data.photo_14_description)

        set_photo15(data.photo15)
        set_photo_15_description(data.photo_15_description)

        setId(data.id)
        setClient(data.client)

        // console.log(data)
        // console.log(data.id)
        // console.log(data.client)

    }

    // console.log(`finished: ${finished}`)
    // console.log(`New finished : ${newFinished}`)

    useEffect(() => {
        loadWorkHour();
        let timer = setInterval(()=>setDate(new Date()), 1000)
        return function cleanup(){
            clearInterval(timer)
        }
    }, [])

    const UpdateWorkHoursInfo = async () => {
        let formField = new FormData()

        if (employees_onsite === 'N/A'){
            alert("Need to enter who worked onsite")
        } else if (work_description === "N/A"){
            alert("Need to enter what work was completed")
        } else if (rework === 'N/A') {
            alert("Please enter if this job was rework")
        } else {
            formField.append('client', client)
            formField.append('employees_onsite', employees_onsite)
            formField.append('work_description', work_description)
            formField.append('rework', rework)
            formField.append('hours',hours)
            formField.append('minutes',minutes)

            if (newFinished === "N/A") {
                formField.append('finished', finished)
            }

            formField.append('photo_1_description', photo_1_description)
            formField.append('photo_2_description', photo_2_description)
            formField.append('photo_3_description', photo_3_description)
            formField.append('photo_4_description', photo_4_description)
            formField.append('photo_5_description', photo_5_description)
            formField.append('photo_6_description', photo_6_description)
            formField.append('photo_7_description', photo_7_description)
            formField.append('photo_8_description', photo_8_description)
            formField.append('photo_9_description', photo_9_description)
            formField.append('photo_10_description', photo_10_description)
            formField.append('photo_11_description', photo_11_description)
            formField.append('photo_12_description', photo_12_description)
            formField.append('photo_13_description', photo_13_description)
            formField.append('photo_14_description', photo_14_description)
            formField.append('photo_15_description', photo_15_description)

            if (photo1 !== null) {
                formField.append('photo1', photo1)
            }
            if (photo2 !== null) {
                formField.append('photo2', photo2)
            }
            if (photo3 !== null) {
                formField.append('photo3', photo3)
            }
            if (photo4 !== null) {
                formField.append('photo4', photo4)
            }
            if (photo5 !== null) {
                formField.append('photo5', photo5)
            }
            if (photo6 !== null) {
                formField.append('photo6', photo6)
            }
            if (photo7 !== null) {
                formField.append('photo7', photo7)
            }
            if (photo8 !== null) {
                formField.append('photo8', photo8)
            }
            if (photo9 !== null) {
                formField.append('photo9', photo9)
            }
            if (photo10 !== null) {
                formField.append('photo10', photo10)
            }
            if (photo11 !== null) {
                formField.append('photo11', photo11)
            }
            if (photo12 !== null) {
                formField.append('photo12', photo12)
            }
            if (photo13 !== null) {
                formField.append('photo13', photo13)
            }
            if (photo14 !== null) {
                formField.append('photo14', photo14)
            }
            if (photo15 !== null) {
                formField.append('photo15', photo15)
            }

            await axios({
                method: 'PUT',
                //TODO if this doesnt work try changing this URL
                url: process.env.REACT_APP_API+`workhour/update/${useId}/`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                },
                data: formField,
            }).then(() => {
                // console.log(response.data)
                // console.log(Error)
                //TODO: Change Nav
                navigate(`/clienthours/${client}/`)
            })
        }
    }


    return (
        <div className={"banner_heading"}>

            <h1>Update Work Hours:</h1>


            <Form>

                <Form.Group controlId={'employees_onsite'}>
                    <Form.Label>Employees Onsite:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"employees_onsite"}
                        value={employees_onsite}
                        onChange={(e) => set_employees_onsite(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'work_description'}>
                    <Form.Label>Work Description:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"work description"}
                        value={work_description}
                        onChange={(e) => set_work_description(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'hours'}>
                    <Form.Label>Hours Worked:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"hours"}
                        value={hours}
                        onChange={(e) => set_hours(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'minutes'}>
                    <Form.Label>minutes Worked:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"minutes"}
                        value={minutes}
                        onChange={(e) => set_minuets(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'rework'}>
                    <Form.Label>Rework:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_rework(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Rework?</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'photo1'}>
                    <Form.Label>Photo 1:</Form.Label>
                    {
                        photo1 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo1}`}
                                       alt={'photo1'}/>) : ("No Photo 1")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_photo1(e.target.files[0])}
                    />
                </Form.Group>
                <Form.Group controlId={'photo_1_description'}>
                    <Form.Label>Photo 1 Description:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"photo_1_description"}
                        value={photo_1_description}
                        onChange={(e) => set_photo_1_description(e.target.value)}
                    />
                </Form.Group>

                <Row>
                    <Col>


                        <Form.Group controlId={'photo2'}>
                            <Form.Label>Photo 2:</Form.Label>
                            {
                                photo2 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo2}`}
                                               alt={'photo2'}/>) : ("No Photo 2")
                            }
                            <Form.Control
                                type={'file'}
                                onChange={(e) => set_photo2(e.target.files[0])}
                            />
                        </Form.Group>
                        <Form.Group controlId={'photo_2_description'}>
                            <Form.Label>Photo 2 Description:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"photo_2_description"}
                                value={photo_2_description}
                                onChange={(e) => set_photo_2_description(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'photo3'}>
                            <Form.Label>Photo 3:</Form.Label>
                            {
                                photo3 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo3}`}
                                               alt={'photo3'}/>) : ("No Photo 3")
                            }
                            <Form.Control
                                type={'file'}
                                onChange={(e) => set_photo3(e.target.files[0])}
                            />
                        </Form.Group>
                        <Form.Group controlId={'photo_3_description'}>
                            <Form.Label>Photo 3 Description:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"photo_3_description"}
                                value={photo_3_description}
                                onChange={(e) => set_photo_3_description(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'photo4'}>
                            <Form.Label>Photo 4:</Form.Label>
                            {
                                photo4 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo4}`}
                                               alt={'photo4'}/>) : ("No Photo 4")
                            }
                            <Form.Control
                                type={'file'}
                                onChange={(e) => set_photo4(e.target.files[0])}
                            />
                        </Form.Group>
                        <Form.Group controlId={'photo_4_description'}>
                            <Form.Label>Photo 4 Description:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"photo_4_description"}
                                value={photo_4_description}
                                onChange={(e) => set_photo_4_description(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'photo5'}>
                            <Form.Label>Photo 5:</Form.Label>
                            {
                                photo5 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo5}`}
                                               alt={'photo5'}/>) : ("No Photo 5")
                            }
                            <Form.Control
                                type={'file'}
                                onChange={(e) => set_photo5(e.target.files[0])}
                            />
                        </Form.Group>
                        <Form.Group controlId={'photo_5_description'}>
                            <Form.Label>Photo 5 Description:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"photo_5_description"}
                                value={photo_5_description}
                                onChange={(e) => set_photo_5_description(e.target.value)}
                            />
                        </Form.Group>


                        <Form.Group controlId={'photo6'}>
                            <Form.Label>Photo 6:</Form.Label>
                            {
                                photo6 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo6}`}
                                               alt={'photo6'}/>) : ("No Photo 6")
                            }
                            <Form.Control
                                type={'file'}
                                onChange={(e) => set_photo6(e.target.files[0])}
                            />
                        </Form.Group>
                        <Form.Group controlId={'photo_6_description'}>
                            <Form.Label>Photo 6 Description:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"photo_6_description"}
                                value={photo_6_description}
                                onChange={(e) => set_photo_6_description(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'photo7'}>
                            <Form.Label>Photo 7:</Form.Label>
                            {
                                photo7 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo7}`}
                                               alt={'photo7'}/>) : ("No Photo 7")
                            }
                            <Form.Control
                                type={'file'}
                                onChange={(e) => set_photo7(e.target.files[0])}
                            />
                        </Form.Group>
                        <Form.Group controlId={'photo_7_description'}>
                            <Form.Label>Photo 7 Description:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"photo_7_description"}
                                value={photo_7_description}
                                onChange={(e) => set_photo_7_description(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'photo8'}>
                            <Form.Label>Photo 8:</Form.Label>
                            {
                                photo8 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo8}`}
                                               alt={'photo8'}/>) : ("No Photo 8")
                            }
                            <Form.Control
                                type={'file'}
                                onChange={(e) => set_photo8(e.target.files[0])}
                            />
                        </Form.Group>
                        <Form.Group controlId={'photo_8_description'}>
                            <Form.Label>Photo 8 Description:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"photo_8_description"}
                                value={photo_8_description}
                                onChange={(e) => set_photo_8_description(e.target.value)}
                            />
                        </Form.Group>

                    </Col>
                    <Col>

                        <Form.Group controlId={'photo9'}>
                            <Form.Label>Photo 9:</Form.Label>
                            {
                                photo9 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo9}`}
                                               alt={'photo9'}/>) : ("No Photo 9")
                            }
                            <Form.Control
                                type={'file'}
                                onChange={(e) => set_photo9(e.target.files[0])}
                            />
                        </Form.Group>
                        <Form.Group controlId={'photo_9_description'}>
                            <Form.Label>Photo 9 Description:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"photo_9_description"}
                                value={photo_9_description}
                                onChange={(e) => set_photo_9_description(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'photo10'}>
                            <Form.Label>Photo 10:</Form.Label>
                            {
                                photo10 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo10}`}
                                                alt={'photo10'}/>) : ("No Photo 10")
                            }
                            <Form.Control
                                type={'file'}
                                onChange={(e) => set_photo10(e.target.files[0])}
                            />
                        </Form.Group>
                        <Form.Group controlId={'photo_10_description'}>
                            <Form.Label>Photo 10 Description:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"photo_10_description"}
                                value={photo_10_description}
                                onChange={(e) => set_photo_10_description(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'photo11'}>
                            <Form.Label>Photo 11:</Form.Label>
                            {
                                photo11 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo11}`}
                                                alt={'photo11'}/>) : ("No Photo 11")
                            }
                            <Form.Control
                                type={'file'}
                                onChange={(e) => set_photo11(e.target.files[0])}
                            />
                        </Form.Group>
                        <Form.Group controlId={'photo_11_description'}>
                            <Form.Label>Photo 11 Description:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"photo_11_description"}
                                value={photo_11_description}
                                onChange={(e) => set_photo_11_description(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'photo12'}>
                            <Form.Label>Photo 12:</Form.Label>
                            {
                                photo12 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo12}`}
                                                alt={'photo12'}/>) : ("No Photo 1")
                            }
                            <Form.Control
                                type={'file'}
                                onChange={(e) => set_photo12(e.target.files[0])}
                            />
                        </Form.Group>
                        <Form.Group controlId={'photo_12_description'}>
                            <Form.Label>Photo 12 Description:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"photo_12_description"}
                                value={photo_12_description}
                                onChange={(e) => set_photo_12_description(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'photo13'}>
                            <Form.Label>Photo 13:</Form.Label>
                            {
                                photo13 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo13}`}
                                                alt={'photo13'}/>) : ("No Photo 13")
                            }
                            <Form.Control
                                type={'file'}
                                onChange={(e) => set_photo13(e.target.files[0])}
                            />
                        </Form.Group>
                        <Form.Group controlId={'photo_13_description'}>
                            <Form.Label>Photo 13 Description:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"photo_13_description"}
                                value={photo_13_description}
                                onChange={(e) => set_photo_13_description(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'photo14'}>
                            <Form.Label>Photo 14:</Form.Label>
                            {
                                photo14 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo14}`}
                                                alt={'photo14'}/>) : ("No Photo 14")
                            }
                            <Form.Control
                                type={'file'}
                                onChange={(e) => set_photo14(e.target.files[0])}
                            />
                        </Form.Group>
                        <Form.Group controlId={'photo_14_description'}>
                            <Form.Label>Photo 14 Description:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"photo_14_description"}
                                value={photo_14_description}
                                onChange={(e) => set_photo_14_description(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'photo15'}>
                            <Form.Label>Photo 15:</Form.Label>
                            {
                                photo15 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo15}`}
                                                alt={'photo15'}/>) : ("No Photo 15")
                            }
                            <Form.Control
                                type={'file'}
                                onChange={(e) => set_photo15(e.target.files[0])}
                            />
                        </Form.Group>
                        <Form.Group controlId={'photo_15_description'}>
                            <Form.Label>Photo 15 Description:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"photo_15_description"}
                                value={photo_15_description}
                                onChange={(e) => set_photo_15_description(e.target.value)}
                            />
                        </Form.Group>

                    </Col>
                </Row>
                <br/>

                <Button className={'btn btn-success'} onClick={UpdateWorkHoursInfo}>Update Work Hours</Button>

            </Form>


        </div>
    )
}

export default UpdateWorkHours