import React, {useState, useEffect} from 'react';
import {Form, Button} from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const UpdateWorkOrder = () => {

    let navigate = useNavigate();
    // const [address, setAddress] = useState()

    const [client, setClient] = useState()
    const [useId, setId] = useState()

    const [system_installed, set_system_installed] = useState('')
    const [job_completed, set_job_completed] = useState('')
    const [done, set_done] = useState('')
    const [prevailing_wage, set_prevailing_wage] = useState('')
    const [estimated_job_length, set_estimated_job_length] = useState('')
    const [scheduling_reviewed, set_scheduling_reviewed] = useState('')
    const [are_we_hiring_a_roofing_company, set_are_we_hiring_a_roofing_company] = useState('')
    const [roofing_company_contact, set_roofing_company_contact] = useState('')
    const [are_we_hiring_an_electrician, set_are_we_hiring_an_electrician] = useState('')
    const [number_of_fans_needed, set_number_of_fans_needed] = useState('')
    const [type_of_fan, set_type_of_fan] = useState('')
    const [work_to_be_done, set_work_to_be_done] = useState('')
    const [notes_from_site, set_notes_from_site] = useState('')
    const [cracked_ceiling, setCrackedCeiling] = useState('')

    const [design_picture_1, set_design_picture_1] = useState(null)
    const[new_design_picture_1, set_new_design_picture_1] = useState(null)

    const [design_picture_2, set_design_picture_2] = useState(null)
    const[new_design_picture_2, set_new_design_picture_2] = useState(null)

    const [design_picture_3, set_design_picture_3] = useState(null)
    const[new_design_picture_3, set_new_design_picture_3] = useState(null)

    const [design_picture_4, set_design_picture_4] = useState(null)
    const[new_design_picture_4, set_new_design_picture_4] = useState(null)

    const [design_picture_5, set_design_picture_5] = useState(null)
    const [new_design_picture_5, set_new_design_picture_5] = useState(null)

    const { id } = useParams();

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin
    let redirect = ''
    if (userInfo){
        if (userInfo.group === "AdminSuper"){
            redirect = '/active_work_orders'
        } else if (userInfo.group === "Finance") {
            redirect = '/finance_active_work_orders'
        } else if (userInfo.group === "Logistics") {
            redirect = '/logistics_dash'
        } else if (userInfo.group === "Tech") {
            redirect = '/tech_work_orders'
        }
    }

    const loadWorkOrder = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`workorders/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        });
        set_system_installed(data.system_installed)
        set_job_completed(data.job_completed)
        set_done(data.done)
        set_prevailing_wage(data.prevailing_wage)
        set_estimated_job_length(data.estimated_job_length)
        set_scheduling_reviewed(data.scheduling_reviewed)
        set_are_we_hiring_a_roofing_company(data.are_we_hiring_a_roofing_company)
        set_roofing_company_contact(data.roofing_company_contact)
        set_are_we_hiring_an_electrician(data.are_we_hiring_an_electrician)
        set_number_of_fans_needed(data.number_of_fans_needed)
        set_type_of_fan(data.type_of_fan)
        set_work_to_be_done(data.work_to_be_done)
        set_notes_from_site(data.notes_from_site)
        set_design_picture_1(data.design_picture_1)
        set_design_picture_2(data.design_picture_2)
        set_design_picture_3(data.design_picture_3)
        set_design_picture_4(data.design_picture_4)
        set_design_picture_5(data.design_picture_5)
        setCrackedCeiling(data.cracked_ceiling)

        setId(data.id)
        setClient(data.client)

        // console.log(data)
        // console.log(data.id)
        // console.log(data.client)

    }

    useEffect(() => {
        loadWorkOrder();
    }, [])

    // console.log(system_installed)

    const UpdateWorkOrderInfo = async () =>{
        let formField = new FormData()
        if (system_installed === null){
            formField.append('system_installed', "")
        } else {
            formField.append('system_installed', system_installed)
        }
        if (job_completed === null){
            formField.append('job_completed', "")
        } else {
            formField.append('job_completed', job_completed)
        }
        formField.append('client', client)
        // formField.append('system_installed', system_installed)
        // formField.append('job_completed', job_completed)
        formField.append('done', done)
        formField.append('prevailing_wage', prevailing_wage)
        formField.append('estimated_job_length', estimated_job_length)
        formField.append('scheduling_reviewed', scheduling_reviewed)
        formField.append('are_we_hiring_a_roofing_company', are_we_hiring_a_roofing_company)
        formField.append('roofing_company_contact', roofing_company_contact)
        formField.append('are_we_hiring_an_electrician', are_we_hiring_an_electrician)
        formField.append('number_of_fans_needed', number_of_fans_needed)
        formField.append('type_of_fan', type_of_fan)
        formField.append('work_to_be_done', work_to_be_done)
        formField.append('notes_from_site', notes_from_site)
        formField.append('cracked_ceiling', cracked_ceiling)

        if (new_design_picture_1 !== null){
            formField.append('design_picture_1', new_design_picture_1)
        }
        if (new_design_picture_2 !== null){
            formField.append('design_picture_2', new_design_picture_2)
        }
        if (new_design_picture_3 !== null){
            formField.append('design_picture_3', new_design_picture_3)
        }
        if (new_design_picture_4 !== null){
            formField.append('design_picture_4', new_design_picture_4)
        }
        if (new_design_picture_5 !== null){
            formField.append('design_picture_5', new_design_picture_5)
        }

        await axios({
            method: 'PUT',
            //TODO if this doesnt work try changing this URL
            url: process.env.REACT_APP_API+`workorder/update/${useId}/`,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            data: formField,
        }).then(() => {
            // console.log(response.data)
            // console.log(Error)
            navigate(redirect)
            // navigate('/logistics_dash')
        })
    }


    return (
        <div className={"banner_heading"}>

            <h1>Update Work Order:</h1>

            <Form>

                <Form.Group controlId={'system_installed'}>
                    <Form.Label>System Installed:</Form.Label>
                    <Form.Control
                        type={'date'}
                        value={system_installed}
                        onChange={(e) => set_system_installed(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'job_completed'}>
                    <Form.Label>Job Completed:</Form.Label>
                    <Form.Control
                        type={'date'}
                        placeholder={"Job Completed"}
                        value={job_completed}
                        onChange={(e) => set_job_completed(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'done'}>
                    <Form.Label>Done:</Form.Label>
                    <Form.Select
                        value={done}
                        onChange={(e) => set_done(e.target.value)}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'cracked_ceiling'}>
                    <Form.Label>Cracked Ceiling:</Form.Label>
                    <Form.Select
                        value={cracked_ceiling}
                        onChange={(e) => setCrackedCeiling(e.target.value)}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'prevailing_wage'}>
                    <Form.Label>Prevailing wage:</Form.Label>
                    <Form.Select
                        value={prevailing_wage}
                        onChange={(e) => set_prevailing_wage(e.target.value)}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'estimated_job_length'}>
                    <Form.Label>Estimated Job Length:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Estimated job length"}
                        value={estimated_job_length}
                        onChange={(e) => set_estimated_job_length(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'scheduling_reviewed'}>
                    <Form.Label>Scheduling reviewed:</Form.Label>
                    <Form.Select
                        value={scheduling_reviewed}
                        onChange={(e) => set_scheduling_reviewed(e.target.value)}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'are_we_hiring_a_roofing_company'}>
                    <Form.Label>Are we hiring a roofing company:</Form.Label>
                    <Form.Select
                        value={are_we_hiring_a_roofing_company}
                        onChange={(e) => set_are_we_hiring_a_roofing_company(e.target.value)}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'roofing_company_contact'}>
                    <Form.Label>Roofing Company Contact:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Roofing company contact"}
                        value={roofing_company_contact}
                        onChange={(e) => set_roofing_company_contact(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'are_we_hiring_an_electrician'}>
                    <Form.Label>Are we hiring an electrician:</Form.Label>
                    <Form.Select
                        value={are_we_hiring_an_electrician}
                        onChange={(e) => set_are_we_hiring_an_electrician(e.target.value)}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'number_of_fans_needed'}>
                    <Form.Label>Number of fans needed:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Number of fans needed"}
                        value={number_of_fans_needed}
                        onChange={(e) => set_number_of_fans_needed(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'type_of_fan'}>
                    <Form.Label>Type of fan:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Type of fan"}
                        value={type_of_fan}
                        onChange={(e) => set_type_of_fan(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'work_to_be_done'}>
                    <Form.Label>Work to be done:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Work to be done"}
                        value={work_to_be_done}
                        onChange={(e) => set_work_to_be_done(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'design_picture_1'}>
                    <Form.Label>Design picture 1:</Form.Label>
                    {
                        design_picture_1 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${design_picture_1}`} alt={'design picture 1'}/>) : ("No design picture 1")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_new_design_picture_1(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'design_picture_2'}>
                    <Form.Label>Design picture 2:</Form.Label>
                    {
                        design_picture_2 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${design_picture_2}`} alt={'design picture 2'}/>) : ("No design picture 2")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_new_design_picture_2(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'design_picture_3'}>
                    <Form.Label>Design picture 3:</Form.Label>
                    {
                        design_picture_3 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${design_picture_3}`} alt={'design picture 3'}/>) : ("No design picture 3")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_new_design_picture_3(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'design_picture_4'}>
                    <Form.Label>Design picture 4:</Form.Label>
                    {
                        design_picture_4 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${design_picture_4}`} alt={'design picture 4'}/>) : ("No design picture 4")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_new_design_picture_4(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'design_picture_5'}>
                    <Form.Label>Design picture 5:</Form.Label>
                    {
                        design_picture_5 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${design_picture_5}`} alt={'design picture 5'}/>) : ("No design picture 5")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_new_design_picture_5(e.target.files[0])}
                    />
                </Form.Group>

                <br />

                <Form.Group controlId={'notes_from_site'}>
                    <Form.Label>Notes from Site</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Notes from site"}
                        value={notes_from_site}
                        onChange={(e) => set_notes_from_site(e.target.value)}
                    />
                </Form.Group>

                <br/>

                    <Button className={'btn btn-success'} onClick={UpdateWorkOrderInfo}>Update Work Order</Button>

            </Form>


        </div>
    )
}

export default UpdateWorkOrder