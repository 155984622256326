import React, {useState, useEffect} from 'react';
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import Moment from "moment/moment";
import {useSelector} from "react-redux";

const DetailServiceOrder = () => {

    const [serviceOrder, setServiceOrder] = useState("")

    const { id } = useParams();
    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const getSingleSO = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`service_order/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        setServiceOrder(data)

    }

    useEffect(()=>{
        getSingleSO()
    }, [])


    let redirect = ''
    if (userInfo){
        if (userInfo.group === "AdminSuper"){
            redirect = '/open_service_orders'
        } else if (userInfo.group === "Finance") {
            redirect = '/finance_dash'
        } else if (userInfo.group === "Logistics") {
            redirect = '/logistics_dash'
        } else if (userInfo.group === "Tech") {
            redirect = '/tech_service_orders'
        }
    }

    return (
        <div className={"banner_heading"}>
            <h1>Work Hours Detail: </h1>

            <div>

                <p>Work To Be Done: {serviceOrder.work_to_be_done}</p>
                <p>Work To Be Done By: {serviceOrder.who}</p>
                <p>Hours Worked: {serviceOrder.hours}</p>
                <p>Minutes Worked: {serviceOrder.minutes}</p>
                <p>Service Order Done?: {serviceOrder.service_order_done}</p>
                <p>Rework?: {serviceOrder.rework}</p>
                <p>Service Order Comments: {serviceOrder.service_order_comments}</p>

                { userInfo.group === "AdminSuper" || userInfo.group === "Finance"
                    ?
                    (
                        <div>
                            <p>Bill Amount: {serviceOrder.bill_amount}</p>
                            <p>Payment Made In Full: {serviceOrder.payment_made_in_full}</p>
                            <p>Ready to Invoice: {serviceOrder.ready_to_invoice}</p>
                        </div>
                    )
                    :
                    ('')
                }

                <p>Scheduled Start: {serviceOrder.start}</p>
                <p>Scheduled End: {serviceOrder.end}</p>

                {/*<p>Started: {workHours.started}</p>*/}
                <p>{Moment(serviceOrder.created).format("[Started On:] MM/DD/YYYY,  hh:mm:ss a")}</p>


                {
                    serviceOrder.photo1 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${serviceOrder.photo1}`}><img className={'processImages'} src={`http://127.0.0.1:8000${serviceOrder.photo1}`} alt={'image 1'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 1 Description : {serviceOrder.photo_1_description}</p>

                {
                    serviceOrder.photo2 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${serviceOrder.photo2}`}><img className={'processImages'} src={`http://127.0.0.1:8000${serviceOrder.photo2}`} alt={'image 2'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 2 Description : {serviceOrder.photo_2_description}</p>

                {
                    serviceOrder.photo3 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${serviceOrder.photo3}`}><img className={'processImages'} src={`http://127.0.0.1:8000${serviceOrder.photo3}`} alt={'image 3'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 3 Description : {serviceOrder.photo_3_description}</p>

                {
                    serviceOrder.photo4 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${serviceOrder.photo4}`}><img className={'processImages'} src={`http://127.0.0.1:8000${serviceOrder.photo4}`} alt={'image 4'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 4 Description : {serviceOrder.photo_4_description}</p>

                {
                    serviceOrder.photo5 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${serviceOrder.photo5}`}><img className={'processImages'} src={`http://127.0.0.1:8000${serviceOrder.photo5}`} alt={'image 5'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 5 Description : {serviceOrder.photo_5_description}</p>

                <br />
                <br />

                {/*<Link className={'btn btn-primary'} to={`/client_service_orders/${serviceOrder.client}/`}>Service Orders Dashboard</Link>*/}

            </div>
        </div>
    )
}

export default DetailServiceOrder