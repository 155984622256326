import React from "react";
import {Col, Row} from "react-bootstrap";
import Calender from "../components/Calender";

function CalenderAssignedEmployees() {
    return (
        <div className={"banner_heading"}>
            <Row>
                <Col>
                    <Calender />
                </Col>
            </Row>
        </div>
    )
}

export default CalenderAssignedEmployees