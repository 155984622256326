import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Button, Form} from "react-bootstrap";
import {useSelector} from "react-redux";

const CreateInvoice = () => {

    let navigate = useNavigate();

    const [client, setClient] = useState()
    const [address, setAddress] = useState()

    const [activateButton, setActivateButton] = useState(false);

    const [payment_made_in_full, set_payment_made_in_full] = useState('No')
    const [showPaymentMadeInFull, setShowPaymentMadeInFull] = useState(false)

    const [is_down_payment_made, set_is_down_payment_made] = useState('DEFAULT')
    const [down_payment_amount, set_down_payment_amount] = useState(0.00)

    const [bill_amount, set_bill_amount] = useState(0.00)
    const [payment_plan, set_payment_plan] = useState('N/A')
    const [payment_notes, set_payment_notes] = useState('N/A')
    const [current_balance_due, set_current_balance_due] = useState(0.00)
    const [po, set_po] = useState('N/A')
    const [work_order_number, set_work_order_number] = useState('N/A')
    const [project_id, set_project_id] = useState('N/A')
    const [priority, set_priority] = useState('DEFAULT')
    const [proposalAmount, setProposalAmount] = useState()
    const [stateID, setStateID] = useState()

    const { id } = useParams();

    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;
    let redirect = ''
    if (userInfo) {
        if (userInfo.group === "AdminSuper") {
            redirect = '/active_work_orders'
        } else if (userInfo.group === "Finance") {
            redirect = '/finance_active_work_orders'
        }
    }

    function factorPaidInFull(){
        setShowPaymentMadeInFull(current => !current);
        setActivateButton(current => !current);
    }

    const loadClient = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`clients/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data.id)
        setClient(data.id)
        setAddress(data.work_site_address)
        setProposalAmount(data.proposal_total)
        setStateID(data.state_id)
    }

    useEffect(() => {
        loadClient()
    }, []);

    const AddInvoice = async () => {

        let formField = new FormData()

        formField.append('client', client)

        formField.append('is_down_payment_made', is_down_payment_made)
        formField.append('down_payment_amount', down_payment_amount)
        formField.append('bill_amount', bill_amount)
        formField.append('payment_plan', payment_plan)
        formField.append('payment_notes', payment_notes)
        formField.append('current_balance_due', current_balance_due)
        formField.append('po', po)
        formField.append('work_order_number', work_order_number)
        formField.append('project_id', project_id)
        formField.append('payment_made_in_full', payment_made_in_full)
        formField.append('priority', priority)
        // console.log(formField)

        await axios ({
            method: 'post',
            url: process.env.REACT_APP_API+`clients/${client}/addinvoice/`,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            data: formField,
        }).then(() => {
            navigate(redirect)
        })
    }

    return(
        <div>
            <h1>Create Invoice for: {address}</h1>
            <h1>{
                stateID !=="N/A" ? (stateID) : ("")
            }</h1>
            <Form>

                <Form.Group controlId={'bill_amount'}>
                    <p>Proposal Amount: {proposalAmount}</p>
                    <Form.Label>Bill Amount:</Form.Label>
                    <Form.Control
                        type={'number'}
                        placeholder={"Bill Amount"}
                        value={bill_amount}
                        onChange={(e) => set_bill_amount(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'is_down_payment_made'}>
                    <Form.Label>Is Down Payment Made:</Form.Label>
                    <Form.Select
                        defaultValue={"DEFAULT"}
                        onChange={(e) => set_is_down_payment_made(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Is Down Payment Made:</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                {
                    is_down_payment_made === 'Yes'
                        ?
                        (
                            <Form.Group controlId={'down_payment_amount'}>
                                <Form.Label>Down Payment Amount:</Form.Label>
                                <Form.Control
                                    type={'number'}
                                    placeholder={"Down Payment Amount"}
                                    value={down_payment_amount}
                                    onChange={(e) => set_down_payment_amount(e.target.value)}
                                />
                            </Form.Group>
                        )
                        :
                        ('')
                }

                {
                    bill_amount > 0 && is_down_payment_made === 'Yes' && bill_amount > 0
                        ?
                        (
                            <Form.Group controlId={'current_balance_due'}>
                                <Form.Label>Current Balance Due:</Form.Label>
                                <Form.Control
                                    type={'number'}
                                    placeholder={"Current Balance Due"}
                                    value={bill_amount - down_payment_amount}
                                    onChange={(e) => set_current_balance_due(bill_amount - down_payment_amount)}
                                />
                            </Form.Group>
                        )
                        :
                        (
                            <Form.Group controlId={'current_balance_due'}>
                                <Form.Label>Current Balance Due:</Form.Label>
                                <Form.Control
                                    type={'number'}
                                    placeholder={"Current Balance Due"}
                                    value={bill_amount}
                                    onChange={(e) => set_current_balance_due(bill_amount)}
                                />
                            </Form.Group>
                        )
                }

                <br />

                <Button variant="primary" type={"button"} onClick={()=>{
                    set_current_balance_due(bill_amount-down_payment_amount);
                    factorPaidInFull();
                }}>Factor Paid In Full</Button>

                <br /><br />

                {showPaymentMadeInFull && (
                <div>
                {
                    current_balance_due === 0 && bill_amount > 0
                        ?
                        <Form.Group controlId={'payment_made_in_full'}>
                            <Form.Label>Payment Made In Full:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"Current Balance Due"}
                                value={'Yes'}
                                onChange={(e) => set_payment_made_in_full("Yes")}
                            />
                        </Form.Group>
                        :
                        <Form.Group controlId={'payment_made_in_full'}>
                            <Form.Label>Payment Made In Full:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"Current Balance Due"}
                                value={'No'}
                                onChange={(e) => set_payment_made_in_full("No")}
                            />
                        </Form.Group>
                }
                </div>
                )}

                <Form.Group controlId={'priority'}>
                    <Form.Label>Priority:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_priority(e.target.value)}
                    >
                        <option value={'DEFAULT'}>Set Priority</option>
                        <option value="Low">Low</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                    </Form.Select>
                </Form.Group>


                <Form.Group controlId={'payment_plan'}>
                    <Form.Label>Payment Plan:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Payment Plan"}
                        value={payment_plan}
                        onChange={(e) => set_payment_plan(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'payment_notes'}>
                    <Form.Label>Payment Notes:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Payment Notes"}
                        value={payment_notes}
                        onChange={(e) => set_payment_notes(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'po'}>
                    <Form.Label>P.O.:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"PO"}
                        value={po}
                        onChange={(e) => set_po(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'work_order_number'}>
                    <Form.Label>Work Order Number:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Work Order Number"}
                        value={work_order_number}
                        onChange={(e) => set_work_order_number(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'project_id'}>
                    <Form.Label>Project I.D.:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Project ID"}
                        value={project_id}
                        onChange={(e) => set_project_id(e.target.value)}
                    />
                </Form.Group>

                <br/>

                {activateButton && (
                    <Button className={'btn btn-success'} onClick={AddInvoice}>Add Invoice</Button>
                )}
            </Form>

        </div>
    );

};


export default CreateInvoice


// import React, {useEffect, useState} from "react";
// import {useNavigate, useParams} from "react-router-dom";
// import axios from "axios";
// import {Button, Form} from "react-bootstrap";
//
// const CreateInvoice = () => {
//
//     let navigate = useNavigate();
//
//     const [client, setClient] = useState()
//     const [address, setAddress] = useState()
//
//     const [is_down_payment_made, set_is_down_payment_made] = useState('DEFAULT')
//     const [down_payment_amount, set_down_payment_amount] = useState(0.00)
//     const [bill_amount, set_bill_amount] = useState(0.00)
//     const [payment_plan, set_payment_plan] = useState('N/A')
//     const [payment_notes, set_payment_notes] = useState('N/A')
//     const [current_balance_due, set_current_balance_due] = useState(0.00)
//     const [po, set_po] = useState('N/A')
//     const [work_order_number, set_work_order_number] = useState('N/A')
//     const [project_id, set_project_id] = useState('N/A')
//     const [payment_made_in_full, set_payment_made_in_full] = useState('DEFAULT')
//     const [priority, set_priority] = useState('DEFAULT')
//
//     const { id } = useParams();
//
//     const loadClient = async () => {
//         const { data } = await axios.get(`http://127.0.0.1:8000/api/clients/${id}/`)
//         // console.log(data.id)
//         setClient(data.id)
//         setAddress(data.work_site_address)
//     }
//
//     useEffect(() => {
//         loadClient()
//     }, []);
//
//     const AddInvoice = async () => {
//
//         let formField = new FormData()
//
//         formField.append('client', client)
//
//         formField.append('is_down_payment_made', is_down_payment_made)
//         formField.append('down_payment_amount', down_payment_amount)
//         formField.append('bill_amount', bill_amount)
//         formField.append('payment_plan', payment_plan)
//         formField.append('payment_notes', payment_notes)
//         formField.append('current_balance_due', current_balance_due)
//         formField.append('po', po)
//         formField.append('work_order_number', work_order_number)
//         formField.append('project_id', project_id)
//         formField.append('payment_made_in_full', payment_made_in_full)
//         formField.append('priority', priority)
//         // console.log(formField)
//
//         await axios ({
//             method: 'post',
//             url: `http://127.0.0.1:8000/api/clients/${client}/addinvoice/`,
//             data: formField,
//         }).then(() => {
//             navigate('/active_work_orders')
//         })
//     }
//
//     return(
//         <div>
//             <h1>Create Invoice for: {address}</h1>
//
//             <Form>
//
//                 <Form.Group controlId={'is_down_payment_made'}>
//                     <Form.Label>Is Down Payment Made:</Form.Label>
//                     <Form.Select
//                         defaultValue={"DEFAULT"}
//                         onChange={(e) => set_is_down_payment_made(e.target.value)}
//                     >
//                         <option value={'DEFAULT'} disabled>Is Down Payment Made:</option>
//                         <option value="Yes">Yes</option>
//                         <option value="No">No</option>
//                     </Form.Select>
//                 </Form.Group>
//
//                 <Form.Group controlId={'down_payment_amount'}>
//                     <Form.Label>Down Payment Amount:</Form.Label>
//                     <Form.Control
//                         type={'number'}
//                         placeholder={"Down Payment Amount"}
//                         value={down_payment_amount}
//                         onChange={(e) => set_down_payment_amount(e.target.value)}
//                     />
//                 </Form.Group>
//
//                 <Form.Group controlId={'bill_amount'}>
//                     <Form.Label>Bill Amount:</Form.Label>
//                     <Form.Control
//                         type={'number'}
//                         placeholder={"Bill Amount"}
//                         value={bill_amount}
//                         onChange={(e) => set_bill_amount(e.target.value)}
//                     />
//                 </Form.Group>
//
//                 <Form.Group controlId={'payment_plan'}>
//                     <Form.Label>Payment Plan:</Form.Label>
//                     <Form.Control
//                         type={'text'}
//                         placeholder={"Payment Plan"}
//                         value={payment_plan}
//                         onChange={(e) => set_payment_plan(e.target.value)}
//                     />
//                 </Form.Group>
//
//
//
//                 <Form.Group controlId={'payment_notes'}>
//                     <Form.Label>Payment Notes:</Form.Label>
//                     <Form.Control
//                         type={'text'}
//                         placeholder={"Payment Notes"}
//                         value={payment_notes}
//                         onChange={(e) => set_payment_notes(e.target.value)}
//                     />
//                 </Form.Group>
//
//                 <Form.Group controlId={'current_balance_due'}>
//                     <Form.Label>Current Balance Due:</Form.Label>
//                     <Form.Control
//                         type={'number'}
//                         placeholder={"Current Balance Due"}
//                         value={current_balance_due}
//                         onChange={(e) => set_current_balance_due(e.target.value)}
//                     />
//                 </Form.Group>
//
//                 <Form.Group controlId={'po'}>
//                     <Form.Label>P.O.:</Form.Label>
//                     <Form.Control
//                         type={'text'}
//                         placeholder={"PO"}
//                         value={po}
//                         onChange={(e) => set_po(e.target.value)}
//                     />
//                 </Form.Group>
//
//                 <Form.Group controlId={'work_order_number'}>
//                     <Form.Label>Work Order Number:</Form.Label>
//                     <Form.Control
//                         type={'text'}
//                         placeholder={"Work Order Number"}
//                         value={work_order_number}
//                         onChange={(e) => set_work_order_number(e.target.value)}
//                     />
//                 </Form.Group>
//
//                 <Form.Group controlId={'project_id'}>
//                     <Form.Label>Project I.D.:</Form.Label>
//                     <Form.Control
//                         type={'text'}
//                         placeholder={"Project ID"}
//                         value={project_id}
//                         onChange={(e) => set_project_id(e.target.value)}
//                     />
//                 </Form.Group>
//
//                 {/*<Form.Group controlId={'payment_made_in_full'}>*/}
//                 {/*    <Form.Label>Is Payment made in full:</Form.Label>*/}
//                 {/*    <Form.Control*/}
//                 {/*        type={'text'}*/}
//                 {/*        placeholder={"Payment Made In Full"}*/}
//                 {/*        value={payment_made_in_full}*/}
//                 {/*        onChange={(e) => set_payment_made_in_full(e.target.value)}*/}
//                 {/*    />*/}
//                 {/*</Form.Group>*/}
//
//                 <Form.Group controlId={'payment_made_in_full'}>
//                     <Form.Label>Payment made in full:</Form.Label>
//                     <Form.Select
//                         defaultValue={"DEFAULT"}
//                         onChange={(e) => set_payment_made_in_full(e.target.value)}
//                     >
//                         <option value={'DEFAULT'} disabled>Payment Made In Full:</option>
//                         <option value="Yes">Yes</option>
//                         <option value="No">No</option>
//                     </Form.Select>
//                 </Form.Group>
//
//                 <Form.Group controlId={'priority'}>
//                     <Form.Label>Priority:</Form.Label>
//                     <Form.Select
//                         defaultValue={'DEFAULT'}
//                         onChange={(e) => set_priority(e.target.value)}
//                     >
//                         <option value={'DEFAULT'}>Set Priority</option>
//                         <option value="Low">Low</option>
//                         <option value="Medium">Medium</option>
//                         <option value="High">High</option>
//                     </Form.Select>
//                 </Form.Group>
//
//
//                 <br/>
//
//                 <Button className={'btn btn-success'} onClick={AddInvoice}>Add Invoice</Button>
//
//             </Form>
//
//         </div>
//     );
//
// };
//
//
// export default CreateInvoice