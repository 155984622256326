import React, {Component, useEffect, useState} from "react";
import {Button, ButtonToolbar, Card, Col, Container, Modal, Nav, Row, Table} from "react-bootstrap";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import Moment from 'moment'
import {useSelector} from "react-redux";

// TODO: the refresh needs a timer on it. Otherwise it just runs all the time


const InactivePriorityJobs = () => {
    Moment.locale('en');
    const [info, setInfo] = useState()

    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;


    const loadPriorities = async () => {
        const {data} = await axios.get(process.env.REACT_APP_API+`done_priority_reports/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        setInfo(data)
    }

    useEffect(() => {
        loadPriorities()
    }, []);



    return (
        <div>
            <Nav variant="tabs" defaultActiveKey="/active_work_orders" className={'reports_nav_heading'}>
                <Nav.Item>
                    <Nav.Link href="/active_work_orders">Active Work Orders</Nav.Link>
                </Nav.Item>
                <Nav.Link href={'/priority_reports'} >
                    Active Priority Tasks
                </Nav.Link>
                <Nav.Link href={'/done_priority_reports'} eventKey="disabled" disabled>
                    Inactive Priority Tasks
                </Nav.Link>
            </Nav>

            <br /><br />
            <h1>All Inactive Priority Jobs:</h1>

            {info?.map(item =>
                <Row className={'HourPerRow'} key={item.id}>

                    <span className={'HoursSpan'}>
                        Client: {item.client_name} <br/>
                        Created On: {Moment(item.created).format("[Created On:] MM/DD/YYYY  hh:mm:ss a")}<br/>
                        Priority Date: {item.priority_date}<br/>
                        Priority: {item.priority}<br/>
                        Done?: {item.finished}<br/>

                    </span>

                    <Col>
                        <Link className={'btn btn-info'} to={`/priority_reports/${item.client}/`}>
                            Detail Priority
                        </Link>
                    </Col>
                    <Col>
                        <Link className={'btn btn-warning'} to={`/priority_reports/update/${item.client}/`}>
                            Update Priority
                        </Link>
                    </Col>

                </Row>
            )}
        </div>
    );

}
export default InactivePriorityJobs;
