import React, {useState, useEffect} from 'react';
import {Form, Button, Row, Col} from "react-bootstrap";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const UpdateComments = () => {

    let navigate = useNavigate();

    const [client, setClient] = useState()
    const [useId, setId] = useState()

    const [tech_hour_comments, setTechHourComments] = useState('N/A')
    const [office_hour_comments ,setOfficeHourComments] = useState('N/A')

    const {id} = useParams();

    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;

    const loadComment = async () => {
        const {data} = await axios.get(process.env.REACT_APP_API+`comment/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        });

        setTechHourComments(data.tech_hour_comments)
        setOfficeHourComments(data.office_hour_comments)

        setId(data.id)
        setClient(data.client)
    }



    useEffect(() => { loadComment(); }, [])

    let redirect = ''
    if (userInfo){
        if (userInfo.group === "AdminSuper"){
            redirect = '/active_work_orders'
        } else if (userInfo.group === "Finance") {
            redirect = '/finance_active_work_orders'
        } else if (userInfo.group === "Logistics") {
            redirect = '/logistics_active-work_orders'
        } else if (userInfo.group === "Tech") {
            redirect = '/tech_work_orders'
        }
    }

    const UpdateWorkHoursInfo = async () => {
        let formField = new FormData()

            formField.append('client', client)
            formField.append('tech_hour_comments', tech_hour_comments)
            formField.append('office_hour_comments', office_hour_comments)

            await axios({
                method: 'PUT',
                url: process.env.REACT_APP_API+`comments/update/${useId}/`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                },
                data: formField,
            }).then(() => {
                navigate(redirect)
            })
    }


    return (
        <div className={"banner_heading"}>

            <h1>Update Comment:</h1>


            <Form>

                <Form.Group controlId={'tech_hour_comments'}>
                    <Form.Label>Technician Comments:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"tech_hour_comments"}
                        value={tech_hour_comments}
                        onChange={(e) => setTechHourComments(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'office_hour_comments'}>
                    <Form.Label>Office Comments:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Office Comments"}
                        value={office_hour_comments}
                        onChange={(e) => setOfficeHourComments(e.target.value)}
                    />
                </Form.Group>

                <br/>

                <Button className={'btn btn-success'} onClick={UpdateWorkHoursInfo}>Update Comments</Button>

            </Form>


        </div>
    )
}

export default UpdateComments