import React, {useState, useEffect} from 'react';
import {Form, Button} from "react-bootstrap";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const UpdateJobSchedule = () => {

    let navigate = useNavigate();
    // const [address, setAddress] = useState()

    const [client, setClient] = useState()
    const [useId, setId] = useState()

    const [title, setTitle] = useState('')
    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const [who, setWho] = useState('')
    const [job_status, setJobStatus] = useState('')
    const [why, setWhy] = useState('')
    const [reason, setReason] = useState('')

    const { id } = useParams();
    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;

    const loadJobSchedule = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`scheduled_job/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        });
        // console.log(data)

        setTitle(data.title)
        setStart(data.start)
        setEnd(data.end)
        setWho(data.who)
        setJobStatus(data.job_status)
        setWhy(data.why)
        setReason(data.reason)

        setId(data.id)
        setClient(data.client)

        // console.log(data.id)
        // console.log(data.client)
    }


    useEffect(() => {
        loadJobSchedule();
    }, [])

    const UpdateJobSchedule = async () =>{


        if (job_status==='On Hold' && why==='N/A'){
            alert("Fill out why the job is on hold and give details in reason.")
        } else if (job_status==='On Hold' && reason==='N/A'){
            alert("Fill out why job is on hold with some details")
        } else{
            let formField = new FormData()
            formField.append('client', client)
            formField.append('title',title)
            formField.append('start',start)
            formField.append('end',end)
            formField.append('who',who)
            formField.append('job_status',job_status)
            formField.append('why',why)
            formField.append('reason',reason)

            await axios({
                method: 'PUT',
                url: process.env.REACT_APP_API+`scheduled/update/${useId}/`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                },
                data: formField,
            }).then(() => {
                // console.log(response.data)
                // console.log(Error)
                navigate('/logistics_active-work_orders')
            })
        }
    }


    return (
        <div className={"banner_heading"}>

            <h1>Update Job Schedule for: {title}</h1>
            <div style={{background: 'lightblue', textAlign: 'center', padding: '10px'}}>
                <h3>Note: End Date is the cut off date that the job will BE DONE. So if the job is supposed to be done on the 4th, end date is the 5th.</h3>
            </div>
            <Form>

                {/*<Form.Group controlId={'title'}>*/}
                {/*    <Form.Label>Title:</Form.Label>*/}
                {/*    <Form.Control*/}
                {/*        type={'text'}*/}
                {/*        placeholder={"title"}*/}
                {/*        value={title}*/}
                {/*        onChange={(e) => setTitle(e.target.value)}*/}
                {/*    />*/}
                {/*</Form.Group>*/}

                <Form.Group controlId={'start'}>
                    <Form.Label>Start Date:</Form.Label>
                    <Form.Control
                        type={'date'}
                        placeholder={"Start Date"}
                        value={start}
                        onChange={(e) => setStart(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'end'}>
                    <Form.Label>End Date:</Form.Label>
                    <Form.Control
                        type={'date'}
                        placeholder={"End Date"}
                        value={end}
                        onChange={(e) => setEnd(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'who'}>
                    <Form.Label>Who's Scheduled</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"who"}
                        value={who}
                        onChange={(e) => setWho(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'job_status'}>
                    <Form.Label>Job Status:</Form.Label>
                    <Form.Select
                        value={job_status}
                        onChange={(e) => setJobStatus(e.target.value)}
                    >
                        <option value={'DEFAULT'}>Job Status?</option>
                        <option value="Active">Active</option>
                        <option value="Finished">Finished</option>
                        <option value="On Hold">On Hold</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'why'}>
                    <Form.Label>On Hold Category:</Form.Label>
                    <Form.Select
                        value={why}
                        onChange={(e) => setWhy(e.target.value)}
                    >
                        <option value={'DEFAULT'}>Category Of Issue?</option>
                        <option value="Inventory">Inventory</option>
                        <option value="Manpower">Manpower</option>
                        <option value="Client Request">Client Request</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'reason'}>
                    <Form.Label>Reason:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"reason"}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                    />
                </Form.Group>
                <br/>

                <Button className={'btn btn-success'} onClick={UpdateJobSchedule}>Update Proposal</Button>

            </Form>

        </div>
    )
}

export default UpdateJobSchedule