import React, {Component, useEffect, useState} from "react";
import {Card, Col, Container, Nav, Row,} from "react-bootstrap";
import axios from "axios";
import {Link} from "react-router-dom";
import {ClockLoader} from "react-spinners";


//TODO: the refresh needs a timer on it. Otherwise it just runs all the time

export class ClosedServiceOrders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // Active Work Orders clients
            AWOS: [],
            clientIsLoading: true,

            // Closed CheckLists
            CSO: [],
            closedServiceOrderIsLoading: true,

        }
    }


    componentDidMount() {
        axios.get(process.env.REACT_APP_API + 'clients/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({AWOS: response.data});
                this.setState({clientIsLoading: false});
            })

        axios.get(process.env.REACT_APP_API + 'closed_service_orders/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({CSO: response.data});
                this.setState({closedServiceOrderIsLoading: false});
            })

    }


    render() {

        const {
            AWOS,
            CSO,
            clientIsLoading,
            closedServiceOrderIsLoading,

        } = this.state;

        if (clientIsLoading || closedServiceOrderIsLoading) {
            return <div className={'loadingSpinner'}>Loading...<br/> <ClockLoader color={'#0d00ff'}/></div>
        }
        let activeWO = AWOS.map((client) => client.client)

        return (

            <Container fluid>
                <h1>Closed Service Orders:</h1>

                {CSO.map((cso, index) =>
                            <div
                                className={AWOS}
                                key={cso.work_site_address}
                                index={index}
                            >

                                            <Row className={"workOrderRow"}>
                                                <Col>
                                                    <Link className={'btn btn-primary'}
                                                          to={`/client_details/${cso.id}/`}>
                                                        {cso.title}
                                                    </Link>
                                                </Col>

                                                <Col>
                                                    <Link className={'btn btn-info'} to={`/service_order/${cso.id}/`}>
                                                        Detail Service Order
                                                    </Link>
                                                </Col>
                                                <Col>
                                                    <Link className={'btn btn-warning'} to={`/service_order/update/${cso.id}/`}>
                                                        Update Service Order
                                                    </Link>
                                                </Col>
                                            </Row>
                            </div>
                        )}
            </Container>
        );
    }
}

export default ClosedServiceOrders;




// import React, {Component, useEffect, useState} from "react";
// import {Card, Col, Container, Nav, Row} from "react-bootstrap";
// import axios from "axios";
// import {Link} from "react-router-dom";
// import CreateClient from "./CreateClient";
// import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
// import {ClockLoader} from "react-spinners";
// import {map} from "react-bootstrap/ElementChildren";
//
//
// //TODO: the refresh needs a timer on it. Otherwise it just runs all the time
//
// export class ClosedServiceOrders extends Component {
//
//     constructor(props) {
//         super(props);
//         this.state = {
//             // Active Work Orders clients
//             AWOS: [],
//             clientIsLoading: true,
//
//             // Active Service Order clients
//             ASO: [],
//             service_orders_are_loading: true,
//         }
//     }
//
//
//     componentDidMount() {
//         axios.get(process.env.REACT_APP_API + 'service_order_clients/', {
//             headers: {
//                 "Accept": 'application/json',
//                 "Content-Type": 'application/json'
//             },
//         })
//             .then(response => {
//                 this.setState({AWOS: response.data});
//                 this.setState({clientIsLoading: false});
//             })
//
//         axios.get(process.env.REACT_APP_API + 'service_orders/', {
//             headers: {
//                 "Accept": 'application/json',
//                 "Content-Type": 'application/json'
//             },
//         })
//             .then(response => {
//                 this.setState({ASO: response.data});
//                 this.setState({service_orders_are_loading: false});
//             })
//     };
//
//     render() {
//
//         const {
//             AWOS,
//             ASO,
//             clientIsLoading,
//             service_orders_are_loading,
//         } = this.state;
//
//         if (clientIsLoading || service_orders_are_loading) {
//             return <div className={'loadingSpinner'}>Loading...<br/> <ClockLoader color={'#0d00ff'}/></div>
//         }
//
//         let activeServiceOrders = ASO.map((client) => client.client)
//
//         return (
//             <div>
//                 <h1>Clients With Closed Service Order Jobs:</h1>
//                 {AWOS.map((awo, index) =>
//                     <Row
//                         key={awo.work_site_address}
//                         index={index}
//                         className={"workOrderRow"}
//                     >
//                         <Col>
//                             <Link className={'btn btn-primary'}
//                                   to={`/client_details/${awo.id}/`}>
//                                 {awo.work_site_address}
//                             </Link>
//                         </Col>
//
//                         <Col className={"Col AddHourCol"}>
//                             {
//                                 activeServiceOrders.includes(awo.id)
//                                     ?
//                                     ('So True')
//                                     &&
//                                     activeServiceOrders.includes(awo.id)
//                                         ?
//                                         (<Link className={'btn btn-info'} to={`/closed_service_orders/${awo.id}/`}>
//                                             Closed Service Orders For Client
//                                         </Link>)
//
//                                         :
//                                         ("Create Service Order For Job")
//                                     :
//                                     ("Create Service Order For Job")
//                             }
//                         </Col>
//
//                     </Row>
//                 )}
//             </div>
//         );
//     }
// }
//
// export default ClosedServiceOrders;
//
//
//
// // import React from "react";
// //
// // function ClosedServiceOrders() {
// //     return (
// //         <div className={"banner_heading"}>
// //             <h1>Logic For Closed Service Orders from back end to go here</h1>
// //         </div>
// //     )
// // }
// //
// // export default ClosedServiceOrders