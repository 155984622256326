import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {useSelector} from "react-redux";

const CreateOnSite = () => {

    let navigate = useNavigate();

    const [client, setClient] = useState()
    const [address, setAddress] = useState()

    const [height_to_ceiling_in_feet, set_height_to_ceiling_in_feet] = useState('')
    const [roof_access, set_roof_access] = useState('DEFAULT')
    const [type_of_roof, set_type_of_roof] = useState('')
    const [drop_ceiling, set_drop_ceiling] = useState('DEFAULT')
    const [type_of_ceiling, set_type_of_ceiling] = useState('DEFAULT')
    const [electrical, set_electrical] = useState('DEFAULT')
    const [soil_type, set_soil_type] = useState('')
    const [soil_removal, set_soil_removal] = useState('DEFAULT')
    const [water_on_site, set_water_on_site] = useState('DEFAULT')
    const [lights_on_site, set_lights_on_site] = useState('DEFAULT')
    const [electricity_on_site, set_electricity_on_site] = useState('DEFAULT')
    const [bath_room_on_site, set_bath_room_on_site] = useState('DEFAULT')
    const [permit_required, set_permit_required] = useState('DEFAULT')
    const [after_completion, set_after_completion] = useState('DEFAULT')
    const [type_of_permit, set_type_of_permit] = useState('')
    const [who_completed_onsite, set_who_completed_onsite] = useState('')
    const [onsite_notes, set_onsite_notes] = useState('')

    const [photo1, set_photo1] = useState(null)
    const [photo2, set_photo2] = useState(null)
    const [photo3, set_photo3] = useState(null)
    const [photo4, set_photo4] = useState(null)
    const [photo5, set_photo5] = useState(null)
    const [photo6, set_photo6] = useState(null)
    const [photo7, set_photo7] = useState(null)
    const [photo8, set_photo8] = useState(null)
    const [photo9, set_photo9] = useState(null)
    const [photo10, set_photo10] = useState(null)


    const { id } = useParams();
    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;

    const loadClient = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`clients/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data.id)
        setClient(data.id)
        setAddress(data.work_site_address)
    }

    useEffect(() => {
        loadClient()
    }, []);

    const AddOnSite = async () => {

        let formField = new FormData()

        formField.append('client', client)
        formField.append('height_to_ceiling_in_feet', height_to_ceiling_in_feet)
        formField.append('roof_access', roof_access)
        formField.append('type_of_roof', type_of_roof)
        formField.append('drop_ceiling', drop_ceiling)
        formField.append('type_of_ceiling', type_of_ceiling)
        formField.append('electrical', electrical)
        formField.append('soil_type', soil_type)
        formField.append('soil_removal', soil_removal)
        formField.append('water_on_site', water_on_site)
        formField.append('lights_on_site', lights_on_site)
        formField.append('electricity_on_site', electricity_on_site)
        formField.append('bath_room_on_site', bath_room_on_site)
        formField.append('permit_required', permit_required)
        formField.append('after_completion', after_completion)
        formField.append('type_of_permit', type_of_permit)
        formField.append('who_completed_onsite', who_completed_onsite)
        formField.append('onsite_notes', onsite_notes)

        if (photo1 !== null){
            formField.append('photo1', photo1)
        }

        if (photo2 !== null){
            formField.append('photo2', photo2)
        }

        if (photo3 !== null){
            formField.append('photo3', photo3)
        }

        if (photo4 !== null){
            formField.append('photo4', photo4)
        }

        if (photo5 !== null){
            formField.append('photo5', photo5)
        }

        if (photo6 !== null){
            formField.append('photo6', photo6)
        }

        if (photo7 !== null){
            formField.append('photo7', photo7)
        }

        if (photo8 !== null){
            formField.append('photo8', photo8)
        }

        if (photo9 !== null){
            formField.append('photo9', photo9)
        }

        if (photo10 !== null){
            formField.append('photo10', photo10)
        }

        await axios ({
            method: 'post',
            url: process.env.REACT_APP_API+`clients/${client}/addonsite/`,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            data: formField,
            }).then(() => {
            // console.log(response.data)
            navigate('/active_work_orders')
        })
    }

    return(
        <div>
            <h1>Create On-Site for: {address}</h1>

            <Form>

                <Form.Group controlId={'height_to_ceiling_in_feet'}>
                    <Form.Label>Height to ceiling in feet:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Height To Ceiling In Feet"}
                        value={height_to_ceiling_in_feet}
                        onChange={(e) => set_height_to_ceiling_in_feet(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'roof_access'}>
                    <Form.Label>Roof Access:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_roof_access(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Roof Access?</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'type_of_roof'}>
                    <Form.Label>Type of roof:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Type Of Roof"}
                        value={type_of_roof}
                        onChange={(e) => set_type_of_roof(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'drop_ceiling'}>
                    <Form.Label>Drop Ceiling:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_drop_ceiling(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Drop Ceiling?</option>
                        <option value="No" >No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'type_of_ceiling'}>
                    <Form.Label>Type of Ceiling:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_type_of_ceiling(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Type of ceiling</option>
                        <option value="Open">Open</option>
                        <option value="Closed Plenum">Closed Plenum</option>
                        <option value="N/A">N/A</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'electrical'}>
                    <Form.Label>Electrical:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_electrical(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Electrical:</option>
                        <option value="phase 1">phase 1</option>
                        <option value="phase 3">phase 3</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'soil_type'}>
                    <Form.Label>Soil Type:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Soil Type"}
                        value={soil_type}
                        onChange={(e) => set_soil_type(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'soil_removal'}>
                    <Form.Label>Soil Removal:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_soil_removal(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Soil Removal:</option>
                        <option value="soil stays on site">soil stays on site</option>
                        <option value="soil is taken with us">soil is taken with us</option>
                        <option value="soil is put in barrel">soil is put in barrel</option>
                    </Form.Select>
                </Form.Group>

                <Container>
                    <Row>
                        <Col>
                            <Form.Group controlId={'water_on_site'}>
                                <Form.Label>Water on Site:</Form.Label>
                                    <Form.Select
                                        defaultValue={'DEFAULT'}
                                        onChange={(e) => set_water_on_site(e.target.value)}
                                    >
                                        <option value={'DEFAULT'} disabled>Water on site:</option>
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group controlId={'lights_on_site'}>
                                <Form.Label>Lights on Site:</Form.Label>
                                <Form.Select
                                    defaultValue={'DEFAULT'}
                                    onChange={(e) => set_lights_on_site(e.target.value)}
                                >
                                    <option value={'DEFAULT'} disabled>Lights on Site:</option>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId={'electricity_on_site'}>
                                <Form.Label>Electricity on Site:</Form.Label>
                                <Form.Select
                                    defaultValue={'DEFAULT'}
                                    onChange={(e) => set_electricity_on_site(e.target.value)}
                                >
                                    <option value={'DEFAULT'} disabled>Electricity on Site:</option>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId={'bath_room_on_site'}>
                                <Form.Label>Bathroom on Site:</Form.Label>
                                <Form.Select
                                    defaultValue={'DEFAULT'}
                                    onChange={(e) => set_bath_room_on_site(e.target.value)}
                                >
                                    <option value={'DEFAULT'} disabled>Bathroom on Site:</option>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId={'permit_required'}>
                                <Form.Label>Permit Required:</Form.Label>
                                <Form.Select
                                    defaultValue={'DEFAULT'}
                                    onChange={(e) => set_permit_required(e.target.value)}
                                >
                                    <option value={'DEFAULT'} disabled>Permit Required:</option>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group controlId={'after_completion'}>
                                <Form.Label>After Completion:</Form.Label>
                                <Form.Select
                                    defaultValue={'DEFAULT'}
                                    onChange={(e) => set_after_completion(e.target.value)}
                                >
                                    <option value={'DEFAULT'} disabled>After Completion:</option>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>

                <Form.Group controlId={'type_of_permit'}>
                    <Form.Label>Type of Permit:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Type of Permit"}
                        value={type_of_permit}
                        onChange={(e) => set_type_of_permit(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'who_completed_onsite'}>
                    <Form.Label>Who Completed Onsite:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Who Completed Onsite"}
                        value={who_completed_onsite}
                        onChange={(e) => set_who_completed_onsite(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'onsite_notes'}>
                    <Form.Label>Onsite Notes:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"OnSite Notes"}
                        value={onsite_notes}
                        onChange={(e) => set_onsite_notes(e.target.value)}
                    />
                </Form.Group>



                <Form.Group controlId={'photo1'}>
                    <Form.Label>Photo 1:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_photo1(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo2'}>
                    <Form.Label>Photo 2:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_photo2(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo3'}>
                    <Form.Label>Photo 3:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_photo3(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo4'}>
                    <Form.Label>Photo 4:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_photo4(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo5'}>
                    <Form.Label>Photo 5:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_photo5(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo6'}>
                    <Form.Label>Photo 6:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_photo6(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo7'}>
                    <Form.Label>Photo 7:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_photo7(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo8'}>
                    <Form.Label>Photo 8:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_photo8(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo9'}>
                    <Form.Label>Photo 9:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_photo9(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo10'}>
                    <Form.Label>Photo 10:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_photo10(e.target.files[0])}
                    />
                </Form.Group>

                <br/>
                {/*<Link to={'/active_work_orders'}>*/}
                    <Button className={'btn btn-success'} onClick={AddOnSite}>Add On-Site</Button>
                {/*</Link>*/}
            </Form>

        </div>
    );

};


export default CreateOnSite