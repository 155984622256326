import React, {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import {ClockLoader} from "react-spinners";
import Moment from "moment";

//TODO: the refresh needs a timer on it. Otherwise it just runs all the time

export class StatusReport extends Component{

    constructor(props) {
        super(props);

        this.state = {
            // Active Work Orders clients
            AWOS: [],
            clientIsLoading: true,
        }
    }


    componentDidMount() {
        axios.get(process.env.REACT_APP_API+'status_reports/',{
            headers:{
                "Accept":'application/json',
                "Content-Type":'application/json'
            },
        })
            .then(response=>{
                this.setState({AWOS: response.data});
                this.setState({clientIsLoading: false});
            })
    }

    render() {

        const {AWOS, clientIsLoading} = this.state;

        if (clientIsLoading){
            return <div className={'loadingSpinner'} >Loading...<br/> <ClockLoader color="#0d00ff" /></div>
        }

        return (

            <Container fluid>
                <br />
                <h1>Job Status Report:</h1>
                <br />

                {AWOS.map(awo=>
                    <Row key={awo.work_site_address} className={"workOrderRow"}>

                        <h2 className={'statusReportHeader'}>Project Address: {awo.work_site_address} || Project Id: {awo.project_ID}</h2>

                        <Col>
                            <h4>Client Details</h4>
                            <h5>•Created: {Moment(awo.created).format("MM/DD/YYYY")}</h5>
                            <h5>•Updated: {Moment(awo.date_modified).format("MM/DD/YYYY")}</h5>
                            <h5>✔ Created Client</h5>
                        </Col>

                        <Col>
                            {
                                awo.pre_site_created
                                    ?
                                    <div>
                                        <h4> Presite Details:</h4>
                                        <h5>•Created: {Moment(awo.pre_site_created).format("MM/DD/YYYY")}</h5>
                                        <h5>•Updated: {Moment(awo.pre_site_updated).format("MM/DD/YYYY")}</h5>
                                        <h5>✔ PreSite Created</h5>
                                    </div>
                                    :
                                    <h5>PreSite Needed ❗</h5>
                            }
                        </Col>

                        <Col>
                            {
                                awo.onsite_created
                                    ?
                                    <div>
                                        <h4> Onsite Details:</h4>
                                        <h5>•Created: {Moment(awo.onsite_created).format("MM/DD/YYYY")}</h5>
                                        <h5>•Updated: {Moment(awo.onsite_updated).format("MM/DD/YYYY")}</h5>
                                        <h5>✔ PreSite Created</h5>
                                    </div>
                                    :
                                    <h5>Onsite Needed ❗</h5>
                            }
                        </Col>

                        <Col>
                            {
                                awo.proposal_created
                                    ?
                                    <div>
                                        <h4> Proposal Details:</h4>
                                        <h5>•Created: {Moment(awo.proposal_created).format("MM/DD/YYYY")}</h5>
                                        <h5>•Updated: {Moment(awo.proposal_updated).format("MM/DD/YYYY")}</h5>
                                        <h5>✔ Proposal Created</h5>
                                    </div>
                                    :
                                    <h5>Proposal Needed ❗</h5>
                            }
                        </Col>

                        <Col>
                            {
                                awo.invoice_created
                                    ?
                                    <div>
                                        <h4> Invoice Details:</h4>
                                        <h5>•Created: {Moment(awo.invoice_created).format("MM/DD/YYYY")}</h5>
                                        <h5>•Updated: {Moment(awo.invoice_updated).format("MM/DD/YYYY")}</h5>
                                        <h5>✔ Invoice Created</h5>
                                    </div>
                                    :
                                    <h5>Invoice Needed ❗</h5>
                            }
                        </Col>

                        <Col>
                            {
                                awo.work_order_created
                                    ?
                                    <div>
                                        <h4> Work Order Details:</h4>
                                        <h5>•Created: {Moment(awo.work_order_created).format("MM/DD/YYYY")}</h5>
                                        <h5>•Updated: {Moment(awo.work_order_updated).format("MM/DD/YYYY")}</h5>
                                        <h5>✔ Work Order Created</h5>
                                    </div>
                                    :
                                    <h5>Work Order Needed ❗</h5>
                            }
                        </Col>

                        <Col>
                            {
                                awo.check_list_created
                                    ?
                                    <div>
                                        <h4> Check List Details:</h4>
                                        <h5>•Created: {Moment(awo.check_list_created).format("MM/DD/YYYY")}</h5>
                                        <h5>•Updated: {Moment(awo.check_list_updated).format("MM/DD/YYYY")}</h5>
                                        <h5>✔ Check List</h5>
                                    </div>
                                    :
                                    <h5>Check List Needed ❗</h5>
                            }
                        </Col>

                    </Row>
                )}
            </Container>
        );
    }
}
export default StatusReport;
