import React, {useState, useEffect} from "react";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";


const ClientDetail = () => {

    const [client, setClient] = useState([""])

    const { id } = useParams()

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const getSingleClient = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`clients/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        setClient(data)
    }

    useEffect( () => {
    getSingleClient();
    }, [])


    return (
        <div>
            <h1>Details For {client.work_site_address}</h1>
            <div className={"single-client-info"}>
                <p>Work Site Address: {client.work_site_address}</p>
                <p>Details For Address: {client.details_for_address}</p>
                <p>Project ID: {client.project_ID}</p>
                <p>Referral Name: {client.referral_name}</p>
                <p>Phone: {client.phone}</p>
                <p>Email: {client.email}</p>
                <p>Service Order: {client.service_order}</p>
                <p>Client Name: {client.client_name}</p>
                <p>Client Phone: {client.client_phone}</p>
                <p>Organization's Name: {client.organization_name}</p>
                {
                    client.new_client === "Yes"
                    ?
                        (<p style={{background:'red', color: 'white'}}>New Client: {client.new_client}</p>)
                        :
                        (<p>New Client: {client.new_client}</p>)
                }
                <p>From Where: {client.from_where}</p>
                <p>From Where Description: {client.from_where_description}</p>
                <p>Requested Service: {client.requested_service}</p>
                <p>Are Prints Available: {client.are_prints_available}</p>
                <p>Is it an environmental firm: {client.is_it_an_environmental_firm}</p>
                <p>Is the State Involved: {client.is_the_state_involved}</p>
                <p>State ID: {client.state_id}</p>
                <p>Additional Comments: {client.additional_comments}</p>

                <Link className={'btn btn-primary'} to={`/clients/update/${client.id}/`}>Update Client</Link>
            </div>

        </div>
    );
};

export default ClientDetail;
