import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useSelector} from "react-redux";

const AddWorkHours = () => {

    let navigate = useNavigate();

    const [client, setClient] = useState()
    const [address, setAddress] = useState()
    const [projectID, setProjectID] = useState()

    let employees_onsite = "N/A"
    let work_description = "N/A"
    let finished = "N/A"

    let hours = "N/A"
    let minutes = "N/A"

    let rework = "N/A"
    let photo1 = null
    let photo_1_description = "N/A"
    let photo2 = null
    let photo_2_description = "N/A"
    let photo3 = null
    let photo_3_description = "N/A"
    let photo4 = null
    let photo_4_description = "N/A"
    let photo5 = null
    let photo_5_description = "N/A"
    let photo6 = null
    let photo_6_description = "N/A"
    let photo7 = null
    let photo_7_description = "N/A"
    let photo8 = null
    let photo_8_description = "N/A"
    let photo9 = null
    let photo_9_description = "N/A"
    let photo10 = null
    let photo_10_description = "N/A"
    let photo11 = null
    let photo_11_description = "N/A"
    let photo12 = null
    let photo_12_description = "N/A"
    let photo13 = null
    let photo_13_description = "N/A"
    let photo14 = null
    let photo_14_description = "N/A"
    let photo15 = null
    let photo_15_description = "N/A"

    const {id} = useParams();

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin
    const loadClient = async () => {
        const {data} = await axios.get(process.env.REACT_APP_API+`clients/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data.id)
        setClient(data.id)
        setAddress(data.work_site_address)
        setProjectID(data.project_ID)
    }

    useEffect(() => {
        loadClient()
    }, []);


    const AddHours = async () => {


        let formField = new FormData()

            formField.append('client', client)
            formField.append('employees_onsite', employees_onsite)
            formField.append('work_description', work_description)
            formField.append('rework', rework)
            formField.append('finished', finished)
            formField.append('hours', hours)
            formField.append('minutes', minutes)

            formField.append('photo_1_description', photo_1_description)
            formField.append('photo_2_description', photo_2_description)
            formField.append('photo_3_description', photo_3_description)
            formField.append('photo_4_description', photo_4_description)
            formField.append('photo_5_description', photo_5_description)
            formField.append('photo_6_description', photo_6_description)
            formField.append('photo_7_description', photo_7_description)
            formField.append('photo_8_description', photo_8_description)
            formField.append('photo_9_description', photo_9_description)
            formField.append('photo_10_description', photo_10_description)
            formField.append('photo_11_description', photo_11_description)
            formField.append('photo_12_description', photo_12_description)
            formField.append('photo_13_description', photo_13_description)
            formField.append('photo_14_description', photo_14_description)
            formField.append('photo_15_description', photo_15_description)

            if (photo1 !== null) {
                formField.append('photo1', photo1)
            }

            if (photo2 !== null) {
                formField.append('photo2', photo2)
            }

            if (photo3 !== null) {
                formField.append('photo3', photo3)
            }

            if (photo4 !== null) {
                formField.append('photo4', photo4)
            }

            if (photo5 !== null) {
                formField.append('photo5', photo5)
            }

            if (photo6 !== null) {
                formField.append('photo6', photo6)
            }

            if (photo7 !== null) {
                formField.append('photo7', photo7)
            }

            if (photo8 !== null) {
                formField.append('photo8', photo8)
            }

            if (photo9 !== null) {
                formField.append('photo9', photo9)
            }

            if (photo10 !== null) {
                formField.append('photo10', photo10)
            }

            if (photo11 !== null) {
                formField.append('photo11', photo11)
            }

            if (photo12 !== null) {
                formField.append('photo12', photo12)
            }

            if (photo13 !== null) {
                formField.append('photo13', photo13)
            }

            if (photo14 !== null) {
                formField.append('photo14', photo14)
            }

            if (photo15 !== null) {
                formField.append('photo15', photo15)
            }

            // console.log(formField)
            await axios({
                method: 'post',
                url: process.env.REACT_APP_API+`clients/${client}/addhours/`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                },
                data: formField,
            }).then(() => {
                //TODO: May have to reassign navigation
                navigate(`/clienthours/${client}/`)
            })

    }


    return (

        <div>


            <Form>
                <h1>Create Work Hours for: {address}</h1>
                <h2>Project: {projectID}</h2>

                <br/>

                <Button className={'btn btn-success'} onClick={AddHours}>Add Hours</Button>
            </Form>

        </div>
    );

};


export default AddWorkHours

