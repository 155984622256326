import React, {Component,} from "react";
import {Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import {Link} from "react-router-dom";
import {ClockLoader} from "react-spinners";
import Moment from "moment";


//TODO: the refresh needs a timer on it. Otherwise it just runs all the time

export class PriorityReviewedJobs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // Active Work Orders clients
            AWOS: [],
            clientIsLoading: true,

            // Active WorkOrders
            ACWO: [],
            workOrderIsLoading: true,

            // Active Scheduled Jobs.
            ASJ: [],
            scheduledJobsIsLoading: true,

            // Reviewed Priority Jobs
            RPJ: [],
            reviewedPriorityJobsIsLoading: true,

            // Closed Scheduled Jobs.
            CSJ: [],
            closedScheduledJobsIsLoading: true,

        }
    }


    componentDidMount() {
        axios.get([process.env.REACT_APP_API+'reviewed_workorders/',
        ], {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({AWOS: response.data});
                this.setState({clientIsLoading: false});
            })

        axios.get([process.env.REACT_APP_API+'workorders/',
        ], {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({ACWO: response.data});
                this.setState({workOrderIsLoading: false});
            })

        axios.get([process.env.REACT_APP_API+'open_scheduled_jobs/',
        ], {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({ASJ: response.data});
                this.setState({scheduledJobsIsLoading: false});
            })

        axios.get([process.env.REACT_APP_API+'reviewed_workorders_with_priority/',
       ], {
           headers: {
               "Accept": 'application/json',
               "Content-Type": 'application/json'
           },
       })
           .then(response => {
               this.setState({RPJ: response.data});
               this.setState({reviewedPriorityJobsIsLoading: false});
           })

        axios.get([process.env.REACT_APP_API+'closed_calender_jobs/',
        ], {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({CSJ: response.data});
                this.setState({closedScheduledJobsIsLoading: false});
            })

    }


    render() {

        const {
            AWOS, ACWO, ASJ, RPJ, CSJ, clientIsLoading, workOrderIsLoading, scheduledJobsIsLoading, closedScheduledJobsIsLoading, reviewedPriorityJobsIsLoading} = this.state;

        if (clientIsLoading || workOrderIsLoading || scheduledJobsIsLoading || closedScheduledJobsIsLoading || reviewedPriorityJobsIsLoading) {
            return <div className={'loadingSpinner'}>Loading...<br/> <ClockLoader color={'#0d00ff'}/></div>
        }

        let activeWorkOrders = ACWO.map((client) => client.client)
        let activeScheduledJobs = ASJ.map((client) => client.client)
        let closedScheduledJobsCreated = CSJ.map((client)=> client.client)
        let closedScheduledJobsCreatedReason = CSJ.map((client)=>client.job_status)

        return (

            <Container fluid>
                <div
                    className={AWOS}
                >
                    {RPJ.map((rpj) =>

                        <Row
                            className={"logisticsWorkOrderRow"}
                            key={rpj.id}
                        >
                            <Col>
                                {
                                    activeWorkOrders.includes(rpj.id)
                                        ?
                                        (<div>
                                            <h6>Created: {Moment(rpj.created).format("MM/DD/YYYY")}</h6>
                                            <Link className={'btn btn-info'}
                                                  to={`/workorders/${rpj.id}/`}> Detail
                                                Work-Order for {rpj.work_site_address} </Link>

                                        </div>)
                                        :
                                        ( <p className={'PassElement'}>No WO for {rpj.work_site_address}</p>)
                                }
                            </Col>
                            <Col>

                                {
                                    activeWorkOrders.includes(rpj.id)
                                        ?
                                        ('So True')
                                        &&
                                        activeScheduledJobs.includes(rpj.id)
                                            ?
                                            (
                                                <div>
                                                    {/*TODO: See if this is grabbing the scheduled job created time, not the client or work order.*/}
                                                    <h6>Created: {Moment(rpj.activeScheduledJobsCreated).format("MM/DD/YYYY")}</h6>
                                                    <Link
                                                        className={'btn btn-info'}
                                                        to={`/scheduled_job/${rpj.id}/`}>
                                                        Detail Job Schedule
                                                    </Link>
                                                </div>
                                            )
                                            :
                                            (
                                                "I dont know what you did, but you broke something."
                                            )
                                            &&
                                            closedScheduledJobsCreated.includes(rpj.id)
                                                ?
                                                (
                                                    <div style={{textAlign: "center"}}>
                                                        {/*TODO: See if I go out of order on these they will give the right reasons for it based off the -1 index.*/}
                                                        <h6>Job is marked as {closedScheduledJobsCreatedReason[rpj.id -1]}. Waiting for checklist completion to remove form screen</h6>

                                                        <Link
                                                            className={'btn btn-danger'}
                                                            to={`/scheduled_job/${rpj.id}/`}>
                                                            Reopen Job to Schedule
                                                        </Link>
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div>
                                                        <h6>Requested Service: {rpj.requested_service}</h6>
                                                        <Link
                                                            className={'btn btn-warning'}
                                                            to={`/clients/${rpj.id}/schedule/`}>
                                                            Schedule Job</Link>
                                                    </div>
                                                )
                                        :
                                        ("Fill out On-Site")
                                }
                            </Col>
                        </Row>
                    )}
                </div>
            </Container>
        );
    }
}

export default PriorityReviewedJobs;



// import React, {Component,} from "react";
// import {Col, Container, Nav, Row} from "react-bootstrap";
// import axios from "axios";
// import {Link} from "react-router-dom";
// import {ClockLoader} from "react-spinners";
// import moment from "moment";
//
//
// //TODO: the refresh needs a timer on it. Otherwise it just runs all the time
//
// export class PriorityReviewedJobs extends Component {
//
//     constructor(props) {
//         super(props);
//         this.state = {
//             // Active Work Orders clients
//             AWOS: [],
//             clientIsLoading: true,
//
//             // Active WorkOrders
//             ACWO: [],
//             workOrderIsLoading: true,
//
//             // Active Scheduled Jobs.
//             ASJ: [],
//             scheduledJobsIsLoading: true,
//
//             // Reviewed Priority Jobs
//             RPJ: [],
//             reviewedPriorityJobsIsLoading: true,
//
//         }
//     }
//
//
//     componentDidMount() {
//         axios.get(['http://127.0.0.1:8000/api/reviewed_workorders/',
//         ], {
//             headers: {
//                 "Accept": 'application/json',
//                 "Content-Type": 'application/json'
//             },
//         })
//             .then(response => {
//                 this.setState({AWOS: response.data});
//                 this.setState({clientIsLoading: false});
//             })
//
//         axios.get(['http://127.0.0.1:8000/api/workorders/',
//         ], {
//             headers: {
//                 "Accept": 'application/json',
//                 "Content-Type": 'application/json'
//             },
//         })
//             .then(response => {
//                 this.setState({ACWO: response.data});
//                 this.setState({workOrderIsLoading: false});
//             })
//
//         axios.get(['http://127.0.0.1:8000/api/open_scheduled_jobs/',
//         ], {
//             headers: {
//                 "Accept": 'application/json',
//                 "Content-Type": 'application/json'
//             },
//         })
//             .then(response => {
//                 this.setState({ASJ: response.data});
//                 this.setState({scheduledJobsIsLoading: false});
//             })
//
//         axios.get(['http://127.0.0.1:8000/api/reviewed_workorders_with_priority/',
//         ], {
//             headers: {
//                 "Accept": 'application/json',
//                 "Content-Type": 'application/json'
//             },
//         })
//             .then(response => {
//                 this.setState({RPJ: response.data});
//                 this.setState({reviewedPriorityJobsIsLoading: false});
//             })
//
//     }
//
//
//
//
//     render() {
//
//         const {
//             AWOS, ACWO, ASJ, RPJ, clientIsLoading, workOrderIsLoading, scheduledJobsIsLoading, reviewedPriorityJobsIsLoading} = this.state;
//
//         if (clientIsLoading || workOrderIsLoading || scheduledJobsIsLoading || reviewedPriorityJobsIsLoading) {
//             return <div className={'loadingSpinner'}>Loading...<br/> <ClockLoader color={'#0d00ff'}/></div>
//         }
//
//         let activeWorkOrders = ACWO.map((client) => client.client)
//         let activeScheduledJobs = ASJ.map((client) => client.client)
//         let reviewedPriorityJobs = RPJ.map((client) => client.client)
//
//         return (
//
//             <Container fluid>
//
//                 <div
//                     className={AWOS}
//                 >
//                     {RPJ.map((rpj) =>
//                         <Row
//                             className={"logisticsWorkOrderRow"}
//                             key={rpj.id}
//                         >
//                             <Col>
//                                 {
//                                     activeWorkOrders.includes(rpj.id)
//                                         ?
//                                         ( <div>
//                                                 {<h6>Created: {moment(rpj.created).format("MM/DD/YYYY")} </h6>}
//                                                 <Link className={'btn btn-info'}
//                                                       to={`/workorders/${rpj.id}/`}> Detail
//                                                     Work-Order for {rpj.work_site_address}</Link>
//                                         </div>
//                                             )
//                                         :
//                                         ( <p className={'PassElement'}>No WO for {rpj.work_site_address}</p>)
//                                 }
//                             </Col>
//                             <Col>
//
//                                 {
//                                     activeWorkOrders.includes(rpj.id)
//                                         ?
//                                         ('So True')
//                                         &&
//                                         reviewedPriorityJobs.includes(rpj.id)
//                                             ?
//                                             (
//                                                 <Link
//                                                     className={'btn btn-info'}
//                                                     to={`/proposals/${rpj.id}/`}>
//                                                     Detail Job Schedule
//                                                 </Link>
//                                             )
//                                             :
//                                             (
//                                                 <div>
//                                                     <h6>Requested Service: {rpj.requested_service} </h6>
//                                                     <Link
//                                                         className={'btn btn-warning'}
//                                                         to={`/clients/${rpj.id}/schedule/`}>
//                                                         Schedule Job </Link>
//                                                 </div>
//                                             )
//                                         :
//                                         ("Fill out On-Site")
//                                 }
//                             </Col>
//                         </Row>
//                     )}
//                 </div>
//             </Container>
//         );
//     }
// }
//
// export default PriorityReviewedJobs;
