import { Container } from "react-bootstrap";
import Header from "./components/Header";
import {
    HashRouter as Router,
    Navigate,
    Route,
    Routes
} from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import AdminDash from "./screens/AdminDash";
import OperationsDash from "./screens/OperationsDash";
import WorkOrders from "./screens/WorkOrders";
import ServiceOrders from "./screens/ServiceOrders";
import CreateClient from "./screens/CreateClient";
import ActiveWorkOrders from "./screens/ActiveWorkOrders";
import FinishedWorkOrders from "./screens/FinishedWorkOrders";
import OpenServiceOrders from "./screens/OpenServiceOrders";
import ClosedServiceOrders from "./screens/ClosedServiceOrders";
import FinanceDash from "./screens/FinanceDash";
import FinanceReports from "./screens/FinanceReports";
import InvoicedJobsNotPaid from "./screens/InvoicedJobsNotPaid";
import CustomInvoiceReport from "./screens/CustomInvoiceReport";
import ProposalsNotSigned from "./screens/ProposalsNotSigned";
import SignedProposals from "./screens/SignedProposals";
import CustomProposals from "./screens/CustomProposals";
import OpenServiceOrderReport from "./screens/OpenServiceOrderReport";
import ClosedServiceOrdersReport from "./screens/ClosedServiceOrdersReport";
import CustomServiceOrderReport from "./screens/CustomServiceOrderReport";
import JobsNotApproved from "./screens/JobsNotApproved";
import ApprovedJobs from "./screens/ApprovedJobs";
import FinanceActiveWorkOrders from "./screens/FinanceActiveWorkOrders";
import ClosedWorkOrdersFinance from "./screens/ClosedWorkOrdersFinance";
import CalenderAssignedEmployees from "./screens/CalenderAssignedEmployees";
import LogisticsDash from "./screens/LogisticsDash";
import UnscheduledWorkOrders from "./screens/UnscheduledWorkOrders";
import LogisticsActiveWorkOrders from "./screens/LogisticsActiveWorkOrders";
import CalenderScheduledJobs from "./screens/CalenderScheduledJobs";
import SafetyDash from "./screens/SafetyDash";
import SDS from "./screens/SDS";
import EmployeeTraining from "./screens/EmployeeTraining";
import InventoryDash from "./screens/InventoryDash";
import TechDash from "./screens/TechDash";
import TechWorkOrders from "./screens/TechWorkOrders";
import TechServiceOrders from "./screens/TechServiceOrders";
import ClientDetail from "./screens/ClientDetail";
import ClientUpdate from "./screens/ClientUpdate";
import CreatePreSite from "./screens/CreatePreSite";
import OpsFormButton from "./components/OpsFormButton";
import PresiteDetail from "./screens/PresiteDetail";
import UpdatePresite from "./screens/UpdatePresite";
import CreateOnSite from "./screens/CreateOnSite";
import OnSiteDetail from "./screens/OnSiteDetail";
import UpdateOnSite from "./screens/UpdateOnSite";
import CreateProposal from "./screens/CreateProposal";
import ProposalDetail from "./screens/ProposalDetail";
import UpdateProposal from "./screens/UpdateProposal";
import CreateInvoice from "./screens/CreateInvoice";
import InvoiceDetails from "./screens/InvoiceDetails";
import UpdateInvoice from "./screens/UpdateInvoice";
import CreateWorkOrder from "./screens/CreateWorkOrder";
import WorkOrderDetails from "./screens/WorkOrderDetails";
import UpdateWorkOrder from "./screens/UpdateWorkOrder";
import CreateCheckList from "./screens/CreateCheckList";
import CheckListDetail from "./screens/CheckListDetail";
import UpdateCheckList from "./screens/UpdateCheckList";
import AddWorkHours from "./screens/AddWorkHours";
import DetailWorkHours from "./screens/DetailWorkHours";
import TestScreen from "./screens/TestScreen";
import UpdateWorkHours from "./screens/UpdateWorkHours";
import AllHoursWorked from "./screens/AllHoursWorked";
import PaidInvoices from "./screens/PaidInvoices";
import CustomProposalNotes from "./components/CustomProposalNotes";
import CustomInvoiceNotes from "./components/CustomInvoiceNotes";
import StatusReport from "./screens/StatusReport";
import AddJobPriority from "./screens/AddJobPriority";
import DetailPriority from "./screens/DetailPriority";
import UpdatePriority from "./screens/UpdatePriority";
import AllPriorityJobs from "./screens/AllPriorityJobs";
import InactivePriorityJobs from "./screens/InactivePriorityJobs";
import Calender from "./components/Calender";
import CreateScheduleJobEvent from "./screens/CreateScheduleJobEvent";
import PriorityUnassignedNotReviewed from "./components/PriorityUnassignedNotReviewed";
import PriorityAssignedNotReviewed from "./components/PriorityAssignedNotReviewed";
import PriorityReviewedJobs from "./components/PriorityReviewedJobs";
import NoPiorityReviewedJobs from "./components/NoPiorityReviewedJobs";
import DetailedScheduledJob from "./screens/DetailedScheduledJob";
import UpdateJobSchedule from "./screens/UpdateJobSchedule";
import LogisticsReport from "./screens/LogisticsReport";
import CustomReportsVideo from "./components/CustomReportsVideo";
import LogisticsVideo from "./components/LogisticsVideo";
import CreateComment from "./screens/CreateComment";
import DetailComment from "./screens/DetailComment";
import UpdateComments from "./screens/UpdateComments";
import AddServiceOrder from "./screens/AddServiceOrder";
import ClientServiceOrders from "./screens/ClientServiceOrders";
import DetailServiceOrder from "./screens/DetailServiceOrder";
import UpdateServiceOrder from "./screens/UpdateServiceOrder";
import ClosedClientServiceOrders from "./screens/ClosedClientServiceOrders";
import ClientReport from "./screens/ClientReport";
import LoginScreen from "./screens/LoginScreen";
import AdminOpenServiceOrders from "./screens/AdminOpenServiceOrders";
import {useSelector} from "react-redux";
import RestrictionPage from "./screens/RestrictionPage";
import LogisticsActiveServiceOrders from "./screens/LogisticsActiveServiceOrders";




function App() {
    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin
    let permission = ''

    if (userInfo){
        if (userInfo.group === "AdminSuper"){
            permission = 'Admin'
        } else if (userInfo.group === "Finance") {
            permission = 'Finance'
        } else if (userInfo.group === "Logistics") {
            permission = 'Logistics'
        } else if (userInfo.group === "Tech") {
            permission = 'Tech'
        }
    }


  return (
      <Router>
          <Header />
          <main className={'py-3'}>
              <Container>
                  <Routes>
                      <Route exact path="/" element={<HomeScreen />} />
                      <Route path={'/login'} element={<LoginScreen />} />
                      <Route path={'/restriction'} element={<RestrictionPage />} />
                      {/*<Route element={<UserConstants/>} /> */}
                      {/*<Route element={<UserReducer/>} />*/}

                      {/*Components*/}
                      <Route element={<OpsFormButton />} />

                      {/*Operations Routes*/}

                      <Route
                          path="/admin_dash"
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <AdminDash /> }
                      />;
                      <Route
                          path="/operations_dash"
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <OperationsDash /> }
                      />
                      <Route
                          path="/manage_work_orders"
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <WorkOrders />}
                      />
                      <Route
                          path={'/manage_service_orders'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <ServiceOrders />}
                      />
                      <Route
                          path={'/create_client'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <CreateClient />}
                      />
                      <Route
                          path={'/client_details/:id/'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <ClientDetail />}
                      />
                      <Route
                          path={'/clients/update/:id/'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <ClientUpdate />}
                      />
                      <Route
                          path={'/active_work_orders'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <ActiveWorkOrders />}
                      />
                      <Route
                          path={'/finished_work_orders'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <FinishedWorkOrders />}
                      />
                      <Route
                          path={'/open_service_orders'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <OpenServiceOrders />}
                      />
                      <Route
                          path={'/closed_service_orders'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <ClosedServiceOrders />}
                      />
                      <Route
                          path={'/status_reports'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <StatusReport />}
                      />
                      <Route
                          path={'/client_report'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <ClientReport />}
                      />


                      {/*Comments Routes*/}
                      <Route
                          path={'/clients/:id/add_comment'}
                          element={permission ==='Admin' || permission==='Tech' || permission==='Logistics' ? <CreateComment /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/comment/:id/'}
                          element={permission ==='Admin' || permission==='Tech' || permission==='Logistics' ? <DetailComment /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'comments/update/:id/'}
                          element={permission ==='Admin' || permission==='Tech' || permission==='Logistics' ? <UpdateComments /> : <Navigate to="/restriction" />}
                      />

                      {/*Set Priority*/}

                      <Route
                          path={'clients/:id/addpriority/'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <AddJobPriority />}
                      />

                      <Route
                          path={'/priority_reports/:id/'}
                          element={permission ==='Admin' || permission==='Tech' || permission==='Logistics' ? <DetailPriority /> : <Navigate to="/restriction" />}
                      />

                      <Route
                          path={'priority_reports/update/:id/'}
                          element={permission ==='Admin' || permission==='Tech' || permission==='Logistics' ? <UpdatePriority /> : <Navigate to="/restriction" />}
                      />

                      <Route
                          path={'/priority_reports'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <AllPriorityJobs />}
                      />

                      <Route
                          path={'/done_priority_reports'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <InactivePriorityJobs />}
                      />

                      {/*PRESITE*/}

                      <Route
                          path={'/clients/:id/addpresite/'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <CreatePreSite />}
                      />
                      <Route
                          path={'/presite/:id/'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <PresiteDetail />}
                      />
                      <Route
                          path={'presite/update/:id/'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <UpdatePresite />}
                      />

                      {/*OnSite*/}
                      <Route
                          path={'/clients/:id/addonsite/'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <CreateOnSite />}
                      />
                      <Route
                          path={'/onsite/:id/'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <OnSiteDetail />}
                      />
                      <Route
                          path={'onsite/update/:id/'}
                          element={permission!=='Admin' ? <Navigate to="/restriction" /> : <UpdateOnSite />}
                      />

                      {/*Proposal*/}
                      <Route
                          path={'/clients/:id/addproposal/'}
                          element={permission ==='Admin' || permission==='Finance' ? <CreateProposal /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/proposals/:id/'}
                          element={permission ==='Admin' || permission==='Finance' ? <ProposalDetail /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'proposal/update/:id/'}
                          element={permission ==='Admin' || permission==='Finance' ? <UpdateProposal /> : <Navigate to="/restriction" />}
                      />

                      {/*Invoice*/}
                      <Route
                          path={'clients/:id/addinvoice/'}
                          element={permission ==='Admin' || permission==='Finance' ? <CreateInvoice /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/invoices/:id/'}
                          element={permission ==='Admin' || permission==='Finance' ? <InvoiceDetails /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'invoice/update/:id/'}
                          element={permission ==='Admin' || permission==='Finance' ? <UpdateInvoice /> : <Navigate to="/restriction" />}
                      />

                      {/*WorkOrders*/}
                      <Route
                          path={'clients/:id/addworkorder/'}
                          element={permission ==='Admin' || permission==='Finance' ? <CreateWorkOrder /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'workorders/:id/'}
                          element={permission ==='Admin' || permission==='Finance' || permission==='Logistics' || permission==='Tech' ? <WorkOrderDetails /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'workorder/update/:id/'}
                          element={permission ==='Admin' || permission==='Finance' || permission==='Logistics' || permission==='Tech' ? <UpdateWorkOrder /> : <Navigate to="/restriction" />}
                      />

                      {/*Check Lists*/}
                      <Route
                          path={'clients/:id/addchecklist/'}
                          element={permission ==='Admin' || permission==='Finance' || permission==='Tech' ? <CreateCheckList /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'checklist/:id/'}
                          element={permission ==='Admin' || permission==='Finance' || permission==='Tech' ? <CheckListDetail /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'check_list/update/:id/'}
                          element={permission ==='Admin' || permission==='Finance' || permission==='Tech' ? <UpdateCheckList /> : <Navigate to="/restriction" />}
                      />

                      {/*Hours Worked*/}
                      <Route
                          path={'clients/:id/addhours/'}
                          element={permission ==='Admin' || permission==='Tech' ? <AddWorkHours /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'workhour/:id/'}
                          element={permission ==='Admin' || permission==='Tech' ? <DetailWorkHours /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'workhour/update/:id/'}
                          element={permission ==='Admin' || permission==='Tech' ? <UpdateWorkHours /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/clienthours/:id/'}
                          element={permission ==='Admin' || permission==='Tech' ? <AllHoursWorked /> : <Navigate to="/restriction" />}
                      />

                      {/*Service Order*/}
                      <Route
                          path={'clients/:id/add_service_order/'}
                          element={permission ==='Admin' ? <AddServiceOrder /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/client_service_orders/:id/'}
                          element={permission ==='Admin' || permission==='Tech' ? <ClientServiceOrders /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/service_order/:id/'}
                          element={permission ==='Admin' || permission==='Tech' || permission==='Logistics' ? <DetailServiceOrder /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'service_order/update/:id/'}
                          element={permission ==='Admin' || permission==='Tech' || permission==='Logistics' ? <UpdateServiceOrder /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/closed_service_orders/:id/'}
                          element={permission ==='Admin' ? <ClosedClientServiceOrders /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/admin_open_service_orders/:id/'}
                          element={permission ==='Admin' ? <AdminOpenServiceOrders /> : <Navigate to="/restriction" />}
                      />
                      <Route
                        path={'/logistics_active_service_orders'}
                        element={permission ==='Admin' || permission==='Logistics' ? <LogisticsActiveServiceOrders/> : <Navigate to="/restriction" />}
                      >
                      </Route>

                      {/*  Finance Dash  */}
                      <Route
                          path={'/finance_dash'}
                          element={permission ==='Admin' || permission==='Finance' ? <FinanceDash /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/finance_reports'}
                          element={permission ==='Admin' || permission==='Finance' ? <FinanceReports /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/invoiced_jobs_not_paid'}
                          element={permission ==='Admin' || permission==='Finance' ? <InvoicedJobsNotPaid /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/paid_invoices'}
                          element={permission ==='Admin' || permission==='Finance' ? <PaidInvoices /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/custom_invoice_report'}
                          element={permission ==='Admin' || permission==='Finance' ? <CustomInvoiceReport /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/proposals_not_signed'}
                          element={permission ==='Admin' || permission==='Finance' ? <ProposalsNotSigned /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/signed_proposals'}
                          element={permission ==='Admin' || permission==='Finance' ? <SignedProposals /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/custom_proposals'}
                          element={permission ==='Admin' || permission==='Finance' ? <CustomProposals /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/open_service_order_report'}
                          element={permission ==='Admin' || permission==='Finance' ? <OpenServiceOrderReport /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/closed_service_orders_report'}
                          element={permission ==='Admin' || permission==='Finance' ? <ClosedServiceOrdersReport /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/custom_service_order_report'}
                          element={permission ==='Admin' || permission==='Finance' ? <CustomServiceOrderReport /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/jobs_not_approved'}
                          element={permission ==='Admin' || permission==='Finance' ? <JobsNotApproved /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/approved_jobs'}
                          element={permission ==='Admin' || permission==='Finance' ? <ApprovedJobs /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/finance_active_work_orders'}
                          element={permission ==='Admin' || permission==='Finance' ? <FinanceActiveWorkOrders /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/closed_work_orders_finance'}
                          element={permission ==='Admin' || permission==='Finance' ? <ClosedWorkOrdersFinance /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/custom_proposal_notes'}
                          element={permission ==='Admin' || permission==='Finance' ? <CustomProposalNotes /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/custom_invoice_notes'}
                          element={permission ==='Admin' || permission==='Finance' ? <CustomInvoiceNotes /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/custom_reports_video'}
                          element={permission ==='Admin' || permission==='Finance' ? <CustomReportsVideo /> : <Navigate to="/restriction" />}
                      />

                      {/*  Finance and Logistics  */}
                      <Route
                          path={'/calender_assigned_employees'}
                          element={permission ==='Admin' || permission==='Finance' || permission==='Logistics' ? <CalenderAssignedEmployees /> : <Navigate to="/restriction" />}
                      />

                      {/*  Logistics  */}
                      <Route
                          path={'/logistics_dash'}
                          element={permission ==='Admin' || permission==='Logistics' ? <LogisticsDash /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/unscheduled_work_orders'}
                          element={permission ==='Admin' || permission==='Logistics' ? <UnscheduledWorkOrders /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/logistics_active-work_orders'}
                          element={permission ==='Admin' || permission==='Logistics' ? <LogisticsActiveWorkOrders /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/calender_scheduled_jobs'}
                          element={permission ==='Admin' || permission==='Finance' || permission==='Logistics' || permission==='Tech'  ? <CalenderScheduledJobs /> : <Navigate to="/restriction" />}
                      />
                      {/*<Route path={'/add_event_modal'} element={<AddEventModal />}/>*/}
                      <Route
                          path={'/clients/:id/schedule/'}
                          element={permission ==='Admin' || permission==='Logistics' ? <CreateScheduleJobEvent /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/calender'}
                          element={permission ==='Admin' || permission==='Finance' || permission==='Logistics' || permission==='Tech' ? <Calender /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          element={permission ==='Admin' || permission==='Logistics' ? <PriorityUnassignedNotReviewed /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          element={permission ==='Admin' || permission==='Logistics' ? <PriorityAssignedNotReviewed /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          element={permission ==='Admin' || permission==='Logistics' ? <PriorityReviewedJobs /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          element={permission ==='Admin' || permission==='Logistics' ? <NoPiorityReviewedJobs /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/scheduled_job/:id'}
                          element={permission ==='Admin' || permission==='Logistics' ? <DetailedScheduledJob /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/scheduled/update/:id/'}
                          element={permission ==='Admin' || permission==='Logistics' ? <UpdateJobSchedule /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/logistics_report'}
                          element={permission ==='Admin' || permission==='Logistics' ? <LogisticsReport /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          element={permission ==='Admin' || permission==='Logistics' ? <LogisticsVideo /> : <Navigate to="/restriction" />}
                      />

                      {/*  Tech Dash */}
                      <Route
                          path={'/tech_dash'}
                          element={permission ==='Admin' || permission==='Tech' ? <TechDash /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/tech_work_orders'}
                          element={permission ==='Admin' || permission==='Tech' ? <TechWorkOrders /> : <Navigate to="/restriction" />}
                      />
                      <Route
                          path={'/tech_service_orders'}
                          element={permission ==='Admin' || permission==='Tech' ? <TechServiceOrders /> : <Navigate to="/restriction" />}
                      />

                      {/*  Safety Dash*/}
                      <Route path={'/safety_dash'} element={<SafetyDash />} />
                      <Route path={'/sds'} element={<SDS />} />
                      <Route path={'/employee_training'} element={<EmployeeTraining />} />

                      {/*  Inventory Dash*/}
                      <Route path={'/inventory_dash'} element={<InventoryDash />} />

                        {/*    TEST SCREEN */}
                      <Route
                          path={'/test'}
                          element={permission ==='Admin' ? <TestScreen /> : <Navigate to="/restriction" />}
                      />

                  </Routes>
              </Container>
          </main>
      </Router>
  );
}

export default App;
