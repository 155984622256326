import React, {Component} from "react";
import {Col, Container, Nav, Row,} from "react-bootstrap";
import axios from "axios";
import {Link} from "react-router-dom";
import {ClockLoader} from "react-spinners";


//TODO: the refresh needs a timer on it. Otherwise it just runs all the time

export class PriorityUnassignedNotReviewed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // Active Work Orders clients
            AWOS: [],
            clientIsLoading: true,

            // Active WorkOrders
            ACWO: [],
            workOrderIsLoading: true,

        }
    }


    componentDidMount() {
        axios.get([process.env.REACT_APP_API+'non_reviewed_workorders/',
        ], {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({AWOS: response.data});
                this.setState({clientIsLoading: false});
            })


        axios.get([process.env.REACT_APP_API+'workorders/',
        ], {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({ACWO: response.data});
                this.setState({workOrderIsLoading: false});
            })
    }


    render() {

        const {
            AWOS, ACWO, clientIsLoading, workOrderIsLoading
        } = this.state;

        if (clientIsLoading || workOrderIsLoading ) {
            return <div className={'loadingSpinner'}>Loading...<br/> <ClockLoader color={'#0d00ff'}/></div>
        }

        let activeWorkOrders = ACWO.map((client) => client.client)

        return (

            <Container fluid>

                <div className={AWOS}>
                    <div>{AWOS.map((awo) =>
                        <Row key={awo.id}>
                            <Col className={"logisticsWorkOrderRow"}>
                                {
                                    activeWorkOrders.includes(awo.id)
                                        ?
                                        (<Link className={'btn btn-info'}
                                               to={`/workorders/${awo.id}/`}> Detail
                                            Work-Order for {awo.work_site_address} </Link>)
                                        :
                                        (<p className={'PassElement'}>No WO for {awo.work_site_address}</p>)
                                }
                            </Col>
                        </Row>
                    )}
                    </div>

                </div>
            </Container>
        );
    }
}

export default PriorityUnassignedNotReviewed;
