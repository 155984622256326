import React, {Component} from "react";
import {Nav, Table} from "react-bootstrap";
import axios from "axios";
import {ClockLoader} from "react-spinners";
import Moment from "moment";

//TODO: the refresh needs a timer on it. Otherwise it just runs all the time

export class PaidInvoices extends Component{

    constructor() {
        super();
        this.state = {
            filterOption: '',
            filteredData: [],
            newData: [],
            invoiceIsLoading: true,
            sum: [],
        };
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API+'invoices_paid/',{
            headers:{
                "Accept":'application/json',
                "Content-Type":'application/json'
            },
        })
            .then(response=>{
                this.setState({newData: response.data});
                this.setState({invoiceIsLoading: false});
            })
    }

    optionSelected(e) {
        this.setState({
            filterOption: e.target.value,
        });
    }

    renderTableData(newData) {
        return newData.map((element, i) => (
            <tr key={i}>
                <td>{Moment(element.created).format("MM/DD/YYYY  hh:mm:ss a")}</td>
                <td>{element.job_type}</td>
                <td>Yes</td>
                <td>{element.client}</td>
                <td>{element.who_to_bill_name}</td>
                <td>{element.bill_amount}</td>
                <td>{element.who_to_bill_phone}</td>
                <td>{element.priority}</td>
                <td>{element.payment_notes}</td>
            </tr>
        ));
    };

    renderFilterOptions(filterOption) {
        if(filterOption) {
            let { newData } = this.state;
            let options = Array.from(new Set(newData.map(element => element[filterOption])));
            return options.map((option,i) => <option key={i} value={option}> {option} </option>)
        }
        return []
    };

    filterOptionSelected(e) {
        let {value} = e.target;
        if(value) {
            let { filterOption, newData } = this.state
            let filteredData = newData.filter(element => element[filterOption] === value);

            const getSum = filteredData.reduce((obj, {bill_amount}) => {
                obj[bill_amount] = bill_amount;
                return obj;
            }, {});

            let sum = 0;
            for (const value of Object.values(getSum)) {
                sum += parseInt(value);
            }

            this.setState({
                filteredData: filteredData,
                getSum: getSum,
                sum: sum.toFixed(2),
            });
        }
    };


    render() {
        let { filterOption, filteredData, newData, invoiceIsLoading, sum} = this.state

        if (invoiceIsLoading){
            return <div className={'loadingSpinner'} >Loading...<br/> <ClockLoader color="#0d00ff" /></div>
        }

        return (
            <div>

                <Nav variant="tabs" defaultActiveKey="/finance_reports" className={'reports_nav_heading'}>
                    <Nav.Item>
                        <Nav.Link eventKey="disabled" disabled>Paid Invoices</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/invoiced_jobs_not_paid">Not Paid Invoices</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/custom_invoice_report">Custom Reports</Nav.Link>
                    </Nav.Item>
                </Nav>
                <h1 className={'perposalInvoiceHeaders'}>Paid Invoices</h1>
                <div>
                    <label>Filter By:</label>
                    <br/>
                    <select onChange={this.optionSelected.bind(this)}>
                        <option value="">  </option>
                        <option value="client">Client</option>
                        <option value="year">Year</option>
                        <option value="year_and_month">Month & Year</option>
                    </select>

                    <select onChange={this.filterOptionSelected.bind(this)}>
                        <option value="">  </option>
                        {this.renderFilterOptions(filterOption)}
                    </select>
                </div>
                <Table responsive="lg" className={'mt-4'} striped bordered hover size={'lg'}>
                    <thead>
                    <th>Invoiced Created On:</th>
                    <th>Job Type:</th>
                    <th>Paid in full?:</th>
                    <th>Address:</th>
                    <th>Client:</th>
                    <th>Bid/Job Total:</th>
                    <th>Phone:</th>
                    <th>Priority: </th>
                    <th>Invoice Notes:</th>
                    </thead>
                    <tbody>
                    {
                        filteredData.length ? this.renderTableData(filteredData) : this.renderTableData(newData)
                    }
                    <div className={'reportsSum'}>
                        TOTAL: {sum}
                    </div>

                    </tbody>
                </Table>
            </div>
        );
    }
}

export default PaidInvoices;



// import React, {Component} from "react";
// import {Nav, Row, Table} from "react-bootstrap";
// import axios from "axios";
// import Moment from "moment/moment";
// import {ClockLoader} from "react-spinners";
//
// export class PaidInvoices extends Component {
//
//     constructor(props) {
//         super(props);
//
//         this.state = {
//             // Active Work Orders clients
//             AWOS: [],
//             // Active Proposals
//             AP: [],
//             // Active Invoices
//             AI: [],
//             // Loading States
//             clientsIsLoading: true,
//             proposalIsLoading: true,
//             invoiceIsLoading: true,
//         }
//     }
//
//
//     componentDidMount() {
//
//
//         axios.get(['http://127.0.0.1:8000/api/invoices_paid/',
//         ],{
//             headers:{
//                 "Accept":'application/json',
//                 "Content-Type":'application/json'
//             },
//         })
//             .then(response=>{
//                 this.setState({AI: response.data});
//                 this.setState({invoiceIsLoading: false});
//             })
//
//     }
//
//     render() {
//
//         const {AI, invoiceIsLoading} = this.state;
//
//         if (invoiceIsLoading){
//             return <div className={'loadingSpinner'} >Loading...<br/> <ClockLoader color="#0d00ff" /></div>
//         }
//
//         return (
//             <div >
//
//                 <Nav variant="tabs" defaultActiveKey="/finance_reports" className={'reports_nav_heading'}>
//                     <Nav.Item>
//                         <Nav.Link href="/invoiced_jobs_not_paid">Not Paid Invoices</Nav.Link>
//                     </Nav.Item>
//                     <Nav.Item>
//                         <Nav.Link eventKey="disabled" disabled>
//                             Paid Invoices
//                         </Nav.Link>
//                     </Nav.Item>
//                     <Nav.Item>
//                         <Nav.Link href="/custom_invoice_report">Custom Reports</Nav.Link>
//                     </Nav.Item>
//                 </Nav>
//
//                 <Row>
//                     <h1 className={'perposalInvoiceHeaders'}>Paid Invoices</h1>
//                     <Table responsive="lg" className={'mt-4'} striped bordered hover size={'lg'}>
//                         <thead>
//                         <tr>
//                             <th>Invoiced Created On:</th>
//                             <th>Job Type:</th>
//                             <th>Paid in full?:</th>
//                             <th>Address:</th>
//                             <th>Client:</th>
//                             <th>Bid/Job Total:</th>
//                             <th>Phone:</th>
//                             <th>Priority: </th>
//                             <th>Invoice Notes:</th>
//                         </tr>
//                         </thead>
//
//                         <tbody>
//                         {AI.map((ai)=>
//                             <tr key={ai.id}>
//                                 <td>
//                                     {Moment(ai.created).format("MM/DD/YYYY  hh:mm:ss a")}
//                                 </td>
//
//                                 <td>
//                                     {ai.job_type}
//                                 </td>
//
//                                 <td>
//                                     Yes
//                                 </td>
//
//                                 <td>
//                                     {ai.client}
//                                 </td>
//
//                                 <td>
//                                     {ai.who_to_bill_name}
//                                 </td>
//
//                                 <td>
//                                     {ai.bill_amount}
//                                 </td>
//
//                                 <td>
//                                     {ai.who_to_bill_phone}
//                                 </td>
//
//                                 <td>
//                                     {ai.priority}
//                                 </td>
//
//                                 <td>
//                                     {ai.payment_notes}
//                                 </td>
//
//                             </tr>
//                         )}
//                         </tbody>
//                     </Table>
//                 </Row>
//             </div>
//         );
//     }
// }
//
// export default PaidInvoices;
//
//
// // export class PaidInvoices extends Component {
// //
// //     constructor(props) {
// //         super(props);
// //
// //         this.state = {
// //             // Active Work Orders clients
// //             AWOS: [],
// //             // Active Proposals
// //             AP: [],
// //             // Active Invoices
// //             AI: [],
// //             // Loading States
// //             clientsIsLoading: true,
// //             proposalIsLoading: true,
// //             invoiceIsLoading: true,
// //         }
// //     }
// //
// //
// //     componentDidMount() {
// //         axios.get(['http://127.0.0.1:8000/api/clients_paid/',
// //         ],{
// //             headers:{
// //                 "Accept":'application/json',
// //                 "Content-Type":'application/json'
// //             },
// //         })
// //             .then(response=>{
// //                 this.setState({AWOS: response.data});
// //                 this.setState({clientsIsLoading: false});
// //             })
// //
// //         axios.get(['http://127.0.0.1:8000/api/perposals_paid/',
// //         ],{
// //             headers:{
// //                 "Accept":'application/json',
// //                 "Content-Type":'application/json'
// //             },
// //         })
// //             .then(response=>{
// //                 this.setState({AP: response.data});
// //                 this.setState({proposalIsLoading: false});
// //             })
// //
// //         axios.get(['http://127.0.0.1:8000/api/invoices_paid/',
// //         ],{
// //             headers:{
// //                 "Accept":'application/json',
// //                 "Content-Type":'application/json'
// //             },
// //         })
// //             .then(response=>{
// //                 this.setState({AI: response.data});
// //                 this.setState({invoiceIsLoading: false});
// //             })
// //
// //     }
// //
// //     render() {
// //
// //         const {AWOS, AP, AI, clientsIsLoading, proposalIsLoading, invoiceIsLoading} = this.state;
// //
// //         if (clientsIsLoading || proposalIsLoading || invoiceIsLoading){
// //             return <div className={'loadingSpinner'} >Loading...<br/> <ClockLoader color="#0d00ff" /></div>
// //         }
// //
// //         return (
// //             <div >
// //
// //                 <Nav variant="tabs" defaultActiveKey="/finance_reports">
// //                     <Nav.Item>
// //                         <Nav.Link href="/invoiced_jobs_not_paid">Not Paid Invoices</Nav.Link>
// //                     </Nav.Item>
// //                     <Nav.Item>
// //                         <Nav.Link eventKey="disabled" disabled>
// //                             Paid Invoices
// //                         </Nav.Link>
// //                     </Nav.Item>
// //                     <Nav.Item>
// //                         <Nav.Link href="/custom_invoice_report">Custom Reports</Nav.Link>
// //                     </Nav.Item>
// //                 </Nav>
// //
// //                 <br/><br/>
// //
// //                 <Row>
// //                     <h1 className={'perposalInvoiceHeaders'}>Paid Invoices</h1>
// //                     <Table responsive="lg" className={'mt-4'} striped bordered hover size={'lg'}>
// //                         <thead>
// //                         <tr>
// //                             <th>Invoiced Created On:</th>
// //                             <th>Job Type:</th>
// //                             <th>Paid in full?:</th>
// //                             <th>Address:</th>
// //                             <th>Client:</th>
// //                             <th>Bid/Job Total:</th>
// //                             <th>Phone:</th>
// //                             <th>Priority: </th>
// //                             <th>Invoice Notes:</th>
// //                         </tr>
// //                         </thead>
// //
// //                         <tbody>
// //                         {AI.map((ai)=>
// //                             <tr key={ai.id}>
// //                                 <td>
// //                                     {Moment(ai.created).format("MM/DD/YYYY  hh:mm:ss a")}
// //                                 </td>
// //
// //                                 <td>
// //                                     {AWOS.at(AWOS.findIndex((AWOS) => AWOS.id===ai.client)).requested_service}
// //                                 </td>
// //
// //                                 <td>
// //                                     Yes
// //                                 </td>
// //
// //                                 <td>
// //                                     {AWOS.at(AWOS.findIndex((AWOS) => AWOS.id===ai.client)).work_site_address}
// //                                 </td>
// //
// //                                 <td>
// //                                     {AP.at(AP.findIndex((AP) => AP.client===ai.client)).who_to_bill_name}
// //                                 </td>
// //
// //                                 <td>
// //                                     {ai.bill_amount}
// //                                 </td>
// //
// //                                 <td>
// //                                     {AP.at(AP.findIndex((AP) => AP.client===ai.client)).who_to_bill_phone}
// //                                 </td>
// //
// //                                 <td>
// //                                     {ai.priority}
// //                                 </td>
// //
// //                                 <td>
// //                                     {ai.payment_notes}
// //                                 </td>
// //
// //                             </tr>
// //                         )}
// //                         </tbody>
// //                     </Table>
// //                 </Row>
// //             </div>
// //         );
// //     }
// // }
// //
// // export default PaidInvoices;
//
