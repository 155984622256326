import React, {useState, useEffect} from 'react';
import {Form, Button} from "react-bootstrap";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const UpdatePriority = () => {

    let navigate = useNavigate();
    // const [address, setAddress] = useState()

    const [client, setClient] = useState()
    const [useId, setId] = useState()

    const [priority_date, set_priority_date] = useState('')
    const [priority, set_priority] = useState('DEFAULT')
    const [finished, set_finished] = useState('DEFAULT')

    const { id } = useParams();

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin
    let redirect = ''
    if (userInfo){
        if (userInfo.group === "AdminSuper"){
            redirect = '/active_work_orders'
        } else if (userInfo.group === "Tech") {
            redirect = '/tech_work_orders'
        }
    }

    const loadPriority = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`priority_reports/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        });
        console.log(data)
        set_priority_date(data.priority_date)
        set_priority(data.priority)
        set_finished(data.finished)

        setId(data.id)
        setClient(data.client)

    }

// console.log(useId)

    useEffect(() => {
        loadPriority();
    }, [])

    const UpdatePriority = async () =>{
        let formField = new FormData()
        formField.append('client', client)
        formField.append('priority_date',priority_date)
        formField.append('priority',priority)
        formField.append('finished',finished)


        await axios({
            method: 'PUT',
            url: process.env.REACT_APP_API+`priority_reports/update/${useId}/`,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            data: formField,
        }).then(() => {
            navigate(redirect)
        })
    }


    return (
        <div className={"banner_heading"}>

            <h1>Update Priority:</h1>

            <Form>

                <Form.Group controlId={'priority_date'}>
                    <Form.Label>Priority Date:</Form.Label>
                    <Form.Control
                        type={'date'}
                        placeholder={"priority_date"}
                        value={priority_date}
                        onChange={(e) => set_priority_date(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'priority'}>
                    <Form.Label>Priority:</Form.Label>
                    <Form.Select
                        value={priority}
                        onChange={(e) => set_priority(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Priority:</option>
                        <option value="Low">Low</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'finished'}>
                    <Form.Label>Finished:</Form.Label>
                    <Form.Select
                        value={finished}
                        onChange={(e) => set_finished(e.target.value)}
                    >
                        <option value={'DEFAULT'}>Set Is Job Finished</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                <br/>

                <Button className={'btn btn-success'} onClick={UpdatePriority}>Update Priority</Button>

            </Form>


        </div>
    )
}

export default UpdatePriority