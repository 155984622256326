import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import axios from "axios";
import {Link} from "react-router-dom";
import {ClockLoader} from "react-spinners";

//TODO: the refresh needs a timer on it. Otherwise it just runs all the time

export class TechServiceOrders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // Active Work Orders clients
            AWOS: [],
            clientIsLoading: true,

            // Active Service Order clients
            ASO: [],
            service_orders_are_loading: true,
        }
    }


    componentDidMount() {
        axios.get(process.env.REACT_APP_API + 'service_order_clients/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({AWOS: response.data});
                this.setState({clientIsLoading: false});
            })

        axios.get(process.env.REACT_APP_API + 'service_orders/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({ASO: response.data});
                this.setState({service_orders_are_loading: false});
            })
    };

    render() {

        const {
            AWOS,
            ASO,
            clientIsLoading,
            service_orders_are_loading,
        } = this.state;

        if (clientIsLoading || service_orders_are_loading) {
            return <div className={'loadingSpinner'}>Loading...<br/> <ClockLoader color={'#0d00ff'}/></div>
        }

        let activeServiceOrders = ASO.map((client) => client.client)

        return (
            <div>
                <h1>Clients With Service Order Jobs:</h1>
                {AWOS.map((awo, index) =>
                    <Row
                        key={awo.work_site_address}
                        index={index}
                        className={"workOrderRow"}
                    >
                        <Col>
                            {/*<Link className={'btn btn-primary'}*/}
                            {/*      to={`/client_details/${awo.id}/`}>*/}
                            {/*    {awo.work_site_address}*/}
                            {/*</Link>*/}
                            <h2>• {awo.work_site_address}</h2>
                        </Col>

                        <Col className={"Col AddHourCol"}>
                            {
                                activeServiceOrders.includes(awo.id)
                                    ?
                                    ('So True')
                                    &&
                                    activeServiceOrders.includes(awo.id)
                                        ?
                                        (<Link className={'btn btn-info'} to={`/client_service_orders/${awo.id}/`}>
                                            Open Service Orders For Client
                                        </Link>)

                                        :
                                        ("Create Service Order For Job")
                                    :
                                    ("Create Service Order For Job")
                            }
                        </Col>

                    </Row>
                )}
            </div>
        );
    }
}

export default TechServiceOrders;



// import React from "react";
//
// function TechServiceOrders() {
//     return (
//         <div className={"banner_heading"}>
//             <h1>Logic for Tech Service Orders from back end to go here</h1>
//         </div>
//     )
// }
//
// export default TechServiceOrders