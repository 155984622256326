import React from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {useState} from "react";
import axios from "axios";
import {preventDefault} from "@fullcalendar/react";
import {useSelector} from "react-redux";

const CreateClient = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [work_site_address, set_work_site_address] = useState('');
    const [details_for_address, set_details_for_address] = useState('N/A');
    const [project_ID, set_project_ID] = useState('');
    const [referral_name, set_referral_name] = useState('N/A');
    const [phone, set_phone] = useState('N/A');
    const [email, set_email] = useState('email@email.com');
    const [service_order, set_service_order] = useState('DEFAULT');
    const [requested_service, set_requested_service] = useState('DEFAULT');
    const [are_prints_available, set_are_prints_available] = useState('DEFAULT');
    const [client_name, set_client_name] = useState('N/A');
    const [client_phone, set_client_phone] = useState('N/A');
    const [is_it_an_environmental_firm, set_is_it_an_environmental_firm] = useState('DEFAULT');
    const [is_the_state_involved, set_is_the_state_involved] = useState('DEFAULT');
    const [additional_comments, set_additional_comments] = useState('N/A');
    const proposal_total = null
    const [new_client, setNewClient] = useState('DEFAULT')
    const [from_where, setFromWhere] = useState('N/A')
    const [from_where_description, setFromWhereDescription] = useState('N/A')
    const [organization_name, setOrganizationName] = useState('N/A')

    const [state_id, set_state_id] = useState('N/A')

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const reload=()=>window.location.reload();

    const AddClientInfo = async () => {
        // preventDefault()
        let formField = new FormData()

        if (work_site_address === ''){
            alert("Need to put in work site address")
        } else if (project_ID === ''){
            alert("Need to enter Project I.D.")
        } else if (requested_service === 'DEFAULT'){
            alert("Need to enter Requested Service")
        } else if (are_prints_available === 'DEFAULT'){
            alert("Enter if prints are available.")
        } else if (is_it_an_environmental_firm === 'DEFAULT'){
            alert("Enter if this is an environmental firm")
        } else if (is_the_state_involved === 'DEFAULT'){
            alert('Enter if the state is involved.')
        } else if (client_phone === 'N/A'){
            alert("Please enter client phone")
        } else if (client_name === 'N/A'){
            alert("Please enter client Name")
        } else if (organization_name === 'N/A'){
            alert('Please enter Organizations Name')
        } else if (new_client === 'DEFAULT'){
            alert("Please enter if this is a new client")
        } else if (new_client === 'Yes' && from_where ==='N/A'){
            alert("Please enter where the new client heard of us")
        } else {
            formField.append('work_site_address', work_site_address)
            formField.append('details_for_address', details_for_address)
            formField.append('project_ID', project_ID)
            formField.append('referral_name', referral_name)
            formField.append('phone', phone)
            formField.append('email', email)
            formField.append('service_order', service_order)
            formField.append('requested_service', requested_service)
            formField.append('are_prints_available', are_prints_available)
            formField.append('new_client', new_client)
            formField.append('from_where', from_where)
            formField.append('from_where_description', from_where_description)
            formField.append('organization_name', organization_name)
            formField.append('client_name', client_name)
            formField.append('client_phone', client_phone)
            formField.append('is_it_an_environmental_firm', is_it_an_environmental_firm)
            formField.append('is_the_state_involved', is_the_state_involved)
            formField.append('state_id', state_id)
            formField.append('additional_comments', additional_comments)
            formField.append('proposal_total', proposal_total)


            await axios({
                method: 'post',
                url: process.env.REACT_APP_API+`clients/create_client/`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                },
                data: formField,
            }).then(() => {
                // console.log(response.data)
                // navigate('/active_work_orders')
                setShow(false);
            })
        }
    }

    return (
        <div className={'formcontainer'}>
            {/*<h1>Create Client {work_site_address}</h1>*/}
            <br/>

            <Button variant="danger" onClick={handleShow}>
                Create Client
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                size="xl"
                onExit={reload}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create Client {work_site_address}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId={'work_site_address'}>
                            <Form.Label>Required: Work Site Address</Form.Label>
                            <Form.Control
                                // aria-required={true}
                                type={'text'}
                                placeholder={"Work Site Address:"}
                                name={'work_site_address'}
                                value={work_site_address}
                                onChange={(e) => set_work_site_address(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'details_for_address'}>
                            <Form.Label>Details For Address</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"Details For Address"}
                                name={'details_for_address'}
                                value={details_for_address}
                                onChange={(e) => set_details_for_address(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'project_ID'}>
                            <Form.Label>Required: Project I.D.</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"Project ID:"}
                                name={'project_ID'}
                                value={project_ID}
                                onChange={(e) => set_project_ID(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'referral_name'}>
                            <Form.Label>Referral Name</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"Referral Name"}
                                name={'referral_name'}
                                value={referral_name}
                                onChange={(e) => set_referral_name(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'phone'}>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"Phone Number"}
                                name={'phone'}
                                value={phone}
                                onChange={(e) => set_phone(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'email'}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type={'email'}
                                placeholder={"Email"}
                                name={'email'}
                                value={email}
                                onChange={(e) => set_email(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'organization_name'}>
                            <Form.Label>Organization Name</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"Required: Organization Name"}
                                name={'organization_name'}
                                value={organization_name}
                                onChange={(e) => setOrganizationName(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'client_name'}>
                            <Form.Label>Client Name</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"Required: Client Name"}
                                name={'client_name'}
                                value={client_name}
                                onChange={(e) => set_client_name(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'client_phone'}>
                            <Form.Label>Client Phone</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"Required: Client Phone"}
                                name={'client_phone'}
                                value={client_phone}
                                onChange={(e) => set_client_phone(e.target.value)}
                            />
                        </Form.Group>


                        <Form.Group controlId={'new_client'}>
                            <Form.Label>Is this a New Client?</Form.Label>
                            <Form.Select
                                // value={service_order}
                                onChange={(e) => setNewClient(e.target.value)}
                                defaultValue={'DEFAULT'}
                            >
                                <option value="DEFAULT" disabled>Is This A New Client?</option>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                            </Form.Select>
                        </Form.Group>

                        {
                            new_client === 'Yes'
                            ?
                                (
                                    <div>
                                    <Form.Group controlId={'from_where'}>
                                    <Form.Label>Where Did The New Client Hear About Us?</Form.Label>
                                    <Form.Select
                                        // value={service_order}
                                        onChange={(e) => setFromWhere(e.target.value)}
                                        defaultValue={'DEFAULT'}
                                    >
                                        <option value="DEFAULT" disabled>Where Did The New Client Hear About Us.</option>
                                        <option value="Referral">Referral</option>
                                        <option value="HSS Site">Hss Site</option>
                                        <option value="Elsewhere Online">Elsewhere Online</option>
                                        <option value="Paper Ad">Paper Ad</option>
                                        <option value="Convention/Show">Convention/Show</option>
                                        <option value="Phone">Phone</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group controlId={'from_where_description'}>
                                    <Form.Label>Please Provide More Details On How They Heard Of Us.</Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder={"Please Provide More Details On How They Heard Of Us"}
                                        name={'from_where_description'}
                                        value={from_where_description}
                                        onChange={(e) => setFromWhereDescription(e.target.value)}
                                    />
                                </Form.Group>
                                    </div>
                                )
                                :
                                ('')
                        }

                        <br />
                        <div className="container">
                            <div className="row">
                                <div className="col-sm">
                                    <Form.Group controlId={'service_order'}>
                                     <Form.Label>Is this a service order?</Form.Label>
                                            <Form.Select
                                                // value={service_order}
                                                onChange={(e) => set_service_order(e.target.value)}
                                                defaultValue={'DEFAULT'}
                                            >
                                                <option value="DEFAULT" disabled>Does this have a service order.</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </Form.Select>
                                    </Form.Group>

                                </div>
                                <div className="col-sm">

                                    <Form.Group controlId={'requested_service'}>
                                        <Form.Label>Required: Requested Service:</Form.Label>

                                            <Form.Select placeholder={"Requested Service"}
                                                // value={requested_service}
                                                onChange={(e) => set_requested_service(e.target.value)}
                                                defaultValue={'DEFAULT'}
                                            >
                                                <option value="DEFAULT" disabled>Select Requested Service.</option>
                                                <option value="Radon New Construction">Radon New Construction</option>
                                                <option value="Single Family Testing">Single Family Testing</option>
                                                <option value="Single Family Radon Mitigation">Single Family Radon Mitigation
                                                </option>
                                                <option value="Multi-Family Testing">Multi-Family Testing</option>
                                                <option value="Multi-Family Mitigation">Multi-Family Mitigation</option>
                                                <option value="VOC">VOC</option>
                                                <option value="Other">Other</option>
                                            </Form.Select>

                                    </Form.Group>

                                </div>
                                <div className="col-sm">

                                    <Form.Group controlId={'are_prints_available'}>
                                        <Form.Label>Required: Are Prints Available:</Form.Label>
                                            <Form.Select
                                                // value={are_prints_available}
                                                onChange={(e) => set_are_prints_available(e.target.value)}
                                                defaultValue={'DEFAULT'}
                                            >
                                                <option value="DEFAULT" disabled>Are Prints Available?</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </Form.Select>
                                    </Form.Group>

                                </div>
                            </div>

                            <br/>

                            <div className="row">
                                <div className="col-sm">

                                    <Form.Group controlId={'is_it_an_environmental_firm'}>
                                        <Form.Label>Required: Is it an environmental firm:</Form.Label>
                                            <Form.Select
                                                // value={is_it_an_environmental_firm}
                                                onChange={(e) => set_is_it_an_environmental_firm(e.target.value)}
                                                defaultValue={'DEFAULT'}
                                            >
                                                <option value="DEFAULT" disabled>Is it an environmental firm?</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </Form.Select>
                                    </Form.Group>

                                </div>
                                <div className="col-sm">

                                    <Form.Group controlId={'is_the_state_involved'}>
                                       <Form.Label>Required: Is The State Involved:</Form.Label>
                                            <Form.Select
                                                // value={is_the_state_involved}
                                                onChange={(e) => set_is_the_state_involved(e.target.value)}
                                                defaultValue={'DEFAULT'}
                                            >
                                                <option value="DEFAULT" disabled>Is the state involved?</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </Form.Select>

                                    </Form.Group>

                                    {
                                        is_the_state_involved === 'Yes'
                                        ?
                                            ( <div className="col-sm">
                                                <Form.Group controlId={'state_id'}>
                                                    <Form.Label>State ID:</Form.Label>
                                                    <Form.Control
                                                        type={'text'}
                                                        placeholder={"Required: State ID"}
                                                        name={'state_id'}
                                                        value={state_id}
                                                        onChange={(e) => set_state_id(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </div>)
                                            :
                                            ('')
                                    }
                                </div>

                            </div>
                        </div>

                        <br/>

                        <Form.Group controlId={'additional_comments'}>
                            <Form.Label>Additional Comments</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"Additional Comments"}
                                name={'additional_comments'}
                                value={additional_comments}
                                onChange={(e) => set_additional_comments(e.target.value)}
                            />
                        </Form.Group>

                        <br/>

                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {/*<Button variant="primary" onClick={handleClose}>*/}
                    {/*    Save Changes*/}
                    {/*</Button>*/}
                    <Button className={'btn btn-success'} onClick={AddClientInfo}>
                        Add Client
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default CreateClient
//
// function CreateClient() {
//
//     let navigate = useNavigate();
//
//     const [form, setForm] = useState({})
//     const [errors, setErrors] = useState({})
//     const setField = (field, value) =>{
//         setForm({
//             ...form,
//             [field]: value
//         })
//         if (!!errors[field])
//             setErrors({
//                 ...errors,
//                 [field]:null,
//             })
//     }
//
//     const validateForm = () =>{
//         const {work_site_address, project_ID, requested_service, are_prints_available, is_it_an_environmental_firm, is_the_state_involved, client_name, client_phone} = form
//         const newErrors = {}
//         if (!work_site_address || work_site_address==='') newErrors.work_site_address = 'Please fill out Work Site Address'
//         if (!project_ID || project_ID==='') newErrors.project_ID = 'Please fill out project id'
//         if (!requested_service || requested_service==='') newErrors.requested_service = 'Please fill out requested service'
//         if (!are_prints_available || are_prints_available==='') newErrors.are_prints_available = 'Please fill out if prints are available'
//         if (!is_it_an_environmental_firm || is_it_an_environmental_firm==='') newErrors.is_it_an_environmental_firm = 'Please indicate if it is an environmental firm'
//         if (!is_the_state_involved || is_the_state_involved==='') newErrors.is_the_state_involved = 'Please indicate if it the state is involved'
//         if (!client_name || client_name==='') newErrors.client_name = 'Please fill out clients name'
//         if (!client_phone || client_phone==='') newErrors.client_phone = 'Please fill out clients phone'
//
//         return newErrors
//     }
//
//     const handleSubmit = async (e) => {
//         e.preventDefault()
//
//         const formErrors = validateForm()
//
//         if (Object.keys(formErrors).length > 0){
//             setErrors(formErrors)
//         }else {
//             let formField = new FormData()
//
//             formField.append('work_site_address', form.work_site_address)
//             formField.append('details_for_address', form.details_for_address)
//             formField.append('project_ID', form.project_ID)
//             formField.append('referral_name', form.referral_name)
//             formField.append('phone', form.phone)
//             formField.append('email', form.email)
//             formField.append('service_order', form.service_order)
//             formField.append('requested_service', form.requested_service)
//             formField.append('are_prints_available', form.are_prints_available)
//             formField.append('purposed_start_date', form.purposed_start_date)
//             formField.append('client_name', form.client_name)
//             formField.append('client_phone', form.client_phone)
//             formField.append('is_it_an_environmental_firm', form.is_it_an_environmental_firm)
//             formField.append('is_the_state_involved', form.is_the_state_involved)
//             formField.append('additional_comments', form.additional_comments)
//
//             console.log(formField)
//
//             await axios({
//                 method: 'post',
//                 url: 'http://127.0.0.1:8000/api/clients/create_client/',
//                 data: formField
//             }).then((response) => {
//                 // console.log(response.data)
//                 navigate('/active_work_orders')
//             })
//         }
//     }
//
//
//     return (
//
//         <div className={"banner_heading"}>
//             <h1>Create Client </h1>
//              <br/>
//
//             <Form>
//                 <Form.Group controlId={'work_site_address'}>
//                     <Form.Control
//                         type={'text'}
//                         placeholder={"Required: Work Site Address:"}
//                         value={form.work_site_address}
//                         isInvalid={!!errors.work_site_address}
//                         onChange={(e) => setField('work_site_address', e.target.value)}
//                     />
//                     <Form.Control.Feedback type={"invalid"}>{errors.work_site_address}</Form.Control.Feedback>
//                 </Form.Group>
//
//                 <Form.Group controlId={'details_for_address'}>
//                     <Form.Control
//                         type={'text'}
//                         placeholder={"Details For Address"}
//                         value={form.details_for_address}
//                         onChange={(e) => setField('details_for_address', e.target.value)}
//                     />
//                 </Form.Group>
//
//                 <Form.Group controlId={'project_ID'}>
//                     <Form.Control
//                         type={'text'}
//                         placeholder={"Required: Project ID:"}
//                         value={form.project_ID}
//                         isInvalid={!!errors.project_ID}
//                         onChange={(e) => setField('project_ID',e.target.value)}
//                     />
//                     <Form.Control.Feedback type={"invalid"}>{errors.project_ID}</Form.Control.Feedback>
//                 </Form.Group>
//
//                 <Form.Group controlId={'referral_name'}>
//                     <Form.Control
//                         type={'text'}
//                         placeholder={"Referral Name"}
//                         value={form.referral_name}
//                         onChange={(e) => setField('referral_name',e.target.value)}
//                     />
//                 </Form.Group>
//
//                 <Form.Group controlId={'phone'}>
//                     <Form.Control
//                         type={'text'}
//                         placeholder={"Phone Number"}
//                         value={form.phone}
//                         onChange={(e) => setField('phone',e.target.value)}
//                     />
//                 </Form.Group>
//
//                 <Form.Group controlId={'email'}>
//                     <Form.Control
//                         type={'email'}
//                         placeholder={"Email"}
//                         value={form.email}
//                         onChange={(e) => setField('email',e.target.value)}
//                     />
//                 </Form.Group>
//
//
//                 <Form.Group controlId={'purposed_start_date'}>
//                     <span className={'form-control form-control-mg'}>
//                         <Form.Label>Purposed Start Date</Form.Label>
//                         <Form.Control
//                             value={form.purposed_start_date}
//                             type={'date'}
//                             onChange={(e) => setField('purposed_start_date',e.target.value)}
//                         />
//                     </span>
//                 </Form.Group>
//
//                 <Form.Group controlId={'client_name'}>
//                     <Form.Control
//                         type={'text'}
//                         placeholder={"Client Name"}
//                         value={form.client_name}
//                         isInvalid={!!errors.client_name}
//                         onChange={(e) => setField('client_name',e.target.value)}
//                     />
//                     <Form.Control.Feedback type={"invalid"}>{errors.client_name}</Form.Control.Feedback>
//                 </Form.Group>
//
//                 <Form.Group controlId={'client_phone'}>
//                     <Form.Control
//                         type={'text'}
//                         placeholder={"Client Phone"}
//                         value={form.client_phone}
//                         isInvalid={!!errors.client_phone}
//                         onChange={(e) => setField('client_phone',e.target.value)}
//                     />
//                     <Form.Control.Feedback type={"invalid"}>{errors.client_phone}</Form.Control.Feedback>
//                 </Form.Group>
//                 <br/>
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-sm">
//
//                             <Form.Group controlId={'service_order'}>
//                                 <Form.Label>Is this a service order?</Form.Label>
//                                 <Form.Select
//                                     value={form.service_order}
//                                     onChange={(e) => setField('service_order',e.target.value)}
//                                 >
//                                     <option value="No" selected>No</option>
//                                     <option value="Yes">Yes</option>
//                                 </Form.Select>
//                             </Form.Group>
//
//                         </div>
//                         <div className="col-sm">
//
//                             <Form.Group controlId={'requested_service'}>
//                                 <Form.Label>Required: Requested Service:</Form.Label>
//
//                                 <Form.Select placeholder={"Requested Service"}
//                                              value={form.requested_service}
//                                              isInvalid={!!errors.requested_service}
//                                              onChange={(e) => setField('requested_service',e.target.value)}
//                                 >
//                                     <option value="Radon New Construction" selected>Radon New Construction</option>
//                                     <option value="Single Family Testing">Single Family Testing</option>
//                                     <option value="Single Family Radon Mitigation">Single Family Radon Mitigation
//                                     </option>
//                                     <option value="Multi-Family Testing">Multi-Family Testing</option>
//                                     <option value="Multi-Family Mitigation">Multi-Family Mitigation</option>
//                                     <option value="VOC">VOC</option>
//                                     <option value="Other">Other</option>
//                                 </Form.Select>
//                                 <Form.Control.Feedback type={"invalid"}>{errors.requested_service}</Form.Control.Feedback>
//
//                             </Form.Group>
//
//                         </div>
//                         <div className="col-sm">
//
//                             <Form.Group controlId={'are_prints_available'}>
//                                 <Form.Label>Required: Are Prints Available:</Form.Label>
//                                 <Form.Select
//                                     value={form.are_prints_available}
//                                     isInvalid={!!errors.are_prints_available}
//                                     onChange={(e) => setField('are_prints_available', e.target.value)}
//                                 >
//                                     <option value="No">No</option>
//                                     <option value="Yes">Yes</option>
//                                 </Form.Select>
//                                 <Form.Control.Feedback type={"invalid"}>{errors.are_prints_available}</Form.Control.Feedback>
//                             </Form.Group>
//
//                         </div>
//                     </div>
//
//                     <br/>
//
//                     <div className="row">
//                         <div className="col-sm">
//
//                             <Form.Group controlId={'is_it_an_environmental_firm'}>
//                                 <Form.Label>Required: Is it an environmental firm:</Form.Label>
//                                 <Form.Select
//                                     value={form.is_it_an_environmental_firm}
//                                     isInvalid={!!errors.is_it_an_environmental_firm}
//                                     onChange={(e) => setField('is_it_an_environmental_firm', e.target.value)}
//                                 >
//                                     <option value="No" selected>No</option>
//                                     <option value="Yes">Yes</option>
//                                 </Form.Select>
//                                 <Form.Control.Feedback type={"invalid"}>{errors.is_it_an_environmental_firm}</Form.Control.Feedback>
//                             </Form.Group>
//
//                         </div>
//                         <div className="col-sm">
//
//                             <Form.Group controlId={'is_the_state_involved'}>
//                                 <Form.Label>Required: Is The State Involved:</Form.Label>
//                                 <Form.Select
//                                     value={form.is_the_state_involved}
//                                     isInvalid={!!errors.is_the_state_involved}
//                                     onChange={(e) => setField('is_the_state_involved',e.target.value)}
//                                 >
//                                     <option value="No" selected>No</option>
//                                     <option value="Yes">Yes</option>
//                                 </Form.Select>
//                                 <Form.Control.Feedback type={"invalid"}>{errors.is_the_state_involved}</Form.Control.Feedback>
//                             </Form.Group>
//
//                         </div>
//                         <div className="col-sm">
//
//                         </div>
//                     </div>
//                 </div>
//
//                 <br/>
//
//                 <Form.Group controlId={'additional_comments'}>
//                     <Form.Control
//                         type={'text'}
//                         value={form.additional_comments}
//                         placeholder={"Additional Comments"}
//                         onChange={(e) => setField('additional_comments', e.target.value)}
//                     />
//                 </Form.Group>
//
//                 <br/>
//                 <Button className={'btn btn-success'} type={'submit'} onClick={handleSubmit}>Add Client</Button>
//
//             </Form>
//         </div>
//     )
// }
//
// export default CreateClient