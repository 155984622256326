import React, {useState, useEffect} from 'react';
import {Form, Button} from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const UpdateOnSite = () => {

    let navigate = useNavigate();
    // const [address, setAddress] = useState()

    const [client, setClient] = useState()
    const [useId, setId] = useState()

    const [height_to_ceiling_in_feet, set_height_to_ceiling_in_feet] = useState('')
    const[roof_access, set_roof_access] = useState('')
    const[type_of_roof, set_type_of_roof] = useState('')
    const [drop_ceiling, set_drop_ceiling] = useState('')
    const [type_of_ceiling, set_type_of_ceiling] = useState('')
    const [electrical, set_electrical] = useState('')
    const [soil_type, set_soil_type] = useState('')
    const [soil_removal, set_soil_removal] = useState('')
    const [water_on_site, set_water_on_site] = useState('')
    const [lights_on_site, set_lights_on_site] = useState('')
    const [electricity_on_site, set_electricity_on_site] = useState('')
    const [bath_room_on_site, set_bath_room_on_site] = useState('')
    const [permit_required, set_permit_required] = useState('')
    const [after_completion, set_after_completion] = useState('')
    const [type_of_permit, set_type_of_permit] = useState('')
    const [who_completed_onsite, set_who_completed_onsite] = useState('')
    const [onsite_notes, set_onsite_notes] = useState('')

    const [photo1, set_photo1] = useState(null)
    const [newPhoto1, setNewPhoto1] = useState(null)

    const [photo2, set_photo2] = useState(null)
    const [newPhoto2, setNewPhoto2] = useState(null)

    const [photo3, set_photo3] = useState(null)
    const [newPhoto3, setNewPhoto3] = useState(null)

    const [photo4, set_photo4] = useState(null)
    const [newPhoto4, setNewPhoto4] = useState(null)

    const [photo5, set_photo5] = useState(null)
    const [newPhoto5, setNewPhoto5] = useState(null)

    const [photo6, set_photo6] = useState(null)
    const [newPhoto6, setNewPhoto6] = useState(null)

    const [photo7, set_photo7] = useState(null)
    const [newPhoto7, setNewPhoto7] = useState(null)

    const [photo8, set_photo8] = useState(null)
    const [newPhoto8, setNewPhoto8] = useState(null)

    const [photo9, set_photo9] = useState(null)
    const [newPhoto9, setNewPhoto9] = useState(null)

    const [photo10, set_photo10] = useState(null)
    const [newPhoto10, setNewPhoto10] = useState(null)

    const { id } = useParams();
    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;

    const loadPreSite = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`onsite/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        });
        // console.log(data)


        set_height_to_ceiling_in_feet(data.height_to_ceiling_in_feet)
        set_roof_access(data.roof_access)
        set_type_of_roof(data.type_of_roof)
        set_drop_ceiling(data.drop_ceiling)
        set_type_of_ceiling(data.type_of_ceiling)
        set_electrical(data.electrical)
        set_soil_type(data.soil_type)
        set_soil_removal(data.soil_removal)
        set_water_on_site(data.water_on_site)
        set_lights_on_site(data.lights_on_site)
        set_electricity_on_site(data.electricity_on_site)
        set_bath_room_on_site(data.bath_room_on_site)
        set_permit_required(data.permit_required)
        set_after_completion(data.after_completion)
        set_type_of_permit(data.type_of_permit)
        set_who_completed_onsite(data.who_completed_onsite)
        set_onsite_notes(data.onsite_notes)

        set_photo1(data.photo1)
        set_photo2(data.photo2)
        set_photo3(data.photo3)
        set_photo4(data.photo4)
        set_photo5(data.photo5)
        set_photo6(data.photo6)
        set_photo7(data.photo7)
        set_photo8(data.photo8)
        set_photo9(data.photo9)
        set_photo10(data.photo10)

        setId(data.id)
        setClient(data.client)

        // console.log(data.id)

        // console.log(data.client)

    }

    useEffect(() => {
        loadPreSite();
    }, [])

    const UpdateOnSiteInfo = async () =>{
        let formField = new FormData()
        formField.append('client', client)
        formField.append('height_to_ceiling_in_feet',height_to_ceiling_in_feet)
        formField.append('roof_access',roof_access)
        formField.append('type_of_roof',type_of_roof)
        formField.append('drop_ceiling',drop_ceiling)
        formField.append('type_of_ceiling',type_of_ceiling)
        formField.append('electrical',electrical)
        formField.append('soil_type',soil_type)
        formField.append('soil_removal',soil_removal)
        formField.append('water_on_site',water_on_site)
        formField.append('lights_on_site',lights_on_site)
        formField.append('electricity_on_site',electricity_on_site)
        formField.append('bath_room_on_site',bath_room_on_site)
        formField.append('permit_required',permit_required)
        formField.append('after_completion',after_completion)
        formField.append('type_of_permit',type_of_permit)
        formField.append('who_completed_onsite',who_completed_onsite)
        formField.append('onsite_notes',onsite_notes)

        if (newPhoto1 !== null){
            formField.append('photo1', newPhoto1)
        }
        if (newPhoto2 !== null){
            formField.append('photo2', newPhoto2)
        }
        if (newPhoto3 !== null){
            formField.append('photo3', newPhoto3)
        }
        if (newPhoto4 !== null){
            formField.append('photo4', newPhoto4)
        }
        if (newPhoto5 !== null){
            formField.append('photo5', newPhoto5)
        }
        if (newPhoto6 !== null){
            formField.append('photo6', newPhoto6)
        }
        if (newPhoto7 !== null){
            formField.append('photo7', newPhoto7)
        }
        if (newPhoto8 !== null){
            formField.append('photo8', newPhoto8)
        }
        if (newPhoto9 !== null){
            formField.append('photo9', newPhoto9)
        }
        if (newPhoto10 !== null){
            formField.append('photo10', newPhoto10)
        }


        await axios({
            method: 'PUT',
            //TODO if this doesnt work try changing this URL
            url: process.env.REACT_APP_API+`onsite/update/${useId}/`,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            data: formField,
        }).then(() => {
            // console.log(response.data)
            // console.log(Error)
            navigate('/active_work_orders')
        })
    }


    return (
        <div className={"banner_heading"}>

            <h1>Update On-Site:</h1>

            <Form>

                <Form.Group controlId={'height_to_ceiling_in_feet'}>
                    <Form.Label>Height to ceiling in feet:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Height To Ceiling In Feet"}
                        value={height_to_ceiling_in_feet}
                        onChange={(e) => set_height_to_ceiling_in_feet(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'roof_access'}>
                    <Form.Label>Roof Access:</Form.Label>
                    <Form.Select
                        value={roof_access}
                        onChange={(e) => set_roof_access(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'type_of_roof'}>
                    <Form.Label>Type of Roof:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Type Of Roof"}
                        value={type_of_roof}
                        onChange={(e) => set_type_of_roof(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'drop_ceiling'}>
                    <Form.Label>Drop Ceiling:</Form.Label>
                    <Form.Select
                        value={drop_ceiling}
                        onChange={(e) => set_drop_ceiling(e.target.value)}
                    >
                        <option value="No" >No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'type_of_ceiling'}>
                    <Form.Label>Type of Ceiling:</Form.Label>
                    <Form.Select
                        value={type_of_ceiling}
                        onChange={(e) => set_type_of_ceiling(e.target.value)}
                    >
                        <option value="Open" >Open</option>
                        <option value="Closed Plenum">Closed Plenum</option>
                        <option value="N/A">N/A</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'electrical'}>
                    <Form.Label>Electrical:</Form.Label>
                    <Form.Select
                        value={electrical}
                        onChange={(e) => set_electrical(e.target.value)}
                    >
                        <option value="phase 1" >phase 1</option>
                        <option value="phase 3">phase 3</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'soil_type'}>
                    <Form.Label>Soil Type:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Soil Type"}
                        value={soil_type}
                        onChange={(e) => set_soil_type(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'soil_removal'}>
                    <Form.Label>Soil Removal:</Form.Label>
                    <Form.Select
                        value={soil_removal}
                        onChange={(e) => set_soil_removal(e.target.value)}
                    >
                        <option value="soil stays on site">soil stays on site</option>
                        <option value="soil is taken with us">soil is taken with us</option>
                        <option value="soil is put in barrel">soil is put in barrel</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'water_on_site'}>
                    <Form.Label>Water on Site:</Form.Label>
                    <Form.Select
                        value={water_on_site}
                        onChange={(e) => set_water_on_site(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'lights_on_site'}>
                    <Form.Label>Lights on Site:</Form.Label>
                    <Form.Select
                        value={lights_on_site}
                        onChange={(e) => set_lights_on_site(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'electricity_on_site'}>
                    <Form.Label>Electricity on Site:</Form.Label>
                    <Form.Select
                        value={electricity_on_site}
                        onChange={(e) => set_electricity_on_site(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'bath_room_on_site'}>
                    <Form.Label>Bathroom on Site:</Form.Label>
                    <Form.Select
                        value={bath_room_on_site}
                        onChange={(e) => set_bath_room_on_site(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'permit_required'}>
                    <Form.Label>Permit Required:</Form.Label>
                    <Form.Select
                        value={permit_required}
                        onChange={(e) => set_permit_required(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'after_completion'}>
                    <Form.Label>After Completion:</Form.Label>
                    <Form.Select
                        value={after_completion}
                        onChange={(e) => set_after_completion(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'type_of_permit'}>
                    <Form.Label>Type of Permit:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Type of Permit"}
                        value={type_of_permit}
                        onChange={(e) => set_type_of_permit(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'who_completed_onsite'}>
                    <Form.Label>Who Completed Onsite:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Who Completed Onsite"}
                        value={who_completed_onsite}
                        onChange={(e) => set_who_completed_onsite(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'onsite_notes'}>
                    <Form.Label>Onsite Notes:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"OnSite Notes"}
                        value={onsite_notes}
                        onChange={(e) => set_onsite_notes(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'photo1'}>
                    <Form.Label>Photo 1:</Form.Label>
                    {
                        photo1 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo1}`} alt={'photo1'}/>) : ("No Photo 1")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewPhoto1(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo2'}>
                    <Form.Label>Photo 2:</Form.Label>
                    {
                        photo2 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo2}`} alt={'photo2'}/>) : ("No Photo 2")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewPhoto2(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo3'}>
                    <Form.Label>Photo 3:</Form.Label>
                    {
                        photo3 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo3}`} alt={'photo3'}/>) : ("No Photo 3")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewPhoto3(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo4'}>
                    <Form.Label>Photo 4:</Form.Label>
                    {
                        photo4 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo4}`} alt={'photo4'}/>) : ("No Photo 4")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewPhoto4(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo5'}>
                    <Form.Label>Photo 5:</Form.Label>
                    {
                        photo5 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo5}`} alt={'photo5'}/>) : ("No Photo 5")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewPhoto5(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo6'}>
                    <Form.Label>Photo 6:</Form.Label>
                    {
                        photo6 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo6}`} alt={'photo6'}/>) : ("No Photo 6")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewPhoto6(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo7'}>
                    <Form.Label>Photo 7:</Form.Label>
                    {
                        photo7 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo7}`} alt={'photo7'}/>) : ("No Photo 7")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewPhoto7(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo8'}>
                    <Form.Label>Photo 8:</Form.Label>
                    {
                        photo8 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo8}`} alt={'photo8'}/>) : ("No Photo 8")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewPhoto8(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo9'}>
                    <Form.Label>Photo 9:</Form.Label>
                    {
                        photo9 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo9}`} alt={'photo9'}/>) : ("No Photo 9")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewPhoto9(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo10'}>
                    <Form.Label>Photo 10:</Form.Label>
                    {
                        photo10 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo10}`} alt={'photo10'}/>) : ("No Photo 10")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewPhoto10(e.target.files[0])}
                    />
                </Form.Group>

                <br/>

                    <Button className={'btn btn-success'} onClick={UpdateOnSiteInfo}>Update On-Site</Button>

            </Form>


        </div>
    )
}

export default UpdateOnSite