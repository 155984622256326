import React, {useState, useEffect} from 'react';
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const WorkOrderDetails = () => {

    const [workOrder, setWorkOrder] = useState("")

    const { id } = useParams();
    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;


    const getSingleWorkOrder = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`workorders/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        // console.log(typeof data.prints)
        setWorkOrder(data)
    }

    useEffect(()=>{
        getSingleWorkOrder()
    }, [])


    return (
        <div className={"banner_heading"}>
            <h1>Work-Order Detail</h1>
            <div>
            {

                workOrder.new_client==='Yes'
                ?
                    (<h3 style={{background: 'red', color: "white"}} className={'homepage_card banner_heading'}>This is a new Client!</h3>)
                    :
                    ('')

            }
            </div>
            <div>

                <p>System Installed: {workOrder.system_installed}</p>
                <p>Job Completed: {workOrder.job_completed}</p>
                <p>Done: {workOrder.done}</p>
                <p>Cracked Ceiling: {workOrder.cracked_ceiling}</p>
                {
                    workOrder.prevailing_wage==="Yes"
                        ?
                        (<p style={{background : 'red', color: "white"}}>Prevailing wage: {workOrder.prevailing_wage}</p>)
                        :
                        (<p>Prevailing wage: {workOrder.prevailing_wage}</p>)
                }
                <p>Estimated job length: {workOrder.estimated_job_length}</p>
                <p>Scheduling reviewed: {workOrder.scheduling_reviewed}</p>
                <p>Are we hiring a roofing company: {workOrder.are_we_hiring_a_roofing_company}</p>
                <p>Roofing company contact: {workOrder.roofing_company_contact}</p>
                <p>Are we hiring an electrician: {workOrder.are_we_hiring_an_electrician}</p>
                <p>Number of fans needed: {workOrder.number_of_fans_needed}</p>
                <p>Type of fan: {workOrder.type_of_fan}</p>
                <p>Work to be done: {workOrder.work_to_be_done}</p>
                <p>Notes from site: {workOrder.notes_from_site}</p>

                {
                    workOrder.design_picture_1 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workOrder.design_picture_1}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workOrder.design_picture_1}`} alt={'design picture 1'}/></a>) : ("No Design Picture")
                }
                <br /> <br />
                {
                    workOrder.design_picture_2 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workOrder.design_picture_2}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workOrder.design_picture_2}`} alt={'design picture 2'}/></a>) : ("No Design Picture")
                }
                <br /> <br />
                {
                    workOrder.design_picture_3 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workOrder.design_picture_3}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workOrder.design_picture_3}`} alt={'design picture 3'}/></a>) : ("No Design Picture")
                }
                <br /> <br />
                {
                    workOrder.design_picture_4 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workOrder.design_picture_4}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workOrder.design_picture_4}`} alt={'design picture 4'}/></a>) : ("No Design Picture")
                }
                <br /> <br />
                {
                    workOrder.design_picture_5 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workOrder.design_picture_5}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workOrder.design_picture_5}`} alt={'design picture 5'}/></a>) : ("No Design Picture")
                }
                <br />
                <br />

                <Link className={'btn btn-primary'} to={`/workorder/update/${id}/`}>Update Work Order</Link>

            </div>
        </div>
    )
}

export default WorkOrderDetails