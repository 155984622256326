import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Button, Form} from "react-bootstrap";
import {useSelector} from "react-redux";

const AddJobPriority = () => {

    let navigate = useNavigate();

    const [client, setClient] = useState()
    const [address, setAddress] = useState()

    const [priority_date, set_priority_date] = useState('')
    const [priority, set_priority] = useState('DEFAULT')
    const [finished, set_finished] = useState('DEFAULT')

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin


    const { id } = useParams();

    const loadClient = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`clients/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        setClient(data.id)
        setAddress(data.work_site_address)
    }

    useEffect(() => {
        loadClient()
    }, []);

    const AddPriority = async () => {
        if (priority_date===''){
            alert("Add Priority Date")
        } else if (priority==='DEFAULT'){
            alert("Add Priority Setting")
        } else if (finished==='DEFAULT'){
            alert("Add if Finished")
        } else {
            let formField = new FormData()

            formField.append('client', client)
            formField.append('priority_date', priority_date)
            formField.append('priority', priority)
            formField.append('finished', finished)

            // console.log(formField)
            await axios ({
                method: 'post',
                url: process.env.REACT_APP_API+`clients/${client}/add_priority/`,
                headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${userInfo.token}`
                    },
                data: formField,
            }).then(() => {
                navigate('/active_work_orders')
            })
        }
    }

    return(
        <div>
            <h1>Create Priority for: {address}</h1>

            <Form>

                <Form.Group controlId={'priority_date'}>
                    <Form.Label>Priority Date:</Form.Label>
                    <Form.Control
                        type={'date'}
                        placeholder={"priority_date"}
                        value={priority_date}
                        onChange={(e) => set_priority_date(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'priority'}>
                    <Form.Label>Priority:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_priority(e.target.value)}
                    >
                        <option value={'DEFAULT'}>Set Priority</option>
                        <option value="Low">Low</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'finished'}>
                    <Form.Label>Finished:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_finished(e.target.value)}
                    >
                        <option value={'DEFAULT'}>Set Is Job Finished</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                <br/>

                <Button className={'btn btn-success'} onClick={AddPriority}>Add Priority</Button>

            </Form>

        </div>
    );

};


export default AddJobPriority

// import React, {useEffect, useState} from "react";
// import {useNavigate, useParams} from "react-router-dom";
// import axios from "axios";
// import {Button, Form,} from "react-bootstrap";
//
// const AddJobPriority = () => {
//
//     let navigate = useNavigate();
//
//     const [client, setClient] = useState()
//     const [address, setAddress] = useState()
//
//
//     const [priority_date, setPriorityDate] = useState()
//     const [priority, setPriority] = useState('DEFAULT')
//     const [finished, setFinished] = useState('No')
//
//
//     const { id } = useParams();
//
//     const loadClient = async () => {
//         const { data } = await axios.get(`http://127.0.0.1:8000/api/clients/${id}/`)
//         // console.log(data.id)
//         setClient(data.id)
//         setAddress(data.work_site_address)
//     }
//
//     useEffect(() => {
//         loadClient()
//     }, []);
//
//     const AddPriority = async () => {
//         if (priority_date === ''){
//             alert('Please fill out priority date')
//         } else if (priority === 'DEFAULT') {
//             alert('Please assign priority rating')
//         } else {
//             let formField = new FormData()
//
//             formField.append('client', client)
//             formField.append('priority_date', priority_date)
//             formField.append('priority', priority)
//             formField.append('finished', finished)
//
//             await axios ({
//                 method: 'post',
//                 url: `http://127.0.0.1:8000/api/clients/${client}/add_priority//`,
//                 data: formField,
//             }).then(() => {
//                 // console.log(response.data)
//                 navigate('/active_work_orders')
//             })
//         }
//     }
//
//     return(
//         <div>
//             <h1>Set Priority For: {address}</h1>
//
//             <Form>
//                 <Form.Group controlId={'priority_date'}>
//                     <Form.Label>Set Priority Date:</Form.Label>
//                     <Form.Control
//                         type={'date'}
//                         value={priority_date}
//                         onChange={(e) => setPriorityDate(e.target.value)}
//                     />
//                 </Form.Group>
//
//                 <Form.Group controlId={'priority'}>
//                     <Form.Label>Priority:</Form.Label>
//                     <Form.Select
//                         defaultValue={'DEFAULT'}
//                         onChange={(e) => setPriority(e.target.value)}
//                     >
//                         <option value={'DEFAULT'}>Set Priority</option>
//                         <option value="Low">Low</option>
//                         <option value="Medium">Medium</option>
//                         <option value="High">High</option>
//                     </Form.Select>
//                 </Form.Group>
//
//                 <Form.Group controlId={'finished'}>
//                     <Form.Label>Finished:</Form.Label>
//                     <Form.Select
//                         defaultValue={'DEFAULT'}
//                         onChange={(e) => setFinished(e.target.value)}
//                     >
//                         <option value={'DEFAULT'} disabled>Job Finished?</option>
//                         <option value="No">No</option>
//                         <option value="Yes">Yes</option>
//                     </Form.Select>
//                 </Form.Group>
//
//
//                 <br/>
//                 {/*<Link to={'/active_work_orders'}>*/}
//                 <Button className={'btn btn-success'} onClick={AddPriority}>Add Priority</Button>
//                 {/*</Link>*/}
//             </Form>
//
//         </div>
//     );
//
// };
//
// export default AddJobPriority