import React from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import axios from "axios";
import {Col, Nav, Row} from "react-bootstrap";
import CustomProposalNotes from "../components/CustomProposalNotes";
import CustomReportsVideo from "../components/CustomReportsVideo";


// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

// see documentation for supported input formats
// const data = [['attribute', 'attribute2'], ['value1', 'value2']];

class CustomProposals extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            AP: [],
        };
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API+'proposals_custom_reports/',{
            headers:{
                "Accept":'application/json',
                "Content-Type":'application/json'
            },
        })
            .then(response=>{
                this.setState({AP: response.data});
            })
    }

    render() {

        const {AP} = this.state;

        return (
            <div className={"banner_heading"}>
                <h1>Custom Proposal Reports:</h1>
                <Nav className={'reports_nav_heading'} variant="tabs" defaultActiveKey="/finance_reports">
                    <Nav.Item>
                        <Nav.Link href={'/signed_proposals'}>
                            Signed Proposals
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link href="/proposals_not_signed">
                            Not Signed Proposals and unapproved jobs
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link  href="/approved_jobs">
                            Approved Jobs
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link eventKey="disabled" disabled>
                            Custom Proposal Reports
                        </Nav.Link>
                    </Nav.Item>
                </Nav>

                <div className={'reports_nav_heading'}>
                    <Row>
                        <Col><CustomProposalNotes /></Col>
                        <Col><CustomReportsVideo /></Col>
                    </Row>
                </div>


            <PivotTableUI
                data={AP}
                onChange={s => this.setState(s)}
                renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                {...this.state}
            />
            </div>
        );
    }
}

export default CustomProposals
