import React from "react";
import {Row, Col, Card, Button,} from "react-bootstrap";
import { useNavigate } from 'react-router-dom'

function ServiceOrders() {

    let navigate = useNavigate();

    return (
        <div>
            <h1 className={"banner_heading"}>Manage Service Orders</h1>
            <br />
            <Row >
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Service Order Customers</Card.Header>
                        <Card.Body>
                            <Card.Title>Manage Active Service Orders</Card.Title>
                            <Card.Text>
                                Allows Creating and managing of open service orders
                            </Card.Text>
                            <Button variant="primary"
                            onClick={ () => navigate('/open_service_orders')}
                            >Service Orders</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Closed Service Orders</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to the Closed Service Orders</Card.Title>
                            <Card.Text>
                                Access to Closed Service orders and the ability to reactive them.
                            </Card.Text>
                            <Button variant="primary"
                            onClick={ () => navigate('/closed_service_orders')}
                            >Closed Service Orders</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ServiceOrders