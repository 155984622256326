import React from "react";
import {Row, Col, Card, Button,} from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import LogisticsVideo from "../components/LogisticsVideo";

function LogisticsDash() {

    let navigate = useNavigate();

    return (
        <div>
            <h1 className={"banner_heading"}>Logistics Dash</h1>
            <br />
            <Row>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Unscheduled & Not Reviewed Work Orders</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Access to work orders that have been created but not scheduled.
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ () => navigate('/unscheduled_work_orders')}
                            >Unscheduled Work Orders</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Reviewed Active Work Orders</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Access to Active Work orders for assignment.
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ ()=> navigate('/logistics_active-work_orders')}
                            >Active Work Orders</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Active Service Orders.</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Access to Active Service Orders not marked as done.
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ () => navigate('/logistics_active_service_orders') }
                            >Active Service Orders.</Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Calender for Scheduled Jobs</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Access to Scheduled Job Calender showing which jobs are on what dates
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ () => navigate('/calender_scheduled_jobs') }
                            >Calender: Scheduled Jobs</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br /> <br />

            <div style={{
                margin: 'auto',
                width: '30%',
                padding: '10px',
            }}>
                <LogisticsVideo />
            </div>
        </div>
    )
}

export default LogisticsDash