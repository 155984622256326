import React, {useState, useEffect} from 'react';
import { Navbar, Nav, Container } from "react-bootstrap";
import axios from "axios";

const OpsFormButton = () => {


    // const [hasPreSite, setHasPreSite] = useState([])
    // const [hasOnSite, setHasOnSite] = useState([])
    // const [hasProposal, setHasProposal] = useState([])
    // const [hasInvoice, setHasInvoice] = useState([])
    // const [hasWorkOrder, setHasWorkOrder] = useState([])
    // const [hasCheckList, setHasCheckList] = useState([])

    const fetchData = () =>{
        const clientsAPI = 'http://127.0.0.1:8000/api/clients/';
        const preSiteAPI = 'http://127.0.0.1:8000/api/presites/';

        const getClients = axios.get(clientsAPI);
        const getPreSites = axios.get(preSiteAPI);
        axios.all([getClients,
            getPreSites
        ]).then(
            axios.spread((...allData) => {
                const allDataClient = allData[0]
                const allDataPresite = allData[1]

                console.log(allDataClient)
                console.log(allDataPresite)
            })
        )
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className={"banner_heading"}>



        </div>
    )
}

export default OpsFormButton