import React from "react";
import {Row, Col, Card, Button,} from "react-bootstrap";
import { useNavigate } from 'react-router-dom'

function SafetyDash() {

    let navigate = useNavigate();

    return (
        <div>
            <h1 className={"banner_heading"}>Safety Dash</h1>
            <br />
            <Row>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Safety Data Sheets</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to our Safety Data Sheet Database</Card.Title>
                            <Card.Text>
                                In order to ensure chemical safety in the workplace, information about the identities and hazards of the chemicals are available here for our workers.
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ () => navigate('/sds')}
                            >Safety Data Sheets</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Employee Training</Card.Header>
                        <Card.Body>
                            <Card.Title>Employee Training Documentation</Card.Title>
                            <Card.Text>
                                A repository for managing training employees and employee training documentation retention.
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ () => navigate('/employee_training')}
                            >Employee Training Documentation</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default SafetyDash