import React, {Component, useEffect, useState} from "react";
import {Button, ButtonToolbar, Card, Col, Container, Modal, Row, Table} from "react-bootstrap";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Moment from 'moment'
import {useSelector} from "react-redux";

// TODO: the refresh needs a timer on it. Otherwise it just runs all the time


const AllHoursWorked = () => {
    Moment.locale('en');
    const [info, setInfo] = useState()
    const [projectID, setProjectId] = useState()
    const [address, setAddress] = useState([])

    const {id} = useParams();
    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const loadClient = async () => {
        const {data} = await axios.get(process.env.REACT_APP_API+`clienthours/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        setInfo(data)
    }

    const getSingleClient = async () => {
        const {data} = await axios.get(process.env.REACT_APP_API+`clients/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        setProjectId(data.project_ID)
        setAddress(data.work_site_address)
    }

    useEffect(() => {
        loadClient()
        getSingleClient()
    }, []);


    let redirect = ''
    if (userInfo){
        if (userInfo.group === "AdminSuper"){
            redirect = '/active_work_orders'
        } else if (userInfo.group === "Tech") {
            redirect = '/tech_work_orders'
        }
    }


    return (
        <div>
            <Link style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop:'5px'
            }} className={'btn btn-primary'} to={redirect}>Active Work Orders Dashboard</Link>

            <br /><br />
            <h1>Hours for: {address}</h1>
            <h2>Project ID: {projectID}</h2>


            {info?.map(item =>
                <Row className={'HourPerRow'} key={item.id}>
                    {/*<h5>Created On: {item.created}</h5>*/}

                    <span className={'HoursSpan'}>
                    <h5>{Moment(item.created).format("[Created On:] MM/DD/YYYY  hh:mm:ss a")}</h5>
                    {
                        item.finished!=='N/A'
                            ?
                            (
                                <h5>
                                    {Moment(item.finished).format("[Finished On:] MM/DD/YYYY  hh:mm:ss a")}
                                </h5>
                            )
                            :
                            ("Job Not yet completed")
                    }

                    </span>

                    <Col>
                        <Link className={'btn btn-info'} to={`/workhour/${item.id}/`}>
                            Detail Hours
                        </Link>
                    </Col>
                    <Col>
                        <Link className={'btn btn-warning'} to={`/workhour/update/${item.id}/`}>
                            Update Hours
                        </Link>
                    </Col>
                </Row>
            )}
        </div>
    );

}
export default AllHoursWorked;
