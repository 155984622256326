import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Form, Button, Row, Col} from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {useDispatch, useSelector} from "react-redux";
import {login, getUserDetails} from "../actions/userActions";
import FormContainer from "../components/FormContainer";


function LoginScreen({location, history}) {

    const dispatch = useDispatch()
    let navigate = useNavigate();


    const [name, setName] = useState('')
    const [password, setPassword] = useState('')


    const userLogin = useSelector(state => state.userLogin)
    const {error, loading, userInfo} = userLogin


    useEffect(() => {
        if (userInfo) {
            if (userInfo.group==='AdminSuper'){
                navigate('/admin_dash')
            } else if (userInfo.group==='Finance') {
                navigate('/finance_dash')
            } else if (userInfo.group==='Logistics'){
                navigate('/logistics_dash')
            } else if (userInfo.group==='Tech'){
                navigate('/tech_dash')
            }
        }
    }, [history, userInfo, navigate])


    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(login(name, password))
    }

    return (
        <FormContainer>
            <h1>Sign In</h1>
            {error && <Message variant={'danger'}>{error}</Message>}
            {loading && <Loader/>}
            <Form onSubmit={submitHandler}>
                <Form.Group controlId={'name'}>
                    <Form.Label>Name:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Name"}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Group>
                <br/>
                <Form.Group controlId={'password'}>
                    <Form.Label>Password:</Form.Label>
                    <Form.Control
                        type={'password'}
                        placeholder={"Password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>
                <br/>
                <Button type={'submit'} variant={'primary'}>Sign in</Button>
            </Form>

        </FormContainer>
    )
}

export default LoginScreen

