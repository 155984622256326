import React from "react";
import {Row, Col, Card, Button,} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

function WorkOrders() {

    let navigate = useNavigate()

    return (
        <div>
            <h1 className={"banner_heading"}>Manage Work Orders</h1>
            <br />
            <Row >
                {/*<Col sm={12} md={6} lg={4} xl={4}>*/}
                {/*    <Card>*/}
                {/*        <Card.Header>Create Client</Card.Header>*/}
                {/*        <Card.Body>*/}
                {/*            <Card.Title>Creates new client</Card.Title>*/}
                {/*            <Card.Text>*/}
                {/*                Allows User to Create a Client*/}
                {/*            </Card.Text>*/}
                {/*            <Button variant="primary"*/}
                {/*            onClick={ () => navigate('/create_client') }*/}
                {/*            >Create Client</Button>*/}
                {/*        </Card.Body>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Active Work Orders</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to Create Clients & Active Work Orders</Card.Title>
                            <Card.Text>
                                Access to Active Work Orders
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ () => navigate('/active_work_orders') }
                            >Active Work Orders</Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Finished Work Orders</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to Finished Work Orders</Card.Title>
                            <Card.Text>
                                Access to Work Orders that have been marked as finished
                            </Card.Text>
                            <Button variant="primary"
                            onClick={ () => navigate('/finished_work_orders')}
                            >Finished Work Orders</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </div>
    )
}

export default WorkOrders