import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, {useState} from "react";


function CustomReportsVideo() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Video On how to make custom reports.
            </Button>
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>How to make custom reports using the pivot table.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {/*<Player*/}
                        {/*    playsInline*/}
                        {/*    poster={"videos/reportspicture.jpeg"}*/}
                        {/*    src={'videos/CustomReportsVideo.mp4'}*/}
                        {/*    type="video/mp4"*/}
                        {/*    width={10}*/}
                        {/*    height={10}*/}
                        {/*    fluid={true}*/}
                        {/*/>*/}

                    <video controls src={'videos/CustomReportsVideo.mp4'} width={1100}/>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default CustomReportsVideo;

