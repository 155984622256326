import React from "react";

function SDS() {
    return (
        <div className={"banner_heading"}>
            <h1>Create SDS HERE</h1>
            <h2>Listed SDS's Here</h2>
        </div>
    )
}

export default SDS