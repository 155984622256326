import React, {useEffect, useState} from 'react';
import {Navbar, Nav, Container, NavDropdown} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../actions/userActions";
import {useNavigate} from "react-router-dom";

function Header() {

    const dispatch = useDispatch()
    let navigate = useNavigate();

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const logoutHandler = () => {
        dispatch(logout())
        navigate('')
    }

    let dashboard = ''
    if (userInfo){
        if (userInfo.group === "AdminSuper"){
            dashboard = '/admin_dash'
        } else if (userInfo.group === "Finance") {
            dashboard = '/finance_dash'
        } else if (userInfo.group === "Logistics") {
            dashboard = '/logistics_dash'
        } else if (userInfo.group === "Tech") {
            dashboard = '/tech_dash'
        }
    }

    return (
        <header>
            <Navbar bg="dark" variant={'dark'} expand="lg" collapseOnSelect>
                <Container fluid>
                    <Navbar.Brand href="/">Home Safety Solutions</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll"/>
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="ml-auto my-2 my-lg-0"
                            style={{maxHeight: '100px'}}
                            navbarScroll
                        >

                            {userInfo ? (
                                <NavDropdown title={userInfo.name} id={'username'}>

                                    <NavDropdown.Item href={dashboard}>{userInfo.group} Dashboard</NavDropdown.Item>


                                    <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>

                                </NavDropdown>
                            ) : (
                                <Nav.Link href="/login"><i className={'fas fa-user'}></i>Log In</Nav.Link>
                            )}


                            {/*<Nav.Link href="#action3">[SOON TO BE A SEARCH BAR]</Nav.Link>*/}
                            {/*<Nav.Link href="#action4">Search</Nav.Link>*/}

                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

export default Header