import React, {Component} from "react";
import {Table} from "react-bootstrap";
import axios from "axios";
import {ClockLoader} from "react-spinners";
import Moment from "moment/moment";

//TODO: the refresh needs a timer on it. Otherwise it just runs all the time

export class LogisticsReport extends Component{

    constructor() {
        super();
        this.state = {
            filterOption: '',
            filteredData: [],
            newData: [],
            invoiceIsLoading: true,
        };
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API+'logistics_report/',{
            headers:{
                "Accept":'application/json',
                "Content-Type":'application/json'
            },
        })
            .then(response=>{
                this.setState({newData: response.data});
                this.setState({invoiceIsLoading: false});
            })
    }

    optionSelected(e) {
        this.setState({
            filterOption: e.target.value,
        });
    }

    renderTableData(newData) {
        return newData.map((element, i) => (
            <tr key={i}>
                <td>{Moment(element.created).format("MM/DD/YYYY  hh:mm:ss a")}</td>
                <td>{element.client}</td>
                <td>{element.why}</td>
                <td>{element.reason}</td>
            </tr>
        ));
    };

    renderFilterOptions(filterOption) {
        if(filterOption) {
            let { newData } = this.state;
            let options = Array.from(new Set(newData.map(element => element[filterOption])));
            return options.map((option,i) => <option key={i} value={option}> {option} </option>)
        }
        return []
    };

    filterOptionSelected(e) {
        let {value} = e.target;
        if(value) {
            let { filterOption, newData } = this.state
            let filteredData = newData.filter(element => element[filterOption] === value);

            const getSum = filteredData.reduce((obj, {bill_amount}) => {
                obj[bill_amount] = bill_amount;
                return obj;
            }, {});

            let sum = 0;
            for (const value of Object.values(getSum)) {
                sum += parseInt(value);
            }

            this.setState({
                filteredData: filteredData,
                getSum: getSum,
                sum: sum.toFixed(2),
            });
        }
    };


    render() {
        let { filterOption, filteredData, newData, invoiceIsLoading} = this.state

        if (invoiceIsLoading){
            return <div className={'loadingSpinner'} >Loading...<br/> <ClockLoader color="#0d00ff" /></div>
        }

        return (
            <div>
                <h1 className={'perposalInvoiceHeaders'}>Logistics Report</h1>
                <div>
                    <label>Filter By:</label>
                    <br/>
                    <select onChange={this.optionSelected.bind(this)}>
                        <option value="">  </option>
                        <option value="client">Client</option>
                        <option value="why">why</option>
                        <option value="year">year</option>
                        <option value="year_and_month">year and month</option>
                    </select>

                    <select onChange={this.filterOptionSelected.bind(this)}>
                        <option value="">  </option>
                        {this.renderFilterOptions(filterOption)}
                    </select>
                </div>
                <Table responsive="lg" className={'mt-4'} striped bordered hover size={'lg'}>
                    <thead>
                    <th>Created:</th>
                    <th>Client:</th>
                    <th>Issue:</th>
                    <th>Description:</th>
                    </thead>
                    <tbody>
                    {
                        filteredData.length ? this.renderTableData(filteredData) : this.renderTableData(newData)
                    }
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default LogisticsReport;

