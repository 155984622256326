import React, {useState, useEffect} from 'react';
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const PresiteDetail = () => {

    const [presite, setPresite] = useState("")

    const { id } = useParams();
    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;

    const getSinglePresite = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`presite/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        setPresite(data)

        // console.log(typeof data.prints)
    }

    useEffect(()=>{
        getSinglePresite()
    }, [])


    return (
        <div className={"banner_heading"}>
            <h1>Pre-Site Detail</h1>
            <div>

                <p>Lock Box or Entry Code: {presite.lock_box_or_entry_code}</p>
                <p>Safety Equipment Needed:{presite.safety_equipment_needed}</p>
                <p>General Notes: {presite.general_notes}</p>
                <p>Is Property Vacant: {presite.is_property_vacant}</p>
                <p>Site Hours: {presite.site_hours}</p>
                <p>Onsite Contact: {presite.onsite_contact}</p>
                <p>Onsite Contact phone:  {presite.onsite_contact_phone}</p>
                <p>Onsite: Contact Email: {presite.onsite_contact_email}</p>


                {
                    presite.prints ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${presite.prints}`}><img className={'processImages'} src={`http://127.0.0.1:8000${presite.prints}`} alt={'prints'}/></a>) : ("No Prints")
                }
                <br /> <br/>
                {
                    presite.prints2 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${presite.prints2}`}><img className={'processImages'} src={`http://127.0.0.1:8000${presite.prints2}`} alt={'prints 2'}/></a>) : ("No Prints 2")
                }
                <br/> <br/>
                {
                    presite.prints3 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${presite.prints3}`}><img className={'processImages'} src={`http://127.0.0.1:8000${presite.prints3}`} alt={'prints 3'}/></a>) : ("No Prints 3")
                }
                <br/> <br/>
                {
                    presite.prints4 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${presite.prints4}`}><img className={'processImages'} src={`http://127.0.0.1:8000${presite.prints4}`} alt={"prints 4"}/></a>) : ("No Prints 4")
                }
                <br/> <br/>
                {
                    presite.prints5 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${presite.prints5}`}><img className={'processImages'} src={`http://127.0.0.1:8000${presite.prints5}`} alt={'prints 5'}/></a>) : ("No Prints 5")
                }
                <br />
                <br />
                <Link className={'btn btn-primary'} to={`/presite/update/${id}/`}>Update PreSite</Link>

            </div>
        </div>
    )
}

export default PresiteDetail