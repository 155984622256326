import React, {Component, useEffect, useState} from "react";
import {Button, ButtonToolbar, Card, Col, Container, Modal, Nav, Row, Table} from "react-bootstrap";
import axios from "axios";
import {Link} from "react-router-dom";
import CreateClient from "./CreateClient";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {ClockLoader} from "react-spinners";
import { connect } from 'react-redux';

//TODO: the refresh needs a timer on it. Otherwise it just runs all the time


export class ActiveWorkOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Active Work Orders clients
            AWOS: [],
            clientIsLoading: true,

            //Active PreSites
            APS: [],
            preSiteIsLoading: true,

            // Active On Sites
            AOS: [],
            onSiteIsLoading: true,

            // Active Proposals
            AP: [],
            proposalIsLoading: true,

            // Active Invoices
            AI: [],
            invoiceIsLoading: true,

            // Active WorkOrders
            ACWO: [],
            workOrderIsLoading: true,

            // Active CheckLists
            ACL: [],
            checkListIsLoading: true,

            // WorkHours
            WO: [],
            workHourIsLoading: true,

            // Priorities
            priority: [],
            priorityIsLoading: true,

            // Comments
            comments: [],
            commentsAreLoading: true,
        }

    }

    componentDidMount() {

        axios.get(process.env.REACT_APP_API + 'clients/', {
            // withCredentials: true,
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({AWOS: response.data});
                this.setState({clientIsLoading: false});
            })

        axios.get(process.env.REACT_APP_API + 'presites/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({APS: response.data});
                this.setState({preSiteIsLoading: false});
            })

        axios.get(process.env.REACT_APP_API + 'onsites/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({AOS: response.data});
                this.setState({onSiteIsLoading: false});
            })

        axios.get(process.env.REACT_APP_API + 'proposals/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({AP: response.data});
                this.setState({proposalIsLoading: false});
            })

        axios.get(process.env.REACT_APP_API + 'invoices/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({AI: response.data});
                this.setState({invoiceIsLoading: false});
            })

        axios.get(process.env.REACT_APP_API + 'workorders/',
            {
                headers: {
                    "Accept": 'application/json',
                    "Content-Type": 'application/json'
                },
            })
            .then(response => {
                this.setState({ACWO: response.data});
                this.setState({workOrderIsLoading: false});
            })

        axios.get(process.env.REACT_APP_API + 'checklists/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({ACL: response.data});
                this.setState({checkListIsLoading: false});
            })

        axios.get(process.env.REACT_APP_API + 'workhours/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({WO: response.data});
                this.setState({workHourIsLoading: false});
            })

        axios.get(process.env.REACT_APP_API + 'priority_reports/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({priority: response.data});
                this.setState({priorityIsLoading: false});
            })

        axios.get(process.env.REACT_APP_API + 'comments/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({comments: response.data});
                this.setState({commentsAreLoading: false});
            })
    }

    onDragEnd = e => {
        console.log(e);
        if (!e.destination) {
            return;
        }
        const {AWOS} = this.state;
        const sorted = this.reorder(AWOS, e.source.index, e.destination.index);
        console.log(sorted);
        this.setState({
            AWOS: sorted
        });
    };

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    render() {

        const {
            AWOS,
            APS,
            AOS,
            AP,
            AI,
            ACWO,
            ACL,
            WO,
            comments,
            clientIsLoading,
            preSiteIsLoading,
            onSiteIsLoading,
            priority,
            proposalIsLoading,
            invoiceIsLoading,
            workOrderIsLoading,
            checkListIsLoading,
            workHourIsLoading,
            priorityIsLoading,
            commentsAreLoading
        } = this.state;

        if (clientIsLoading || preSiteIsLoading || onSiteIsLoading || proposalIsLoading || invoiceIsLoading ||
            workOrderIsLoading || checkListIsLoading || workHourIsLoading || priorityIsLoading || commentsAreLoading) {
            return <div className={'loadingSpinner'}>Loading...<br/> <ClockLoader color={'#0d00ff'}/></div>
        }

        let activePreSites = APS.map((client) => client.client)
        let activeOnSites = AOS.map((client) => client.client)
        let activeProposals = AP.map((client) => client.client)
        let activeInvoices = AI.map((client) => client.client)
        let activeWorkOrders = ACWO.map((client) => client.client)
        let activeCheckLists = ACL.map((client) => client.client)
        let activeWorkHours = WO.map((client) => client.client)
        let priorities = priority.map((client) => client.client)
        let techcomments = comments.map((client) => client.client)

        return (

            <Container fluid>

                <Nav variant="tabs" defaultActiveKey="/active_work_orders" className={'reports_nav_heading'}>
                    <Nav.Item>
                        <Nav.Link href="/active_work_orders" eventKey="disabled" disabled>Active Work Orders</Nav.Link>
                    </Nav.Item>
                    <Nav.Link href={'/priority_reports'}>
                        Active Priority Tasks
                    </Nav.Link>
                    <Nav.Link href={'/done_priority_reports'}>
                        InActive Priority Tasks
                    </Nav.Link>
                </Nav>
                <br/>

                <Row>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Creates new client</Card.Title>
                                <Card.Text>
                                    Allows User to Create a Client
                                </Card.Text>
                                {/*<Link className={'btn btn-danger'} to={`/create_client`}>*/}
                                {/*Create Client*/}
                                {/*</Link>*/}
                                <CreateClient/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId={AWOS}>
                        {(provided) => (
                            <div
                                className={AWOS}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {AWOS.map((awo, index) =>
                                    <Draggable
                                        key={awo.work_site_address}
                                        draggableId={awo.work_site_address}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <Row
                                                className={"workOrderRow"}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                            >
                                                <Col>
                                                    <Link className={'btn btn-primary'}
                                                          to={`/client_details/${awo.id}/`}>
                                                        {awo.work_site_address}
                                                    </Link>
                                                </Col>

                                                <Col>

                                                    {
                                                        activePreSites.includes(awo.id)
                                                            ?
                                                            (<Link className={'btn btn-info'}
                                                                   to={`/presite/${awo.id}/`}>
                                                                Detail Pre-Site
                                                            </Link>)
                                                            :
                                                            (<Link className={'btn btn-warning'}
                                                                   to={`/clients/${awo.id}/addpresite`}>
                                                                Add Pre-Site
                                                            </Link>)
                                                    }
                                                </Col>

                                                <Col>
                                                    {
                                                        activePreSites.includes(awo.id)
                                                            ?
                                                            ('So True')
                                                            &&
                                                            activeOnSites.includes(awo.id)
                                                                ?
                                                                (<Link className={'btn btn-info'}
                                                                       to={`/onsite/${awo.id}/`}>Detail
                                                                    OnSite</Link>)
                                                                :
                                                                (<Link className={'btn btn-warning'}
                                                                       to={`/clients/${awo.id}/addonsite/`}>Add
                                                                    OnSite</Link>)
                                                            :
                                                            ("Fill out Pre-Site")
                                                    }

                                                </Col>

                                                <Col>

                                                    {
                                                        activeOnSites.includes(awo.id)
                                                            ?
                                                            ('So True')
                                                            &&
                                                            activeProposals.includes(awo.id)
                                                                ?
                                                                (<Link className={'btn btn-info'}
                                                                       to={`/proposals/${awo.id}/`}>
                                                                    Detail Proposal
                                                                </Link>)
                                                                :
                                                                (<Link className={'btn btn-warning'}
                                                                       to={`/clients/${awo.id}/addproposal/`}> Add
                                                                    Proposal </Link>)
                                                            :
                                                            ("Fill out On-Site")
                                                    }
                                                </Col>

                                                <Col>
                                                    {
                                                        activeProposals.includes(awo.id)
                                                            ?
                                                            ('So True')
                                                            &&
                                                            activeInvoices.includes(awo.id)
                                                                ?
                                                                (<Link className={'btn btn-info'}
                                                                       to={`/invoices/${awo.id}/`}> Detail
                                                                    Invoice </Link>)
                                                                :
                                                                (<Link className={'btn btn-warning'}
                                                                       to={`/clients/${awo.id}/addinvoice/`}>Add
                                                                    Invoice </Link>)
                                                            :
                                                            ("Fill out Proposal")
                                                    }
                                                </Col>

                                                <Col>
                                                    {
                                                        activeInvoices.includes(awo.id)
                                                            ?
                                                            ('So True')
                                                            &&
                                                            activeWorkOrders.includes(awo.id)
                                                                ?
                                                                (<Link className={'btn btn-info'}
                                                                       to={`/workorders/${awo.id}/`}> Detail
                                                                    Work-Order </Link>)
                                                                :
                                                                (<Link className={'btn btn-warning'}
                                                                       to={`/clients/${awo.id}/addworkorder/`}>Add
                                                                    Work-Order</Link>)
                                                            :
                                                            ("Fill out Invoice")
                                                    }

                                                </Col>

                                                <Col>
                                                    {
                                                        activeWorkOrders.includes(awo.id)
                                                            ?
                                                            ('So True')
                                                            &&
                                                            activeCheckLists.includes(awo.id)
                                                                ?
                                                                (<Link className={'btn btn-info'}
                                                                       to={`/checklist/${awo.id}/`}>
                                                                    Detail Check List
                                                                </Link>)
                                                                :
                                                                (<Link className={'btn btn-warning'}
                                                                       to={`/clients/${awo.id}/addchecklist/`}>Add
                                                                    Check List </Link>)
                                                            :
                                                            ("Fill out Work Order")
                                                    }
                                                </Col>
                                                <div className={'Row AddHourRow'}>

                                    <span className={"Col AddHourCol"}>
                                        {
                                            activeWorkOrders.includes(awo.id)
                                                ?
                                                ('So True')
                                                &&
                                                activeWorkOrders.includes(awo.id)
                                                    ?
                                                    <Link className={'btn btn-warning'}
                                                          to={`/clients/${awo.id}/addhours/`}>Add Hours</Link>
                                                    :
                                                    ("Create Work Order For Job")
                                                :
                                                ("Create Work Order For Job")
                                        }

                                    </span>
                                                    <span className={"Col AddHourCol"}>
                                        {
                                            activeWorkOrders.includes(awo.id)
                                                ?
                                                ('So True')
                                                &&
                                                activeWorkHours.includes(awo.id)
                                                    ?
                                                    (<Link className={'btn btn-info'} to={`/clienthours/${awo.id}/`}>
                                                        Hours Worked
                                                    </Link>)

                                                    :
                                                    ("Create Hours For Job")
                                                :
                                                ("Create Hours For Job")
                                        }
                                    </span>

                                                    <span className={"Col AddHourCol"}>
                                        {
                                            activeWorkOrders.includes(awo.id)
                                                ?
                                                ('So True')
                                                &&
                                                activeWorkOrders.includes(awo.id) && priorities.includes(awo.id)
                                                    ?
                                                    <Link className={'btn btn-success'}
                                                          to={`/priority_reports/${awo.id}/`}>Detail Priority</Link>
                                                    :
                                                    <Link className={'btn btn-danger'}
                                                          to={`/clients/${awo.id}/addpriority/`}>Set Priority</Link>
                                                :
                                                ("Create Work Order For Job")
                                        }

                                    </span>

                                                    <span className={"Col AddHourCol"}>
                                        {
                                            activeWorkOrders.includes(awo.id)
                                                ?
                                                ('So True')
                                                &&
                                                activeWorkOrders.includes(awo.id) && techcomments.includes(awo.id)
                                                    ?
                                                    <Link className={'btn btn-success'}
                                                          to={`/comment/${awo.id}/`}>Review Comment</Link>
                                                    :
                                                    <Link className={'btn btn-danger'}
                                                          to={`/clients/${awo.id}/add_comment/`}>Add Comment</Link>
                                                :
                                                ("Create Work Order For Job")
                                        }

                                    </span>

                                                </div>

                                            </Row>
                                        )}
                                    </Draggable>
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Container>
        );
    }
}

export default ActiveWorkOrders;


//
// export class ActiveWorkOrders extends Component {
//
//     constructor(props) {
//         super(props);
//         this.state = {
//             // Active Work Orders clients
//             AWOS: [],
//             //Active PreSites
//             APS: [],
//             // Active On Sites
//             AOS: [],
//             // Active Proposals
//             AP: [],
//             // Active Invoices
//             AI: [],
//             // Active WorkOrders
//             ACWO: [],
//             // Active CheckLists
//             ACL: [],
//             // WorkHours
//             WO: [],
//
//             // Modal Test
//
//         }
//     }
//
//
//     componentDidMount() {
//         axios.get(['http://127.0.0.1:8000/api/clients/',
//         ],{
//             headers:{
//                 "Accept":'application/json',
//                 "Content-Type":'application/json'
//             },
//         })
//             .then(response=>{
//                 this.setState({
//                     AWOS: response.data
//                 });
//             })
//
//         axios.get(['http://127.0.0.1:8000/api/presites/',
//         ],{
//             headers:{
//                 "Accept":'application/json',
//                 "Content-Type":'application/json'
//             },
//         })
//             .then(response=>{
//                 this.setState({
//                     APS: response.data
//                 });
//             })
//
//         axios.get(['http://127.0.0.1:8000/api/onsites/',
//         ],{
//             headers:{
//                 "Accept":'application/json',
//                 "Content-Type":'application/json'
//             },
//         })
//             .then(response=>{
//                 this.setState({
//                     AOS: response.data
//                 });
//             })
//
//         axios.get(['http://127.0.0.1:8000/api/proposals/',
//         ],{
//             headers:{
//                 "Accept":'application/json',
//                 "Content-Type":'application/json'
//             },
//         })
//             .then(response=>{
//                 this.setState({
//                     AP: response.data
//                 });
//             })
//
//         axios.get(['http://127.0.0.1:8000/api/invoices/',
//         ],{
//             headers:{
//                 "Accept":'application/json',
//                 "Content-Type":'application/json'
//             },
//         })
//             .then(response=>{
//                 this.setState({
//                     AI: response.data
//                 });
//             })
//
//         axios.get(['http://127.0.0.1:8000/api/workorders/',
//         ],{
//             headers:{
//                 "Accept":'application/json',
//                 "Content-Type":'application/json'
//             },
//         })
//             .then(response=>{
//                 this.setState({
//                     ACWO: response.data
//                 });
//             })
//
//         axios.get(['http://127.0.0.1:8000/api/checklists/',
//         ],{
//             headers:{
//                 "Accept":'application/json',
//                 "Content-Type":'application/json'
//             },
//         })
//             .then(response=>{
//                 this.setState({
//                     ACL: response.data
//                 });
//             })
//
//         axios.get(['http://127.0.0.1:8000/api/workhours/',
//         ],{
//             headers:{
//                 "Accept":'application/json',
//                 "Content-Type":'application/json'
//             },
//         })
//             .then(response=>{
//                 this.setState({
//                     WO: response.data
//                 });
//             })
//
//     }
//     render() {
//
//         const {AWOS, APS, AOS, AP, AI, ACWO, ACL, WO} = this.state;
//
//         let activePreSites = APS.map((client) => client.client)
//         let activeOnSites = AOS.map((client) => client.client)
//         let activeProposals = AP.map((client) => client.client)
//         let activeInvoices = AI.map((client) => client.client)
//         let activeWorkOrders = ACWO.map((client) => client.client)
//         let activeCheckLists = ACL.map((client) => client.client)
//         let activeWorkHours = WO.map((client) => client.client)
//
//         return (
//
//             <Container fluid>
//                 <Row>
//                     <Col sm={12} md={6} lg={4} xl={4}>
//                         <Card>
//                             <Card.Body>
//                                 <Card.Title>Creates new client</Card.Title>
//                                 <Card.Text>
//                                     Allows User to Create a Client
//                                 </Card.Text>
//                                 {/*<Link className={'btn btn-danger'} to={`/create_client`}>*/}
//                                 {/*Create Client*/}
//                                 {/*</Link>*/}
//                                 <CreateClient/>
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                 </Row>
//
//                 <br/>
//
//                 {AWOS.map(awo =>
//                     <Row key={awo.work_site_address} className={"workOrderRow"}>
//
//                         <Col>
//                             <Link className={'btn btn-primary'} to={`/client_details/${awo.id}/`}>
//                                 {awo.work_site_address}
//                             </Link>
//                         </Col>
//
//                         <Col>
//
//                             {
//                                 activePreSites.includes(awo.id)
//                                     ?
//                                     (<Link className={'btn btn-info'} to={`/presite/${awo.id}/`}>
//                                         Detail Pre-Site
//                                     </Link>)
//                                     :
//                                     (<Link className={'btn btn-warning'} to={`/clients/${awo.id}/addpresite`}>
//                                         Add Pre-Site
//                                     </Link>)
//                             }
//                         </Col>
//
//                         <Col>
//                             {
//                                 activePreSites.includes(awo.id)
//                                     ?
//                                     ('So True')
//                                     &&
//                                     activeOnSites.includes(awo.id)
//                                         ?
//                                         (<Link className={'btn btn-info'} to={`/onsite/${awo.id}/`}>Detail
//                                             OnSite</Link>)
//                                         :
//                                         (<Link className={'btn btn-warning'} to={`/clients/${awo.id}/addonsite/`}>Add
//                                             OnSite</Link>)
//                                     :
//                                     ("Fill out Pre-Site")
//                             }
//
//                         </Col>
//
//                         <Col>
//
//                             {
//                                 activeOnSites.includes(awo.id)
//                                     ?
//                                     ('So True')
//                                     &&
//                                     activeProposals.includes(awo.id)
//                                         ?
//                                         (<Link className={'btn btn-info'} to={`/proposals/${awo.id}/`}>
//                                             Detail Proposal
//                                         </Link>)
//                                         :
//                                         (<Link className={'btn btn-warning'} to={`/clients/${awo.id}/addproposal/`}> Add
//                                             Proposal </Link>)
//                                     :
//                                     ("Fill out On-Site")
//                             }
//                         </Col>
//
//                         <Col>
//                             {
//                                 activeProposals.includes(awo.id)
//                                     ?
//                                     ('So True')
//                                     &&
//                                     activeInvoices.includes(awo.id)
//                                         ?
//                                         (<Link className={'btn btn-info'} to={`/invoices/${awo.id}/`}> Detail
//                                             Invoice </Link>)
//                                         :
//                                         (<Link className={'btn btn-warning'} to={`/clients/${awo.id}/addinvoice/`}>Add
//                                             Invoice </Link>)
//                                     :
//                                     ("Fill out Proposal")
//                             }
//                         </Col>
//
//                         <Col>
//                             {
//                                 activeInvoices.includes(awo.id)
//                                     ?
//                                     ('So True')
//                                     &&
//                                     activeWorkOrders.includes(awo.id)
//                                         ?
//                                         (<Link className={'btn btn-info'} to={`/workorders/${awo.id}/`}> Detail
//                                             Work-Order </Link>)
//                                         :
//                                         (<Link className={'btn btn-warning'} to={`/clients/${awo.id}/addworkorder/`}>Add
//                                             Work-Order</Link>)
//                                     :
//                                     ("Fill out Invoice")
//                             }
//
//                         </Col>
//
//                         <Col>
//                             {
//                                 activeWorkOrders.includes(awo.id)
//                                     ?
//                                     ('So True')
//                                     &&
//                                     activeCheckLists.includes(awo.id)
//                                         ?
//                                         (<Link className={'btn btn-info'} to={`/checklist/${awo.id}/`}>
//                                             Detail Check List
//                                         </Link>)
//                                         :
//                                         (<Link className={'btn btn-warning'} to={`/clients/${awo.id}/addchecklist/`}>Add
//                                             Check List </Link>)
//                                     :
//                                     ("Fill out Work Order")
//                             }
//                         </Col>
//
//                         <div className={'Row AddHourRow'}>
//
//                                     <span className={"Col AddHourCol"}>
//                                         {
//                                             activeWorkOrders.includes(awo.id)
//                                                 ?
//                                                 ('So True')
//                                                 &&
//                                                 activeWorkOrders.includes(awo.id)
//                                                     ?
//                                                     <Link className={'btn btn-warning'} to={`/clients/${awo.id}/addhours/`}>Add Hours </Link>
//                                                     :
//                                                     ("Create Work Order For Job")
//                                                 :
//                                                 ("Create Work Order For Job")
//                                         }
//
//                                     </span>
//
//                             <span className={"Col AddHourCol"}>
//                                         {
//                                             activeWorkOrders.includes(awo.id)
//                                                 ?
//                                                 ('So True')
//                                                 &&
//                                                 activeWorkHours.includes(awo.id)
//                                                     ?
//                                                     (<Link className={'btn btn-info'} to={`/clienthours/${awo.id}/`}>
//                                                         Hours Worked
//                                                     </Link>)
//
//                                                     :
//                                                     ("Create Hours For Job")
//                                                 :
//                                                 ("Create Hours For Job")
//                                         }
//                                     </span>
//
//                         </div>
//
//                     </Row>
//                 )}
//
//             </Container>
//         );
//     }
// }
//
// export default ActiveWorkOrders;
