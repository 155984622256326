import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Button, Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";

const CreateProposal = () => {

    let navigate = useNavigate();

    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;
    let redirect = ''
    if (userInfo) {
        if (userInfo.group === "AdminSuper") {
            redirect = '/active_work_orders'
        } else if (userInfo.group === "Finance") {
            redirect = '/finance_active_work_orders'
        }
    }


    const [client, setClient] = useState()
    const [address, setAddress] = useState()

    const [proposal_approved_date, set_proposal_approved_date] = useState('')
    const [proposal_amount, set_proposal_amount] = useState(0)
    const [priority, set_priority] = useState('DEFAULT')
    const [who_to_bill_name, set_who_to_bill_name] = useState('')
    const [who_to_bill_phone, set_who_to_bill_phone] = useState('')
    const [who_to_bill_email, set_who_to_bill_email] = useState('')

    const [pic1, set_pic1] = useState(null)
    const [pic2, set_pic2] = useState(null)
    const [pic3, set_pic3] = useState(null)
    const [pic4, set_pic4] = useState(null)
    const [pic5, set_pic5] = useState(null)
    const [pic6, set_pic6] = useState(null)
    const [sign_doc_pic, set_sign_doc_pic] = useState(null)


    const { id } = useParams();

    const loadClient = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`clients/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data.id)
        setClient(data.id)
        setAddress(data.work_site_address)
    }


    useEffect(() => {
        loadClient()
    }, []);

    const AddProposal = async () => {

        let formField = new FormData()

        formField.append('client', client)
        formField.append('proposal_approved_date', proposal_approved_date)
        formField.append('proposal_amount', proposal_amount)
        formField.append('priority', priority)
        formField.append('who_to_bill_name', who_to_bill_name)
        formField.append('who_to_bill_phone', who_to_bill_phone)
        formField.append('who_to_bill_email', who_to_bill_email)


        if (pic1 !== null){
            formField.append('pic1', pic1)
        }

        if (pic2 !== null){
            formField.append('pic2', pic2)
        }

        if (pic3 !== null){
            formField.append('pic3', pic3)
        }

        if (pic4 !== null){
            formField.append('pic4', pic4)
        }

        if (pic5 !== null){
            formField.append('pic5', pic5)
        }

        if (pic6 !== null){
            formField.append('pic6', pic6)
        }

        if (sign_doc_pic !== null){
            formField.append('sign_doc_pic', sign_doc_pic)
        }

        // console.log(formField)
        await axios ({
            method: 'post',
            url: process.env.REACT_APP_API+`clients/${client}/addproposal/`,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            data: formField,
        }).then(() => {
            navigate(redirect)
        })
    }

    return(
        <div>
            <h1>Create Proposal for: {address}</h1>

            <Form>

                <Form.Group controlId={'proposal_approved_date'}>
                    <Form.Label>Proposal Approved Date:</Form.Label>
                    <Form.Control
                        type={'date'}
                        placeholder={"Proposal Approved Date"}
                        value={proposal_approved_date}
                        onChange={(e) => set_proposal_approved_date(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'proposal_amount'}>
                    <Form.Label>Proposal Amount:</Form.Label>
                    <Form.Control
                        type={'number'}
                        placeholder={"Proposal Amount"}
                        value={proposal_amount}
                        onChange={(e) => set_proposal_amount(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'priority'}>
                    <Form.Label>Priority:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_priority(e.target.value)}
                    >
                        <option value={'DEFAULT'}>Set Priority</option>
                        <option value="Low">Low</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'who_to_bill_name'}>
                    <Form.Label>Who to bill name:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Who To Bill Name"}
                        value={who_to_bill_name}
                        onChange={(e) => set_who_to_bill_name(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'who_to_bill_phone'}>
                    <Form.Label>Who to bill phone:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Who To Bill Phone"}
                        value={who_to_bill_phone}
                        onChange={(e) => set_who_to_bill_phone(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'who_to_bill_email'}>
                    <Form.Label>Who to bill email:</Form.Label>
                    <Form.Control
                        type={'email'}
                        placeholder={"Who To Bill Email"}
                        value={who_to_bill_email}
                        onChange={(e) => set_who_to_bill_email(e.target.value)}
                    />
                </Form.Group>



                <Form.Group controlId={'pic1'}>
                    <Form.Label>Photo 1:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_pic1(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'pic2'}>
                    <Form.Label>Photo 2:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_pic2(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'pic3'}>
                    <Form.Label>Photo 3:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_pic3(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'pic4'}>
                    <Form.Label>Photo 4:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_pic4(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'pic5'}>
                    <Form.Label>Photo 5:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_pic5(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'pic6'}>
                    <Form.Label>Photo 6:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_pic6(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'sign_doc_pic'}>
                    <Form.Label>Signed Picture of Document:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_sign_doc_pic(e.target.files[0])}
                    />
                </Form.Group>

                <br/>

                    <Button className={'btn btn-success'} onClick={AddProposal}>Add Proposal</Button>

            </Form>

        </div>
    );

};


export default CreateProposal