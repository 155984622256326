import React from 'react';
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const RestrictionPage = () => {

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin
    let redirect = ''
    if (userInfo){
        if (userInfo.group === "AdminSuper"){
            redirect = '/admin_dash'
        } else if (userInfo.group === "Finance") {
            redirect = '/finance_dash'
        } else if (userInfo.group === "Logistics") {
            redirect = '/logistics_dash'
        } else if (userInfo.group === "Tech") {
            redirect = '/tech_dash'
        }
    } else {
        redirect ='/'
    }


    return (
        <div className={"banner_heading"}>

            <h1>Page access restricted</h1>
            <div>
                <h2>You do not have permission to access this page. </h2>
                <Link to={redirect}>
                    <Button variant="link" size={"lg"}>Return to Dashboard</Button>
                </Link>

            </div>
        </div>
    )
}

export default RestrictionPage