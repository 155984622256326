import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getUserDetails} from "../actions/userActions";



function TestScreen() {



    return (
    <div>
<h1>Test Screen</h1>
    </div>

    )
}

export default TestScreen