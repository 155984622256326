import React from "react";
import {Row, Col, Card, Button,} from "react-bootstrap";
import { useNavigate } from "react-router-dom"

function OperationsDash() {

    let navigate = useNavigate ();

    return (
        <div>
            <h1 className={"banner_heading"}>Operations Dash</h1>
            <br />
            <Row >
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Manage Work Orders</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to the managing work orders</Card.Title>
                            <Card.Text>
                                Controls Creating Clients, Active Work Orders, and Finished Work Orders
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ () => navigate('/manage_work_orders')}
                            >Manage Work Orders</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Manage Service Orders</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to the Service Orders</Card.Title>
                            <Card.Text>
                                Access to Active Service Order Customers, and Closed Service Orders
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ () => {navigate('/manage_service_orders')}}>Service Orders</Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Logistics Report</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to the logistics report</Card.Title>
                            <Card.Text>
                                Access to Logistics Report where jobs where put on pause for an issue.
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ () => {navigate('/logistics_report')}}>Logistics Report</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>New Clients Report</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to a report made up of new clients</Card.Title>
                            <Card.Text>
                                Access to New Client Marketing Report that takes notes of ROI on marketing.
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ () => {navigate('/client_report')}}>New Clients Report</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </div>
    )
}

export default OperationsDash