import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Button, Form} from "react-bootstrap";
import {useSelector} from "react-redux";

const CreateComment = () => {

    let navigate = useNavigate();

    const [client, setClient] = useState()
    const [address, setAddress] = useState()
    const [tech_hour_comments, setTechHourComments] = useState('')
    const [office_hour_comments, setOfficeHourComments] = useState('')

    const {id} = useParams();
    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const loadClient = async () => {
        const {data} = await axios.get(process.env.REACT_APP_API + `clients/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data.id)
        setClient(data.id)
        setAddress(data.work_site_address)
    }

    let redirect = ''
    if (userInfo){
        if (userInfo.group === "AdminSuper"){
            redirect = '/active_work_orders'
        } else if (userInfo.group === "Finance") {
            redirect = '/finance_active_work_orders'
        } else if (userInfo.group === "Logistics") {
            redirect = '/logistics_active-work_orders'
        } else if (userInfo.group === "Tech") {
            redirect = '/tech_work_orders'
        }
    }

    useEffect(() => {
        loadClient()
    }, []);

    const CreateComment = async () => {
        let formField = new FormData()
        formField.append('client', client)
        formField.append('office_hour_comments', office_hour_comments)
        formField.append('tech_hour_comments', tech_hour_comments)

        // console.log(formField)
        await axios({
            method: 'post',
            url: process.env.REACT_APP_API + `clients/${client}/add_comment/`,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            data: formField,
        }).then(() => {
            navigate(redirect)
        })

    }

    return (
        <div>
            <h1>Create Comment for: {address}</h1>

            <Form>
                <Form.Group controlId={'tech_hour_comments'}>
                    <Form.Label>Technician Comments:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Tech Comments"}
                        value={tech_hour_comments}
                        onChange={(e) => setTechHourComments(e.target.value)}
                    />
                </Form.Group>


                <Form.Group controlId={'office_hour_comments'}>
                    <Form.Label>Office Comments:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Office Comments"}
                        value={office_hour_comments}
                        onChange={(e) => setOfficeHourComments(e.target.value)}
                    />
                </Form.Group>

                <br/>

                <Button className={'btn btn-success'} onClick={CreateComment}>Create Comment</Button>

            </Form>

        </div>
    );

};


export default CreateComment