import React, {useEffect, useState} from "react";
import { useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Button, Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../actions/userActions";

const CreateWorkOrder = () => {

    let navigate = useNavigate();

    const [client, setClient] = useState()
    const [address, setAddress] = useState()

    const [system_installed, set_system_installed] = useState('')
    const [job_completed, set_job_completed] = useState('')

    const [done, set_done] = useState('DEFAULT')
    const [prevailing_wage, set_prevailing_wage] = useState('DEFAULT')
    const [estimated_job_length, set_estimated_job_length] = useState('N/A')
    const [scheduling_reviewed, set_scheduling_reviewed] = useState('DEFAULT')
    const [are_we_hiring_a_roofing_company, set_are_we_hiring_a_roofing_company] = useState('DEFAULT')
    const [roofing_company_contact, set_roofing_company_contact] = useState('N/A')
    const [are_we_hiring_an_electrician, set_are_we_hiring_an_electrician] = useState('DEFAULT')
    const [number_of_fans_needed, set_number_of_fans_needed] = useState('0')
    const [type_of_fan, set_type_of_fan] = useState('N/A')
    const [work_to_be_done, set_work_to_be_done] = useState('N/A')
    const [notes_from_site, set_notes_from_site] = useState('N/A')
    const [cracked_ceiling, setCrackedCeiling] = useState('')

    const [design_picture_1, set_design_picture_1] = useState(null)
    const [design_picture_2, set_design_picture_2] = useState(null)
    const [design_picture_3, set_design_picture_3] = useState(null)
    const [design_picture_4, set_design_picture_4] = useState(null)
    const [design_picture_5, set_design_picture_5] = useState(null)

    const { id } = useParams();

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin
    let redirect = ''
    if (userInfo){
        if (userInfo.group === "AdminSuper"){
            redirect = '/active_work_orders'
        } else if (userInfo.group === "Finance") {
            redirect = '/finance_active_work_orders'
        } else if (userInfo.group === "Logistics") {
            redirect = '/logistics_active-work_orders'
        } else if (userInfo.group === "Tech") {
            redirect = '/tech_work_orders'
        }
    }

    const loadClient = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`clients/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data.id)
        setClient(data.id)
        setAddress(data.work_site_address)
    }

    useEffect(() => {
        loadClient()
    }, []);

    const AddWorkOrder = async () => {

        let formField = new FormData()

        if (prevailing_wage === 'DEFAULT'){
            alert("Please enter prevailing wage")
        } else if (are_we_hiring_an_electrician === 'DEFAULT'){
            alert("Please enter if we are hiring an electrician")
        } else if (are_we_hiring_a_roofing_company === 'DEFAULT'){
            alert("Please enter if we are hiring a roofing company")
        } else if (work_to_be_done === 'N/A'){
            alert("Please enter the work to be done")
        } else {
            formField.append('client', client)

            formField.append('system_installed', system_installed)
            formField.append('job_completed', job_completed)
            formField.append('done', done)
            formField.append('cracked_ceiling', cracked_ceiling)
            formField.append('prevailing_wage', prevailing_wage)
            formField.append('estimated_job_length', estimated_job_length)
            formField.append('scheduling_reviewed', scheduling_reviewed)
            formField.append('are_we_hiring_a_roofing_company', are_we_hiring_a_roofing_company)
            formField.append('roofing_company_contact', roofing_company_contact)
            formField.append('are_we_hiring_an_electrician', are_we_hiring_an_electrician)
            formField.append('number_of_fans_needed', number_of_fans_needed)
            formField.append('type_of_fan', type_of_fan)
            formField.append('work_to_be_done', work_to_be_done)
            formField.append('notes_from_site', notes_from_site)
            if (design_picture_1 !== null){
                formField.append('design_picture_1', design_picture_1)
            }

            if (design_picture_2 !== null){
                formField.append('design_picture_2', design_picture_2)
            }

            if (design_picture_3 !== null){
                formField.append('design_picture_3', design_picture_3)
            }

            if (design_picture_4 !== null){
                formField.append('design_picture_4', design_picture_4)
            }

            if (design_picture_5 !== null){
                formField.append('design_picture_5', design_picture_5)
            }
            await axios ({
                method: 'post',
                url: process.env.REACT_APP_API+`clients/${client}/addworkorder/`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                },
                data: formField,
            }).then(() => {
                    navigate(redirect)
            })
        }

    }

    return(
        <div>
            <h1>Create Work Order for: {address}</h1>

            <Form>

                <Form.Group controlId={'system_installed'}>
                    <Form.Label>System Installed:</Form.Label>
                    <Form.Control
                        type={'date'}
                        value={system_installed}
                        onChange={(e) => set_system_installed(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'job_completed'}>
                    <Form.Label>Job Completed:</Form.Label>
                    <Form.Control
                        type={'date'}
                        value={job_completed}
                        onChange={(e) => set_job_completed(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'done'}>
                    <Form.Label>Done:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_done(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Done?</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'cracked_ceiling'}>
                    <Form.Label>Cracked Ceiling:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => setCrackedCeiling(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Cracked Ceiling?</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'prevailing_wage'}>
                    <Form.Label>Prevailing wage:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_prevailing_wage(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Prevailing Wage</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'estimated_job_length'}>
                    <Form.Label>Estimated Job Length:</Form.Label>
                    <Form.Control
                        type={'text'}
                        value={estimated_job_length}
                        onChange={(e) => set_estimated_job_length(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'scheduling_reviewed'}>
                    <Form.Label>Scheduling reviewed:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_scheduling_reviewed(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Scheduling Reviewed</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'are_we_hiring_a_roofing_company'}>
                    <Form.Label>Are we hiring a roofing company:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_are_we_hiring_a_roofing_company(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Are we hiring a roofing company</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'roofing_company_contact'}>
                    <Form.Label>Roofing Company Contact:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Roofing company contact"}
                        value={roofing_company_contact}
                        onChange={(e) => set_roofing_company_contact(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'are_we_hiring_an_electrician'}>
                    <Form.Label>Are we hiring an electrician:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_are_we_hiring_an_electrician(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Are we hiring an electrician</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'number_of_fans_needed'}>
                    <Form.Label>Number of fans needed:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Number of fans needed"}
                        value={number_of_fans_needed}
                        onChange={(e) => set_number_of_fans_needed(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'type_of_fan'}>
                    <Form.Label>Type of Fan/s:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Type of fan"}
                        value={type_of_fan}
                        onChange={(e) => set_type_of_fan(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'work_to_be_done'}>
                    <Form.Label>Work to be done:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Work to be done"}
                        value={work_to_be_done}
                        onChange={(e) => set_work_to_be_done(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'design_picture_1'}>
                    <Form.Label>Design picture 1:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_design_picture_1(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'design_picture_2'}>
                    <Form.Label>Design picture 2:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_design_picture_2(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'design_picture_3'}>
                    <Form.Label>Design picture 3:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_design_picture_3(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'design_picture_4'}>
                    <Form.Label>Design picture 4:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_design_picture_4(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'design_picture_5'}>
                    <Form.Label>Design picture 5:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_design_picture_5(e.target.files[0])}
                    />
                </Form.Group>
                <br/>

                <Form.Group controlId={'notes_from_site'}>
                    <Form.Label>Notes from site:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Notes from site"}
                        value={notes_from_site}
                        onChange={(e) => set_notes_from_site(e.target.value)}
                    />
                </Form.Group>

                <br/>

                    <Button className={'btn btn-success'} onClick={AddWorkOrder}>Add WorkOrder</Button>

            </Form>

        </div>
    );

};


export default CreateWorkOrder