import React, {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import {Link} from "react-router-dom";
import {ClockLoader} from "react-spinners";

//TODO: the refresh needs a timer on it. Otherwise it just runs all the time

export class ClosedWorkOrdersFinance extends Component{

    constructor(props) {
        super(props);

        this.state = {
            // Active Work Orders clients
            AWOS: [],
            clientIsLoading: true,

            //Active PreSites
            APS: [],
            preSiteIsLoading: true,

            // Active On Sites
            AOS: [],
            onSiteIsLoading: true,

            // Active Proposals
            AP: [],
            proposalIsLoading: true,

            // Active Invoices
            AI: [],
            invoiceIsLoading: true,

            // Active WorkOrders
            ACWO: [],
            workOrderIsLoading: true,

            // Active CheckLists
            ACL: [],
            checkListIsLoading: true,

            // WorkHours
            WO: [],
            workHourIsLoading: true,
        }
    }


    componentDidMount() {
        axios.get(process.env.REACT_APP_API+'finished_work_orders/',{
            headers:{
                "Accept":'application/json',
                "Content-Type":'application/json'
            },
        })
            .then(response=>{
                this.setState({AWOS: response.data});
                this.setState({clientIsLoading :false});
            })

        axios.get(process.env.REACT_APP_API+'presites/', {
            headers:{
                "Accept":'application/json',
                "Content-Type":'application/json'
            },
        })
            .then(response=>{
                this.setState({APS: response.data});
                this.setState({preSiteIsLoading :false});
            })

        axios.get(process.env.REACT_APP_API+'onsites/',{
            headers:{
                "Accept":'application/json',
                "Content-Type":'application/json'
            },
        })
            .then(response=>{
                this.setState({AOS: response.data});
                this.setState({onSiteIsLoading :false});
            })

        axios.get(process.env.REACT_APP_API+'proposals/',{
            headers:{
                "Accept":'application/json',
                "Content-Type":'application/json'
            },
        })
            .then(response=>{
                this.setState({AP: response.data});
                this.setState({proposalIsLoading :false});
            })

        axios.get(process.env.REACT_APP_API+'invoices/',{
            headers:{
                "Accept":'application/json',
                "Content-Type":'application/json'
            },
        })
            .then(response=>{
                this.setState({AI: response.data});
                this.setState({invoiceIsLoading :false});
            })

        axios.get(process.env.REACT_APP_API+'workorders/',{
            headers:{
                "Accept":'application/json',
                "Content-Type":'application/json'
            },
        })
            .then(response=>{
                this.setState({ACWO: response.data});
                this.setState({workOrderIsLoading :false});
            })

        axios.get(process.env.REACT_APP_API+'checklists/',{
            headers:{
                "Accept":'application/json',
                "Content-Type":'application/json'
            },
        })
            .then(response=>{
                this.setState({ACL: response.data});
                this.setState({checkListIsLoading :false});
            })

        axios.get(process.env.REACT_APP_API+'workhours/',{
            headers:{
                "Accept":'application/json',
                "Content-Type":'application/json'
            },
        })
            .then(response=>{
                this.setState({WO: response.data});
                this.setState({workHourIsLoading :false});
            })

    }

    render() {

        const {
            AWOS, APS, AOS, AP, AI, ACWO, ACL, WO, clientIsLoading, preSiteIsLoading, onSiteIsLoading,
            proposalIsLoading, invoiceIsLoading, workOrderIsLoading, checkListIsLoading, workHourIsLoading
        } = this.state;

        if (clientIsLoading || preSiteIsLoading || onSiteIsLoading || proposalIsLoading || invoiceIsLoading || workOrderIsLoading || checkListIsLoading || workHourIsLoading){
            return <div className={'loadingSpinner'} >Loading...<br/> <ClockLoader color="#0d00ff" /></div>
        }

        let activePreSites = APS.map((client)=> client.client)
        let activeOnSites = AOS.map((client)=> client.client)
        let activeProposals = AP.map((client) => client.client)
        let activeInvoices = AI.map((client) => client.client)
        let activeWorkOrders = ACWO.map((client) => client.client)
        let activeCheckLists = ACL.map((client) => client.client)
        let activeWorkHours = WO.map((client) => client.client)

        return (

            <Container fluid>
                <br />
                <h1>Finance Finished Work Orders</h1>
                <br />

                {AWOS.map(awo=>
                    <Row key={awo.work_site_address} className={"workOrderRow"}>


                        <Col>
                            <h5>{awo.work_site_address}</h5>
                        </Col>

                        <Col>

                            {
                                activeOnSites.includes(awo.id)
                                    ?
                                    ('So True')
                                    &&
                                    activeProposals.includes(awo.id)
                                        ?
                                        (<Link className={'btn btn-info'} to={`/proposals/${awo.id}/`} >
                                            Detail Proposal
                                        </Link>)
                                        :
                                        (<Link className={'btn btn-warning'} to={`/clients/${awo.id}/addproposal/`} > Add Proposal </Link>)
                                    :
                                    ("Fill out On-Site")
                            }
                        </Col>

                        <Col>
                            {
                                activeProposals.includes(awo.id)
                                    ?
                                    ('So True')
                                    &&
                                    activeInvoices.includes(awo.id)
                                        ?
                                        (<Link className={'btn btn-info'} to={`/invoices/${awo.id}/`}> Detail Invoice </Link>)
                                        :
                                        (<Link className={'btn btn-warning'} to={`/clients/${awo.id}/addinvoice/`}>Add Invoice </Link>)
                                    :
                                    ("Fill out Proposal")
                            }
                        </Col>

                        <Col>
                            {
                                activeInvoices.includes(awo.id)
                                    ?
                                    ('So True')
                                    &&
                                    activeWorkOrders.includes(awo.id)
                                        ?
                                        (<Link className={'btn btn-info'} to={`/workorders/${awo.id}/`}> Detail Work-Order </Link>)
                                        :
                                        (<Link className={'btn btn-warning'} to={`/clients/${awo.id}/addworkorder/`}>Add Work-Order</Link>)
                                    :
                                    ("Fill out Invoice")
                            }

                        </Col>

                        <Col>
                            {
                                activeWorkOrders.includes(awo.id)
                                    ?
                                    ('So True')
                                    &&
                                    activeCheckLists.includes(awo.id)
                                        ?
                                        (<Link className={'btn btn-info'} to={`/checklist/${awo.id}/`} >
                                            Detail Check List
                                        </Link>)
                                        :
                                        (<Link className={'btn btn-warning'} to={`/clients/${awo.id}/addchecklist/`} >Add Check List </Link>)
                                    :
                                    ("Fill out Work Order")
                            }
                        </Col>

                    </Row>
                )}
            </Container>
        );
    }
}
export default ClosedWorkOrdersFinance;
