import React from "react";

function JobsNotApproved() {
    return (
        <div className={"banner_heading"}>
            <h1>Logic for Jobs Not Approved Report from back end to go here</h1>
        </div>
    )
}

export default JobsNotApproved