import React, {useState, useEffect} from 'react';
import {Form, Button} from "react-bootstrap";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const UpdatePresite = () => {

    let navigate = useNavigate();
    // const [address, setAddress] = useState()

    const [useID, setID] = useState()

    const [client, setClient] = useState()

    const [prints, setPrints] = useState(null);
    const [newPrints, setNewPrints] = useState(null)

    const [prints2, setPrints2] = useState(null);
    const [newPrints2, setNewPrints2] = useState(null)

    const [prints3, setPrints3] = useState(null);
    const [newPrints3, setNewPrints3] = useState(null);

    const [prints4, setPrints4] = useState(null);
    const [newPrints4, setNewPrints4] = useState(null);

    const [prints5, setPrints5] = useState(null);
    const [newPrints5, setNewPrints5] = useState(null);

    const [lock_box_or_entry_code, set_lock_box_or_entry_code] = useState('');
    const [safety_equipment_needed, set_safety_equipment_needed] = useState('');
    const [general_notes, set_general_notes] = useState('');
    const [is_property_vacant, set_is_property_vacant] = useState('N/A');
    const [site_hours, set_site_hours] = useState('');
    const [onsite_contact, set_onsite_contact] = useState('');
    const [onsite_contact_phone, set_onsite_contact_phone] = useState('');
    const [onsite_contact_email, set_onsite_contact_email] = useState('');

    const { id } = useParams();
    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;

    const loadPreSite = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`presite/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        });
        // console.log(data)
        setPrints(data.prints)
        setPrints2(data.prints2)
        setPrints3(data.prints3)
        setPrints4(data.prints4)
        setPrints5(data.prints5)
        set_lock_box_or_entry_code(data.lock_box_or_entry_code)
        set_safety_equipment_needed(data.safety_equipment_needed)
        set_general_notes(data.general_notes)
        set_is_property_vacant(data.is_property_vacant)
        set_site_hours(data.site_hours)
        set_onsite_contact(data.onsite_contact)
        set_onsite_contact_phone(data.onsite_contact_phone)
        set_onsite_contact_email(data.onsite_contact_email)

        setID(data.id)
        setClient(data.client)
        // console.log(data.client)
        // console.log(data.prints)

    }

    useEffect(() => {
        loadPreSite();
    }, [])

    const UpdatePreSiteInfo = async () =>{
        let formField = new FormData()
        formField.append('client', client)
        formField.append('lock_box_or_entry_code', lock_box_or_entry_code)
        formField.append('safety_equipment_needed', safety_equipment_needed)
        formField.append('general_notes', general_notes)
        formField.append('is_property_vacant', is_property_vacant)
        formField.append('site_hours', site_hours)
        formField.append('onsite_contact', onsite_contact)
        formField.append('onsite_contact_phone', onsite_contact_phone)
        formField.append('onsite_contact_email', onsite_contact_email)

        if (newPrints !== null){
            formField.append('prints', newPrints)
        }

        if (newPrints2 !== null){
            formField.append('prints2', newPrints2)
        }

        if (newPrints3 !== null){
            formField.append('prints3', newPrints3)
        }

        if (newPrints4 !== null){
            formField.append('prints4', newPrints4)
        }

        if (newPrints5 !== null){
            formField.append('prints5', newPrints5)
        }

        await axios({
            method: 'PUT',
            //TODO if this doesnt work try changing this URL
            url: process.env.REACT_APP_API+`presite/update/${useID}/`,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            data: formField,
        }).then(() => {
            // console.log(response.data)
            // console.log(Error)
            navigate('/active_work_orders')
        })
    }


    return (
        <div className={"banner_heading"}>

        <h1>Update Pre-Site:</h1>

            <Form>

                <Form.Group controlId={'lock_box_or_entry_code'}>
                    <Form.Label>Lock Box or Entry Code:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Lock Box or Entry Code"}
                        value={lock_box_or_entry_code}
                        onChange={(e) => set_lock_box_or_entry_code(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'safety_equipment_needed'}>
                    <Form.Label>Safety equipment needed:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Safety Equipment Needed"}
                        value={safety_equipment_needed}
                        onChange={(e) => set_safety_equipment_needed(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'general_notes'}>
                    <Form.Label>General Notes:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"General Notes"}
                        value={general_notes}
                        onChange={(e) => set_general_notes(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'is_property_vacant'}>
                    <Form.Label>Is Property Vacant:</Form.Label>
                    <Form.Select
                        value={is_property_vacant}
                        onChange={(e) => set_is_property_vacant(e.target.value)}
                    >
                        <option value={'DEFAULT'}>Is Property Vacant</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'site_hours'}>
                    <Form.Label>Site Hours:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Site Hours"}
                        value={site_hours}
                        onChange={(e) => set_site_hours(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'onsite_contact'}>
                    <Form.Label>Onsite Contact:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Onsite Contact"}
                        value={onsite_contact}
                        onChange={(e) => set_onsite_contact(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'onsite_contact_phone'}>
                    <Form.Label>Onsite Contact Phone Number:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Onsite Contact Phone"}
                        value={onsite_contact_phone}
                        onChange={(e) => set_onsite_contact_phone(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'onsite_contact_email'}>
                    <Form.Label>Onsite Contact Email:</Form.Label>
                    <Form.Control
                        type={'email'}
                        placeholder={"Onsite Contact Email"}
                        value={onsite_contact_email}
                        onChange={(e) => set_onsite_contact_email(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'prints'}>
                    <Form.Label>Prints:</Form.Label>
                    {
                        prints ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${prints}`} alt={'prints'}/>) : ("No Prints")
                    }


                    <Form.Control
                        type={'file'}
                        // value={prints}
                        // multiple
                        onChange={(e) => setNewPrints(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'prints2'}>
                    <Form.Label>Prints 2:</Form.Label>
                    {
                        prints2 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${prints2}`} alt={'prints'}/>) : ("No Prints 2")
                    }
                    <Form.Control
                        type={'file'}
                        // value={prints}
                        // multiple
                        onChange={(e) => setNewPrints2(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'prints3'}>
                    <Form.Label>Prints 3:</Form.Label>
                    {
                        prints3 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${prints3}`} alt={'prints'}/>) : ("No Prints 3")
                    }
                    <Form.Control
                        type={'file'}
                        // value={prints}
                        // multiple
                        onChange={(e) => setNewPrints3(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'prints4'}>
                    <Form.Label>Prints 4:</Form.Label>
                    {
                        prints4 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${prints4}`} alt={'prints'}/>) : ("No Prints 4")
                    }
                    <Form.Control
                        type={'file'}
                        // value={prints}
                        // multiple
                        onChange={(e) => setNewPrints4(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'prints5'}>
                    <Form.Label>Prints 5:</Form.Label>
                    {
                        prints5 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${prints5}`} alt={'prints'}/>) : ("No Prints 5")
                    }
                    <Form.Control
                        type={'file'}
                        // value={prints}
                        // multiple
                        onChange={(e) => setNewPrints5(e.target.files[0])}
                    />
                </Form.Group>

                <br/>

                    <Button className={'btn btn-success'} onClick={UpdatePreSiteInfo}>Update Pre-Site</Button>

            </Form>

        </div>
    )
}

export default UpdatePresite