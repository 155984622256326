import React, {useState, useEffect} from 'react';
import {Form, Button} from "react-bootstrap";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const UpdateProposal = () => {

    let navigate = useNavigate();
    // const [address, setAddress] = useState()

    const [client, setClient] = useState()
    const [useId, setId] = useState()


    const [proposal_approved_date, set_proposal_approved_date] = useState(null)
    const [proposal_amount, set_proposal_amount] = useState(0)
    const [priority, set_priority] = useState('DEFAULT')
    const [who_to_bill_name, set_who_to_bill_name] = useState('')
    const [who_to_bill_phone, set_who_to_bill_phone] = useState('')
    const [who_to_bill_email, set_who_to_bill_email] = useState('')

    const [pic1, set_pic1] = useState(null)
    const [newPhoto1, setNewPhoto1] = useState(null)

    const [pic2, set_pic2] = useState(null)
    const [newPhoto2, setNewPhoto2] = useState(null)

    const [pic3, set_pic3] = useState(null)
    const [newPhoto3, setNewPhoto3] = useState(null)

    const [pic4, set_pic4] = useState(null)
    const [newPhoto4, setNewPhoto4] = useState(null)

    const [pic5, set_pic5] = useState(null)
    const [newPhoto5, setNewPhoto5] = useState(null)

    const [pic6, set_pic6] = useState(null)
    const [newPhoto6, setNewPhoto6] = useState(null)

    const [sign_doc_pic, set_sign_doc_pic] = useState(null)
    const [newSignDocPic, setNewSignDocPic] = useState(null)

    const { id } = useParams();

    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;
    let redirect = ''
    if (userInfo) {
        if (userInfo.group === "AdminSuper") {
            redirect = '/active_work_orders'
        } else if (userInfo.group === "Finance") {
            redirect = '/finance_active_work_orders'
        }
    }

    const loadProposal = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`proposals/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        });
        // console.log(data)

        set_proposal_approved_date(data.proposal_approved_date)
        set_proposal_amount(data.proposal_amount)
        set_priority(data.priority)
        set_who_to_bill_name(data.who_to_bill_name)
        set_who_to_bill_phone(data.who_to_bill_phone)
        set_who_to_bill_email(data.who_to_bill_email)
        set_pic1(data.pic1)
        set_pic2(data.pic2)
        set_pic3(data.pic3)
        set_pic4(data.pic4)
        set_pic5(data.pic5)
        set_pic6(data.pic6)
        set_sign_doc_pic(data.sign_doc_pic)

        setId(data.id)
        setClient(data.client)

        // console.log(data.id)
        // console.log(data.client)
    }


    useEffect(() => {
        loadProposal();
    }, [])

    const UpdateProposalInfo = async () =>{
        let formField = new FormData()
        formField.append('client', client)
        if (proposal_approved_date === null){
            formField.append('proposal_approved_date', "")
        } else {
            formField.append('proposal_approved_date',proposal_approved_date)
        }
        // formField.append('proposal_approved_date',proposal_approved_date)
        formField.append('proposal_amount',proposal_amount)
        formField.append('priority',priority)
        formField.append('who_to_bill_name',who_to_bill_name)
        formField.append('who_to_bill_phone',who_to_bill_phone)
        formField.append('who_to_bill_email',who_to_bill_email)

        if (newPhoto1 !== null){
            formField.append('pic1', newPhoto1)
        }
        if (newPhoto2 !== null){
            formField.append('pic2', newPhoto2)
        }
        if (newPhoto3 !== null){
            formField.append('pic3', newPhoto3)
        }
        if (newPhoto4 !== null){
            formField.append('pic4', newPhoto4)
        }
        if (newPhoto5 !== null){
            formField.append('pic5', newPhoto5)
        }
        if (newPhoto6 !== null){
            formField.append('pic6', newPhoto6)
        }
        if (newSignDocPic !== null){
            formField.append('sign_doc_pic', newSignDocPic)
        }

        await axios({
            method: 'PUT',
            //TODO if this doesnt work try changing this URL
            url: process.env.REACT_APP_API+`proposal/update/${useId}/`,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            data: formField,
        }).then(() => {
            // console.log(response.data)
            // console.log(Error)
            navigate(redirect)
        })
    }


    return (
        <div className={"banner_heading"}>

            <h1>Update Proposal:</h1>

            <Form>

                <Form.Group controlId={'proposal_approved_date'}>
                    <Form.Label>Proposal approved date:</Form.Label>
                    <Form.Control
                        type={'date'}
                        placeholder={"Proposal Approved Date"}
                        value={proposal_approved_date}
                        onChange={(e) => set_proposal_approved_date(e.target.value)}
                    />
                </Form.Group>



                <Form.Group controlId={'proposal_amount'}>
                    <Form.Label>Proposal Amount:</Form.Label>
                    <Form.Control
                        type={'number'}
                        placeholder={"Proposal Amount"}
                        value={proposal_amount}
                        onChange={(e) => set_proposal_amount(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'priority'}>
                    <Form.Label>Priority:</Form.Label>
                    <Form.Select
                        value={priority}
                        onChange={(e) => set_priority(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Priority:</option>
                        <option value="Low">Low</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                    </Form.Select>
                </Form.Group>



                <Form.Group controlId={'who_to_bill_name'}>
                    <Form.Label>Who to bill name:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Who to bill name"}
                        value={who_to_bill_name}
                        onChange={(e) => set_who_to_bill_name(e.target.value)}
                    />
                </Form.Group>


                <Form.Group controlId={'who_to_bill_phone'}>
                    <Form.Label>Who to bill phone:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Who to bill phone"}
                        value={who_to_bill_phone}
                        onChange={(e) => set_who_to_bill_phone(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'who_to_bill_email'}>
                    <Form.Label>Who to bill email:</Form.Label>
                    <Form.Control
                        type={'email'}
                        placeholder={"Who to bill email"}
                        value={who_to_bill_email}
                        onChange={(e) => set_who_to_bill_email(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'pic1'}>
                    <Form.Label>Photo 1:</Form.Label>
                    {
                        pic1 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${pic1}`} alt={'photo1'}/>) : ("No Photo 1")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewPhoto1(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo2'}>
                    <Form.Label>Photo 2:</Form.Label>
                    {
                        pic2 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${pic2}`} alt={'photo2'}/>) : ("No Photo 2")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewPhoto2(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo3'}>
                    <Form.Label>Photo 3:</Form.Label>
                    {
                        pic3 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${pic3}`} alt={'photo3'}/>) : ("No Photo 3")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewPhoto3(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo4'}>
                    <Form.Label>Photo 4:</Form.Label>
                    {
                        pic4 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${pic4}`} alt={'photo4'}/>) : ("No Photo 4")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewPhoto4(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo5'}>
                    <Form.Label>Photo 5:</Form.Label>
                    {
                        pic5 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${pic5}`} alt={'photo5'}/>) : ("No Photo 5")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewPhoto5(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo6'}>
                    <Form.Label>Photo 6:</Form.Label>
                    {
                        pic6 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${pic6}`} alt={'photo6'}/>) : ("No Photo 6")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewPhoto6(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo7'}>
                    <Form.Label>Photo 7:</Form.Label>
                    {
                        sign_doc_pic ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${sign_doc_pic}`} alt={'photo7'}/>) : ("No Signed Document")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => setNewSignDocPic(e.target.files[0])}
                    />
                </Form.Group>

                <br/>

                    <Button className={'btn btn-success'} onClick={UpdateProposalInfo}>Update Proposal</Button>

            </Form>


        </div>
    )
}

export default UpdateProposal