import React, {useState, useEffect} from 'react';
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const ProposalDetail = () => {

    const [proposal, setProposal] = useState("")

    const { id } = useParams();
    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;

    const getSingleProposal = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`proposals/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        setProposal(data)
    }

    useEffect(()=>{
        getSingleProposal()
    }, [])


    return (
        <div className={"banner_heading"}>
            <h1>Proposal Detail</h1>
            <div>

                <p>Proposal Approved Date: {proposal.proposal_approved_date}</p>
                <p>Proposal Amount: {proposal.proposal_amount}</p>
                <p>Priority: {proposal.priority}</p>

                <p>Who to bill name: {proposal.who_to_bill_name}</p>
                <p>Who to bill phone: {proposal.who_to_bill_phone}</p>
                <p>Who to bill email: {proposal.who_to_bill_email}</p>

                {
                    proposal.pic1 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${proposal.pic1}`}><img className={'processImages'} src={`http://127.0.0.1:8000${proposal.pic1}`} alt={'prints'}/></a>) : ("No Picture")
                }
                <br /> <br />
                {
                    proposal.pic2 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${proposal.pic2}`}><img className={'processImages'} src={`http://127.0.0.1:8000${proposal.pic2}`} alt={'prints 2'}/></a>) : ("No Picture 2")
                }
                <br/> <br />
                {
                    proposal.pic3 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${proposal.pic3}`}><img className={'processImages'} src={`http://127.0.0.1:8000${proposal.pic3}`} alt={'prints 3'}/></a>) : ("No Picture 3")
                }
                <br/> <br />
                {
                    proposal.pic4 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${proposal.pic4}`}><img className={'processImages'} src={`http://127.0.0.1:8000${proposal.pic4}`} alt={"prints 4"}/></a>) : ("No Picture 4")
                }
                <br/> <br />
                {
                    proposal.pic5 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${proposal.pic5}`}><img className={'processImages'} src={`http://127.0.0.1:8000${proposal.pic5}`} alt={'prints 5'}/></a>) : ("No Picture 5")
                }
                <br /> <br />
                {
                    proposal.pic6 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${proposal.pic6}`}><img className={'processImages'} src={`http://127.0.0.1:8000${proposal.pic6}`} alt={'prints 5'}/></a>) : ("No Picture 5")
                }
                <br /> <br />
                {
                    proposal.sign_doc_pic ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${proposal.sign_doc_pic}`}><img className={'processImages'} src={`http://127.0.0.1:8000${proposal.sign_doc_pic}`} alt={'prints 5'}/></a>) : ("No Signed Document Picture")
                }
                <br />
                <br />
                <Link className={'btn btn-primary'} to={`/proposal/update/${id}/`}>Update Proposal</Link>

            </div>
        </div>
    )
}

export default ProposalDetail