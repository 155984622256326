import React, {useState, useEffect} from 'react';
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const CheckListDetail = () => {

    const [checkList, setCheckList] = useState("")

    const { id } = useParams();

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const getSingleCheckList = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`check_list/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        setCheckList(data)
    }

    useEffect(()=>{
        getSingleCheckList()
    }, [])


    return (
        <div className={"banner_heading"}>
            <h1>Check List Detail</h1>
            <div>
                <a href={checkList.forms_link} target={'_blank'} rel="noreferrer">Forms link: {checkList.forms_link}</a>
                <p>Is premeditation done: {checkList.is_premeditation_done}</p>
                <p>Is active mitigation done: {checkList.is_active_mitigation_done}</p>
                <p>Is post mitigation done: {checkList.is_post_mitigation_done}</p>
                <p>Are gauges on: {checkList.are_gauges_on}</p>
                <p>Is system labeled: {checkList.is_system_labeled}</p>
                <p>Is_om and m done: {checkList.is_om_and_m_done}</p>
                <p>Is electrical done: {checkList.is_electrical_done}</p>
                <p>Is fan on: {checkList.is_fan_on}</p>
                <p>Ready to invoice: {checkList.ready_to_invoice}</p>
                <p>None of the above apply: {checkList.none_of_the_above_apply}</p>
                <p>Job is fully completed: {checkList.job_is_fully_completed}</p>

                <br />
                <Link className={'btn btn-primary'} to={`/check_list/update/${id}/`}>Update Check List</Link>

            </div>
        </div>
    )
}

export default CheckListDetail