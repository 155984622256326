import React, {Component} from "react";
import {Nav, Table} from "react-bootstrap";
import axios from "axios";
import {ClockLoader} from "react-spinners";
import {Link} from "react-router-dom";

//TODO: the refresh needs a timer on it. Otherwise it just runs all the time

export class ProposalsNotSigned extends Component{

    constructor() {
        super();
        this.state = {
            filterOption: '',
            filteredData: [],
            newData: [],
            proposalIsLoading: true,
            sum: [],
        };
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API+'non_approved_proposals/',{
            headers:{
                "Accept":'application/json',
                "Content-Type":'application/json'
            },
        })
            .then(response=>{
                this.setState({newData: response.data});
                this.setState({proposalIsLoading: false});
            })
    }

    optionSelected(e) {
        this.setState({
            filterOption: e.target.value,
        });
    }

    renderTableData(newData) {
        return newData.map((element, i) => (
            <tr key={i}>
                <td>Job Not Yet Approved</td>
                <td>{element.job_type}</td>
                <td>{element.client}</td>
                <td>{element.who_to_bill_name}</td>
                <td>{element.proposal_amount}</td>
                <td>{element.priority}</td>
                <td><Link className={'btn btn-primary'} to={`/proposal/update/${element.id_number}/`}>Update Proposal</Link></td>
            </tr>
        ));
    };

    renderFilterOptions(filterOption) {
        if(filterOption) {
            let { newData } = this.state;
            let options = Array.from(new Set(newData.map(element => element[filterOption])));
            return options.map((option,i) => <option key={i} value={option}> {option} </option>)
        }
        return []
    };

    filterOptionSelected(e) {
        let {value} = e.target;
        if(value) {
            let { filterOption, newData } = this.state
            let filteredData = newData.filter(element => element[filterOption] === value);

            const getSum = filteredData.reduce((obj, {proposal_amount}) => {
                obj[proposal_amount] = proposal_amount;
                return obj;
            }, {});

            let sum = 0;
            for (const value of Object.values(getSum)) {
                sum += parseInt(value);
            }

            this.setState({
                filteredData: filteredData,
                getSum: getSum,
                sum: sum.toFixed(2),
            });
        }
    };


    render() {
        let { filterOption, filteredData, newData, proposalIsLoading, sum} = this.state

        if (proposalIsLoading){
            return <div className={'loadingSpinner'} >Loading...<br/> <ClockLoader color="#0d00ff" /></div>
        }

        return (
            <div>
                <Nav className={'reports_nav_heading'} variant="tabs" defaultActiveKey="/finance_reports">
                    <Nav.Item>
                        <Nav.Link href="/signed_proposals">
                            Signed Proposals
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="disabled" disabled>
                            Not Signed Proposals and unapproved jobs
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/approved_jobs">
                            Approved Jobs
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/custom_proposals">
                            Custom Proposal Reports
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <div>
                    <h1 className={'perposalInvoiceHeaders'}>Sales For Proposals Not Signed & Unapproved Jobs</h1>
                    <label>Filter By:</label>
                    <br/>
                    <select onChange={this.optionSelected.bind(this)}>
                        <option value="">  </option>
                        <option value="client">Client</option>
                        <option value="year">Year</option>
                        <option value="year_and_month">Month & Year</option>
                    </select>

                    <select onChange={this.filterOptionSelected.bind(this)}>
                        <option value="">  </option>
                        {this.renderFilterOptions(filterOption)}
                    </select>
                </div>
                <Table responsive="lg" className={'mt-4'} striped bordered hover size={'lg'}>
                    <thead>
                    <th>Approval Date:</th>
                    <th>Job Type:</th>
                    <th>Address:</th>
                    <th>Client:</th>
                    <th>Proposal Bid/Job Total:</th>
                    <th>Priority:</th>
                    </thead>
                    <tbody>
                    {
                        filteredData.length ? this.renderTableData(filteredData) : this.renderTableData(newData)
                    }
                    <div className={'reportsSum'}>
                        TOTAL: {sum}
                    </div>

                    </tbody>
                </Table>
            </div>
        );
    }
}

export default ProposalsNotSigned;



// import React, {Component} from "react";
// import {Nav, Row, Table} from "react-bootstrap";
// import axios from "axios";
// import {ClockLoader} from "react-spinners";
// import {Link} from "react-router-dom";
//
// export class SignedProposals extends Component {
//
//     constructor(props) {
//         super(props);
//
//         this.state = {
//             // Active Proposals
//             AP: [],
//             // Loading States
//             proposalIsLoading: true,
//         }
//     }
//
//
//     componentDidMount() {
//         axios.get(['http://127.0.0.1:8000/api/non_approved_proposals/',
//         ],{
//             headers:{
//                 "Accept":'application/json',
//                 "Content-Type":'application/json'
//             },
//         })
//             .then(response=>{
//                 this.setState({AP: response.data});
//                 this.setState({proposalIsLoading: false});
//             })
//     }
//
//     render() {
//
//         const {AP,  proposalIsLoading} = this.state;
//
//         if (proposalIsLoading){
//             return <div className={'loadingSpinner'} >Loading...<br/> <ClockLoader color="#0d00ff" /></div>
//         }
//
//         return (
//             <div >
//
//                 <Nav className={'reports_nav_heading'} variant="tabs" defaultActiveKey="/finance_reports">
//                     <Nav.Item>
//                         <Nav.Link href="/signed_proposals">
//                             Signed Proposals
//                         </Nav.Link>
//                     </Nav.Item>
//
//                     <Nav.Item>
//                         <Nav.Link eventKey="disabled" disabled>
//                             Not Signed Proposals and unapproved jobs
//                         </Nav.Link>
//                     </Nav.Item>
//
//                     <Nav.Item>
//                         <Nav.Link href="/approved_jobs">
//                             Approved Jobs
//                         </Nav.Link>
//                     </Nav.Item>
//
//                     <Nav.Item>
//                         <Nav.Link href="/custom_proposals">
//                             Custom Proposal Reports
//                         </Nav.Link>
//                     </Nav.Item>
//                 </Nav>
//
//                 <Row>
//                     <h1 className={'perposalInvoiceHeaders'}>Sales For Proposals Not Signed & Unapproved Jobs</h1>
//                     <Table responsive="lg" className={'mt-4'} striped bordered hover size={'lg'}>
//                         <thead>
//                         <tr>
//                             <th>Approval Date:</th>
//                             <th>Job Type:</th>
//                             <th>Address:</th>
//                             <th>Client:</th>
//                             <th>Proposal Bid/Job Total:</th>
//                             <th>Priority:</th>
//                         </tr>
//                         </thead>
//
//                         <tbody>
//                         {AP.map((ap)=>
//                             <tr key={ap.id}>
//                                 <td>Job Not Yet Approved</td>
//                                 <td>{ap.job_type}</td>
//                                 <td>{ap.client}</td>
//                                 <td>{ap.who_to_bill_name}</td>
//                                 <td>{ap.proposal_amount}</td>
//                                 <td>{ap.priority}</td>
//                                 <td><Link className={'btn btn-primary'} to={`/proposal/update/${ap.id_number}/`}>Update Proposal</Link></td>
//
//                             </tr>
//                         )}
//                         </tbody>
//                     </Table>
//                 </Row>
//             </div>
//         );
//     }
// }
//
// export default SignedProposals;
//
//
//
