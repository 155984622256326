import React from "react";
import {Row, Col, Card, Button,} from "react-bootstrap";
import { useNavigate } from 'react-router-dom'

function FinanceDash() {

    let navigate = useNavigate();

    return (
        <div>
            <h1 className={"banner_heading"}>Finance Dash</h1>
            <br />
            <Row>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Reporting Dash</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to Reports</Card.Title>
                            <Card.Text>
                                Access to Invoices, Proposals, Service Orders and Job Approves and if they are completed or in progress
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ () => navigate('/finance_reports')}
                            >Reporting Dash</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Active Work Orders</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to Active Work Orders</Card.Title>
                            <Card.Text>
                                Access to Active Work orders for proposal and inventory.
                            </Card.Text>
                            <Button variant="primary"
                            onClick={ ()=> navigate('/finance_active_work_orders')}
                            >Active Work Orders</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Closed Work Orders</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to Closed Work Orders</Card.Title>
                            <Card.Text>
                                Access to Closed Work orders for proposal and inventory.
                            </Card.Text>
                            <Button variant="primary"
                            onClick={ () => navigate('/closed_work_orders_finance') }
                            >Closed Work Orders</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <br /> <br />

            <Row>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Calender for Assigned Employees</Card.Header>
                        <Card.Body>
                            <Card.Title>Access to Calender</Card.Title>
                            <Card.Text>
                                Access to Employee Work Scheduled Calender
                            </Card.Text>
                            <Button variant="primary"
                            onClick={ ()=> navigate('/calender_assigned_employees')}
                            >Calender</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </div>
    )
}

export default FinanceDash