import React, {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import {Link} from "react-router-dom";
import {ClockLoader} from "react-spinners";


//TODO: the refresh needs a timer on it. Otherwise it just runs all the time

export class TechWorkOrders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // Active Work Orders clients
            AWOS: [],
            clientIsLoading: true,

            // Active WorkOrders
            ACWO: [],
            workOrderIsLoading: true,

            // Active CheckLists
            ACL: [],
            checkListIsLoading: true,

            // WorkHours
            WO: [],
            workHourIsLoading: true,

            // Priorities
            priority: [],
            priorityIsLoading: true,

            // Comments
            comments: [],
            commentsAreLoading: true,
        }
    }


    componentDidMount() {
        axios.get(process.env.REACT_APP_API + 'clients/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({AWOS: response.data});
                this.setState({clientIsLoading: false});
            })


        axios.get(process.env.REACT_APP_API + 'tech_work_orders/',
            {
                headers: {
                    "Accept": 'application/json',
                    "Content-Type": 'application/json'
                },
            })
            .then(response => {
                this.setState({ACWO: response.data});
                this.setState({workOrderIsLoading: false});
            })

        axios.get(process.env.REACT_APP_API + 'checklists/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({ACL: response.data});
                this.setState({checkListIsLoading: false});
            })

        axios.get(process.env.REACT_APP_API + 'workhours/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({WO: response.data});
                this.setState({workHourIsLoading: false});
            })

        axios.get(process.env.REACT_APP_API + 'priority_reports/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({priority: response.data});
                this.setState({priorityIsLoading: false});
            })

        axios.get(process.env.REACT_APP_API + 'comments/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({comments: response.data});
                this.setState({commentsAreLoading: false});
            })
    }

    render() {

        const {
            AWOS,
            ACWO,
            ACL,
            WO,
            priority,
            comments,
            clientIsLoading,
            workOrderIsLoading,
            checkListIsLoading,
            workHourIsLoading,
            priorityIsLoading,
            commentsAreLoading
        } = this.state;

        if (clientIsLoading || workOrderIsLoading || checkListIsLoading || workHourIsLoading || priorityIsLoading || commentsAreLoading) {
            return <div className={'loadingSpinner'}>Loading...<br/> <ClockLoader color={'#0d00ff'}/></div>
        }

        let activeWorkOrders = ACWO.map((client) => client.client)
        let activeCheckLists = ACL.map((client) => client.client)
        let activeWorkHours = WO.map((client) => client.client)
        let priorities = priority.map((client) => client.client)
        let techcomments = comments.map((client) => client.client)

        return (

            <Container fluid>
                <h1>Active Work Orders:</h1>

                <div className={activeWorkOrders}
                >
                    {ACWO.map((awo, index) =>
                        <Row
                            className={"workOrderRow"}
                            key={awo.id}
                        >
                            <Col>
                                <h4>{awo.client_name}</h4>
                            </Col>


                            <Col>
                                {
                                    activeWorkOrders.includes(awo.id)
                                        ?
                                        (<Link className={'btn btn-info'}
                                               to={`/workorders/${awo.id}/`}> Detail
                                            Work-Order </Link>)
                                        :
                                        ("Work Order will soon be assigned to this client")

                                }
                            </Col>

                            <Col className={"Col AddHourCol"}>
                                {
                                    activeWorkOrders.includes(awo.id)
                                        ?
                                        ('So True')
                                        &&
                                        activeWorkOrders.includes(awo.id)
                                            ?
                                            <Link className={'btn btn-warning'}
                                                  to={`/clients/${awo.id}/addhours/`}>Add Hours</Link>
                                            :
                                            ("Create Work Order For Job")
                                        :
                                        ("Create Work Order For Job")
                                }

                            </Col>
                            <Col className={"Col AddHourCol"}>
                                {
                                    activeWorkOrders.includes(awo.id)
                                        ?
                                        ('So True')
                                        &&
                                        activeWorkHours.includes(awo.id)
                                            ?
                                            (<Link className={'btn btn-info'} to={`/clienthours/${awo.id}/`}>
                                                Hours Worked
                                            </Link>)

                                            :
                                            ("Create Hours For Job")
                                        :
                                        ("Create Hours For Job")
                                }
                            </Col>

                            <Col>
                                {
                                    activeWorkOrders.includes(awo.id)
                                        ?
                                        ('So True')
                                        &&
                                        activeCheckLists.includes(awo.id)
                                            ?
                                            (<Link className={'btn btn-info'}
                                                   to={`/checklist/${awo.id}/`}>
                                                Detail Check List
                                            </Link>)
                                            :
                                            (<Link className={'btn btn-warning'}
                                                   to={`/clients/${awo.id}/addchecklist/`}>Add
                                                Check List </Link>)
                                        :
                                        ("Fill out Work Order")
                                }
                            </Col>



                                <Col className={"Col AddHourCol"}>
                                        {
                                            activeWorkOrders.includes(awo.id)
                                                ?
                                                ('So True')
                                                &&
                                                activeWorkOrders.includes(awo.id) && priorities.includes(awo.id)
                                                    ?
                                                    <Link className={'btn btn-success'}
                                                          to={`/priority_reports/${awo.id}/`}>Detail Priority</Link>
                                                    :
                                                    ("Job Does Not Have Set Priority")
                                                :
                                                ("Create Work Order For Job")
                                        }

                                    </Col>

                                <Col className={"Col AddHourCol"}>
                                        {
                                            activeWorkOrders.includes(awo.id)
                                                ?
                                                ('So True')
                                                &&
                                                activeWorkOrders.includes(awo.id) && techcomments.includes(awo.id)
                                                    ?
                                                    <Link className={'btn btn-success'}
                                                          to={`/comment/${awo.id}/`}>Review Comment</Link>
                                                    :
                                                    <Link className={'btn btn-danger'}
                                                          to={`/clients/${awo.id}/add_comment/`}>Add Comment</Link>
                                                :
                                                ("Create Work Order For Job")
                                        }

                                    </Col>
                        </Row>
                    )}
                </div>
            </Container>
        );
    }
}

export default TechWorkOrders;


// import React from "react";
//
// function TechWorkOrders() {
//     return (
//         <div className={"banner_heading"}>
//             <h1>Logic for Tech Work Orders from back end to go here</h1>
//         </div>
//     )
// }
//
// export default TechWorkOrders