import React from "react";

function InventoryDash() {
    return (
        <div className={"banner_heading"}>
            <h1>Inventory Dash Yet Maybe outlined to go here.</h1>

        </div>
    )
}

export default InventoryDash