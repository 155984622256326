import React, {useState, useEffect} from 'react';
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import Moment from "moment/moment";
import {useSelector} from "react-redux";

const DetailWorkHours = () => {

    const [workHours, setWorkHours] = useState("")

    const { id } = useParams();
    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;

    const getSingleHour = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`workhour/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        setWorkHours(data)

    }

    useEffect(()=>{
        getSingleHour()
    }, [])


    return (
        <div className={"banner_heading"}>
            <h1>Work Hours Detail: </h1>

            <div>

                <p>Employees Onsite: {workHours.employees_onsite}</p>
                <p>Work Description: {workHours.work_description}</p>
                <p>Rework: {workHours.rework}</p>
                <p>Hours: {workHours.hours}</p>
                <p>Minuets: {workHours.minutes}</p>
                {/*<p>Started: {workHours.started}</p>*/}
                <p>{Moment(workHours.created).format("[Started On:] MM/DD/YYYY,  hh:mm:ss a")}</p>
                <p>Finished {workHours.finished}</p>

                {
                    workHours.photo1 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workHours.photo1}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workHours.photo1}`} alt={'image 1'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 1 Description : {workHours.photo_1_description}</p>

                {
                    workHours.photo2 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workHours.photo2}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workHours.photo2}`} alt={'image 2'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 2 Description : {workHours.photo_2_description}</p>

                {
                    workHours.photo3 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workHours.photo3}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workHours.photo3}`} alt={'image 3'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 3 Description : {workHours.photo_3_description}</p>

                {
                    workHours.photo4 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workHours.photo4}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workHours.photo4}`} alt={'image 4'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 4 Description : {workHours.photo_4_description}</p>

                {
                    workHours.photo5 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workHours.photo5}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workHours.photo5}`} alt={'image 5'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 5 Description : {workHours.photo_5_description}</p>

                {
                    workHours.photo6 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workHours.photo6}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workHours.photo6}`} alt={'image 6'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 6 Description : {workHours.photo_6_description}</p>

                {
                    workHours.photo7 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workHours.photo7}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workHours.photo7}`} alt={'image 7'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 7 Description : {workHours.photo_7_description}</p>

                {
                    workHours.photo8 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workHours.photo8}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workHours.photo8}`} alt={'image 8'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 8 Description : {workHours.photo_8_description}</p>

                {
                    workHours.photo9 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workHours.photo9}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workHours.photo9}`} alt={'image 9'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 9 Description : {workHours.photo_9_description}</p>

                {
                    workHours.photo10 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workHours.photo10}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workHours.photo10}`} alt={'image 10'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 10 Description : {workHours.photo_10_description}</p>

                {
                    workHours.photo11 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workHours.photo11}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workHours.photo11}`} alt={'image 11'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 11 Description : {workHours.photo_11_description}</p>

                {
                    workHours.photo12 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workHours.photo12}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workHours.photo12}`} alt={'image 12'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 12 Description : {workHours.photo_12_description}</p>

                {
                    workHours.photo13 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workHours.photo13}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workHours.photo13}`} alt={'image 13'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 13 Description : {workHours.photo_13_description}</p>

                {
                    workHours.photo14 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workHours.photo14}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workHours.photo14}`} alt={'image 14'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 14 Description : {workHours.photo_14_description}</p>

                {
                    workHours.photo15 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${workHours.photo15}`}><img className={'processImages'} src={`http://127.0.0.1:8000${workHours.photo15}`} alt={'image 15'}/></a>) : ("No Picture")
                }
                <br />
                <p>Picture 15 Description : {workHours.photo_15_description}</p>

                <br />
                <br />
                <Link className={'btn btn-primary'} to={`/clienthours/${workHours.client}/`}>Active Work Hours For Client</Link>

            </div>
        </div>
    )
}

export default DetailWorkHours