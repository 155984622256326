import React, {useState, useEffect} from 'react';
import { Form, Button} from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams} from "react-router-dom";
import Moment from "moment";
import {useSelector} from "react-redux";

const UpdateInvoice = () => {

    let navigate = useNavigate();

    const [client, setClient] = useState();
    const [useId, setId] = useState();

    const [date, setDate] = useState(new Date());

    let [newPayment,setNewPayment] = useState(0);
    let [newBillAmount, setNewBillAmount] = useState(0);

    const [showPaymentMadeInFull, setShowPaymentMadeInFull] = useState(false);
    const [math,setMath] = useState(0.00);
    const [math2, setMath2] = useState(0);
    let myNumberWithTwoDecimalPlaces=parseFloat(math).toFixed(2);
    let mySecondNumberWithTwoDecimalPlaces=parseFloat(math2).toFixed(2);

    const [is_down_payment_made, set_is_down_payment_made] = useState('');
    const [down_payment_amount, set_down_payment_amount] = useState(0);
    const [bill_amount, set_bill_amount] = useState(0);
    const [payment_plan, set_payment_plan] = useState('');

    const [payment_notes, set_payment_notes] = useState('');
    const [oldPaymentNotes, setOldPaymentNotes] = useState('');

    const [current_balance_due, set_current_balance_due] = useState(0);
    const [po, set_po] = useState('');
    const [work_order_number, set_work_order_number] = useState('');
    const [project_id, set_project_id] = useState('');
    const [payment_made_in_full, set_payment_made_in_full] = useState('');
    const [priority, set_priority] = useState('');
    const [showButton, setShowButton] = useState(false);
    const { id } = useParams();

     // const [ready_to_invoice ,set_ready_to_invoice] = useState()
    // console.log(`${ready_to_invoice}`)

    const interestDate = new Date(Moment(date).format("MM/DD/YYYY"));
    const [interest, setInterest] = useState();
    const diffInDays = Math.abs((interestDate - interest) / (1000 * 60 * 60 * 24));
    const timesDaysHas30 = Math.floor(diffInDays/30)
    const interestAmount = ((current_balance_due*1.5) / 100) * timesDaysHas30

    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;
    let redirect = ''
    if (userInfo) {
        if (userInfo.group === "AdminSuper") {
            redirect = '/active_work_orders'
        } else if (userInfo.group === "Finance") {
            redirect = '/finance_active_work_orders'
        }
    }


    const loadInvoice = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`invoices/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        });
        setInterest(new Date(Moment(data.created).format("MM/DD/YYYY")))

        set_is_down_payment_made(data.is_down_payment_made);
        set_down_payment_amount(data.down_payment_amount);
        set_bill_amount(data.bill_amount);
        set_payment_plan(data.payment_plan);
        set_payment_notes(data.payment_notes);
        setOldPaymentNotes(data.payment_notes);
        set_current_balance_due(data.current_balance_due);
        set_po(data.po);
        set_work_order_number(data.work_order_number);
        set_project_id(data.project_id);
        set_payment_made_in_full(data.payment_made_in_full);
        set_priority(data.priority);
        setId(data.id);
        setClient(data.client);
    }

    // const loadChecklist = async () => {
    //     const { data } = await axios.get(`http://127.0.0.1:8000/api/check_list/${id}/`);
    //     set_ready_to_invoice(data.ready_to_invoice)
    // }

    useEffect(() => {
        loadInvoice();
        // loadChecklist();

        let timer = setInterval(()=>setDate(new Date()), 1000);
        return function cleanup(){
            clearInterval(timer);
        };
    }, []);

    function factorPaidInFull(){
        setShowPaymentMadeInFull(current => !current);
        setShowButton(current => !current);
    }


    const UpdateInvoiceInfo = async () =>{
        let formField = new FormData()
        formField.append('client', client)

        formField.append('is_down_payment_made',is_down_payment_made);
        formField.append('down_payment_amount',down_payment_amount);
        formField.append('bill_amount',bill_amount);
        formField.append('payment_plan',payment_plan);
        formField.append('payment_notes',payment_notes);

        if (newPayment > '0' && newBillAmount===0){
            formField.append('current_balance_due',current_balance_due-newPayment)
            formField.append('payment_notes', oldPaymentNotes + ` Payment of $${newPayment} Made On ${date.toLocaleDateString()} at ${date.toLocaleTimeString()}.`)
        } else if (newBillAmount > '0' && newPayment ===0){
            formField.append('bill_amount',parseInt(bill_amount)+parseInt(newBillAmount));
            formField.append('current_balance_due', parseInt(current_balance_due) + parseInt(newBillAmount))
            formField.append('payment_notes', oldPaymentNotes + ` Bill amount increased by $${newBillAmount} On ${date.toLocaleDateString()} at ${date.toLocaleTimeString()}.`)
        } else if (newPayment > '0' && newBillAmount > 0) {
            formField.append('bill_amount',parseInt(bill_amount)+parseInt(newBillAmount));
            formField.append('current_balance_due', parseInt(current_balance_due) + parseInt(newBillAmount)-parseInt(newPayment))
            formField.append('payment_notes', oldPaymentNotes + ` Payment of $${newPayment} & Bill amount increased by $${newBillAmount} On ${date.toLocaleDateString()} at ${date.toLocaleTimeString()}.`)
        } else {
            formField.append('bill_amount', bill_amount)
            formField.append('current_balance_due', current_balance_due)
        }

        // if (newPayment > '0'){
        //     formField.append('current_balance_due',current_balance_due-newPayment)
        // } else formField.append('current_balance_due', current_balance_due)
        //
        // if (newBillAmount > '0'){
        //     formField.append('bill_amount',parseInt(bill_amount)+parseInt(newBillAmount));
        //     formField.append('current_balance_due', parseInt(current_balance_due) + parseInt(newBillAmount))
        // } else formField.append('bill_amount', bill_amount)

        formField.append('po',po)
        formField.append('work_order_number',work_order_number)
        formField.append('project_id',project_id)

        if (newBillAmount + (current_balance_due-newPayment)  === 0) {
            formField.append('payment_made_in_full', "Yes")
        } else {
            formField.append('payment_made_in_full', "No")
        }
        formField.append('priority',priority)

        // if (newPayment > '0'){
        //     formField.append('payment_notes', oldPaymentNotes + ` Payment of $${newPayment} Made On ${date.toLocaleDateString()} at ${date.toLocaleTimeString()}.`)
        // }
        //
        // if (newBillAmount > '0'){
        //     formField.append('payment_notes', oldPaymentNotes + ` Bill amount increased by $${newBillAmount} On ${date.toLocaleDateString()} at ${date.toLocaleTimeString()}.`)
        // }

        await axios({
            method: 'PUT',
            //TODO if this doesnt work try changing this URL
            url: process.env.REACT_APP_API+`invoice/update/${useId}/`,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            data: formField,
        }).then(() => {
            navigate(redirect)
        })
    }

    return (
        <div className={"banner_heading"}>

            <h1>Update Invoice:</h1>

            <Form>

                <br />
                <br />
                <span className={'newPaymentSpan'}>Update Bill Amount: <input onChange={(e) => setNewBillAmount(e.target.value)}/></span>
                <br />
                <br />

                <div className={'interestPaymentSpan'}>
                {

                    payment_made_in_full==="Yes" ?
                        (
                            ("")
                        )
                        :
                        <span className={'interestPaymentSpan'}>Total Current Balance Due With interest: {current_balance_due+interestAmount}. Increase Balance Due by: {interestAmount}</span>

                }
                </div>


                <Form.Group controlId={'bill_amount'}>
                    <Form.Label>Bill Amount:</Form.Label>
                    <Form.Control
                        type={'number'}
                        placeholder={"Bill Amount"}
                        value={parseInt(bill_amount) + parseInt(newBillAmount)}
                        onChange={(e) => set_bill_amount(parseInt(bill_amount) + parseInt(newBillAmount))}
                    />
                </Form.Group>
                {/*{is_down_payment_made}*/}
                <Form.Group controlId={'is_down_payment_made'}>
                    <Form.Label>Is Down Payment Made:{is_down_payment_made}</Form.Label>

                    <Form.Select
                        value={is_down_payment_made}
                        onChange={(e) => set_is_down_payment_made(e.target.value)}
                    >
                        <option value="DEFAULT">DEFAULT</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'down_payment_amount'}>
                    <Form.Label>Down Payment Amount:</Form.Label>
                    <Form.Control
                        type={'number'}
                        placeholder={"Down Payment Amount"}
                        value={down_payment_amount}
                        onChange={(e) => set_down_payment_amount(e.target.value)}
                    />
                </Form.Group>


                    <br />
                    <br />
                    <span className={'newPaymentSpan'}>New Payment: <input onChange={(e) => setNewPayment(e.target.value)}/></span>
                    <br />
                    <br />



                {/*{current_balance_due}*/}
                <Form.Group controlId={'current_balance_due'}>
                    <Form.Label>Current Balance Due:</Form.Label>
                    <Form.Control
                        type={'number'}
                        placeholder={"Current Balance Due"}
                        value={parseInt(current_balance_due)-parseInt(newPayment) + parseInt(newBillAmount)}
                        // value={bill_amount-down_payment_amount-newPayment}
                        onChange={(e) => set_current_balance_due(parseInt(current_balance_due)-parseInt(newPayment) + parseInt(newBillAmount))}
                    />
                </Form.Group>

                {/*<Form.Group controlId={'payment_made_in_full'}>*/}
                {/*    <Form.Label>Payment made in full:</Form.Label>*/}
                {/*    <Form.Select*/}
                {/*        defaultValue={"DEFAULT"}*/}
                {/*        onChange={(e) => set_payment_made_in_full(e.target.value)}*/}
                {/*    >*/}
                {/*        <option value={'DEFAULT'} disabled>Payment Made In Full:</option>*/}
                {/*        <option value="Yes">Yes</option>*/}
                {/*        <option value="No">No</option>*/}
                {/*    </Form.Select>*/}
                {/*</Form.Group>*/}
                <br/>

                <Button variant="primary" type={"button"} onClick={()=>{
                    setMath(newPayment);
                    setMath2(newBillAmount);
                    factorPaidInFull();
                }}>Factor Paid In Full</Button>


                <br /><br />

                {showPaymentMadeInFull && (
                    <div>
                        {
                            myNumberWithTwoDecimalPlaces + mySecondNumberWithTwoDecimalPlaces === current_balance_due || parseInt(myNumberWithTwoDecimalPlaces) === parseInt(mySecondNumberWithTwoDecimalPlaces) + parseInt(current_balance_due)
                                ?
                                <Form.Group controlId={'payment_made_in_full'}>
                                    <Form.Label>Payment Made In Full:</Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder={payment_made_in_full}
                                        value={'Yes'}
                                        onChange={(e) => set_payment_made_in_full("Yes")}
                                    />
                                </Form.Group>

                                :
                                <Form.Group controlId={'payment_made_in_full'}>
                                    <Form.Label>Payment Made In Full:</Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder={payment_made_in_full}
                                        value={'No'}
                                        onChange={(e) => set_payment_made_in_full("No")}
                                    />
                                </Form.Group>
                        }
                    </div>
                )}

                <Form.Group controlId={'priority'}>
                    <Form.Label>Priority:</Form.Label>
                    <Form.Select
                        value={priority}
                        onChange={(e) => set_priority(e.target.value)}
                    >
                        <option value="Low">Low</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'payment_plan'}>
                    <Form.Label>Payment Plan:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Payment Plan"}
                        value={payment_plan}
                        onChange={(e) => set_payment_plan(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'payment_notes'}>
                    <Form.Label>Payment Notes:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Payment Notes"}
                        value={payment_notes}
                        onChange={(e) => set_payment_notes(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'po'}>
                    <Form.Label>P.O.:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"PO"}
                        value={po}
                        onChange={(e) => set_po(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'work_order_number'}>
                    <Form.Label>Work Order Number:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Work Order Number"}
                        value={work_order_number}
                        onChange={(e) => set_work_order_number(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'project_id'}>
                    <Form.Label>Project I.D.:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Project ID"}
                        value={project_id}
                        onChange={(e) => set_project_id(e.target.value)}
                    />
                </Form.Group>

                <br/>
                {showButton && (
                    <Button className={'btn btn-success'} onClick={UpdateInvoiceInfo}>Update Invoice</Button>
                )}

            </Form>


        </div>
    )
}

export default UpdateInvoice