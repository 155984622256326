import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Button, Form} from "react-bootstrap";
import {useSelector} from "react-redux";

const ClientUpdate = () => {

    let navigate = useNavigate();

    const [work_site_address, set_work_site_address] = useState('');
    const [details_for_address, set_details_for_address] = useState('N/A');
    const [project_ID, set_project_ID] = useState('');
    const [referral_name, set_referral_name] = useState('N/A');
    const [phone, set_phone] = useState('N/A');
    const [email, set_email] = useState('email@email.com');
    const [service_order, set_service_order] = useState('DEFAULT');
    const [requested_service, set_requested_service] = useState('DEFAULT');
    const [are_prints_available, set_are_prints_available] = useState('DEFAULT');
    const [client_name, set_client_name] = useState('N/A');
    const [client_phone, set_client_phone] = useState('N/A');
    const [is_it_an_environmental_firm, set_is_it_an_environmental_firm] = useState('DEFAULT');
    const [is_the_state_involved, set_is_the_state_involved] = useState('DEFAULT');
    const [additional_comments, set_additional_comments] = useState('N/A');
    const proposal_total = null
    const [new_client, setNewClient] = useState('DEFAULT')
    const [from_where, setFromWhere] = useState('N/A')
    const [from_where_description, setFromWhereDescription] = useState('N/A')
    const [organization_name, setOrganizationName] = useState('N/A')


    const [state_id, set_state_id] = useState('N/A')

    const {id} = useParams();

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const loadClients = async () => {
        const {data} = await axios.get(process.env.REACT_APP_API + `clients/update/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        });
        // console.log(data)
        set_work_site_address(data.work_site_address)
        set_details_for_address(data.details_for_address)
        set_project_ID(data.project_ID)
        set_referral_name(data.referral_name)
        set_phone(data.phone)
        set_email(data.email)
        set_service_order(data.service_order)
        set_requested_service(data.requested_service)
        set_are_prints_available(data.are_prints_available)
        set_client_name(data.client_name)
        set_client_phone(data.client_phone)
        set_is_it_an_environmental_firm(data.is_it_an_environmental_firm)
        set_is_the_state_involved(data.is_the_state_involved)
        set_additional_comments(data.additional_comments)
        setNewClient(data.new_client)
        setFromWhere(data.from_where)
        setFromWhereDescription(data.from_where_description)
        setOrganizationName(data.organization_name)

        set_state_id(data.state_id)
    }
    useEffect(() => {
        loadClients()
    }, [])

    const updateClientInfo = async () => {
        let formField = new FormData()

        formField.append('work_site_address', work_site_address)
        formField.append('details_for_address', details_for_address)
        formField.append('project_ID', project_ID)
        formField.append('referral_name', referral_name)
        formField.append('phone', phone)
        formField.append('email', email)
        formField.append('service_order', service_order)
        formField.append('requested_service', requested_service)
        formField.append('are_prints_available', are_prints_available)
        formField.append('new_client', new_client)
        formField.append('from_where', from_where)
        formField.append('from_where_description', from_where_description)
        formField.append('organization_name', organization_name)
        formField.append('client_name', client_name)
        formField.append('client_phone', client_phone)
        formField.append('is_it_an_environmental_firm', is_it_an_environmental_firm)
        formField.append('is_the_state_involved', is_the_state_involved)
        formField.append('state_id', state_id)
        formField.append('additional_comments', additional_comments)
        formField.append('proposal_total', proposal_total)

        await axios({
            method: 'PUT',
            url: process.env.REACT_APP_API + `clients/update/${id}/`,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            data: formField
        }).then(response => {
            // console.log(response.data)
            navigate('/active_work_orders')
        })
    }

    return (
        <div>
            <h1>Update {work_site_address}</h1>
            <br/>


            <Form>

                <Form.Group controlId={'work_site_address'}>
                    <Form.Control
                        type={'text'}
                        placeholder={"Required: Work Site Address:"}
                        name={'work_site_address'}
                        value={work_site_address}
                        onChange={(e) => set_work_site_address(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'details_for_address'}>
                    <Form.Control
                        type={'text'}
                        placeholder={"Details For Address"}
                        name={'details_for_address'}
                        value={details_for_address}
                        onChange={(e) => set_details_for_address(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'project_ID'}>
                    <Form.Control
                        type={'text'}
                        placeholder={"Required: Project ID:"}
                        name={'project_ID'}
                        value={project_ID}
                        onChange={(e) => set_project_ID(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'organization_name'}>
                    <Form.Label>Organization Name</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Required: Organization Name"}
                        name={'organization_name'}
                        value={organization_name}
                        onChange={(e) => setOrganizationName(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'referral_name'}>
                    <Form.Control
                        type={'text'}
                        placeholder={"Referral Name"}
                        name={'referral_name'}
                        value={referral_name}
                        onChange={(e) => set_referral_name(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'phone'}>
                    <Form.Control
                        type={'text'}
                        placeholder={"Phone Number"}
                        name={'phone'}
                        value={phone}
                        onChange={(e) => set_phone(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'email'}>
                    <Form.Control
                        type={'email'}
                        placeholder={"Email"}
                        name={'email'}
                        value={email}
                        onChange={(e) => set_email(e.target.value)}
                    />
                </Form.Group>


                <Form.Group controlId={'new_client'}>
                    <Form.Label>Is this a New Client?</Form.Label>
                    <Form.Select
                        // value={service_order}
                        onChange={(e) => setNewClient(e.target.value)}
                        value={new_client}
                    >
                        <option value="DEFAULT" disabled>Is This A New Client.</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'from_where'}>
                    <Form.Label>Where Did The New Client Hear About Us?</Form.Label>
                    <Form.Select
                        // value={service_order}
                        onChange={(e) => setFromWhere(e.target.value)}
                        value={from_where}
                    >
                        <option value="DEFAULT" disabled>Where Did The New Client Hear About Us.</option>
                        <option value="Referral">Referral</option>
                        <option value="HSS Site">Hss Site</option>
                        <option value="Elsewhere Online">Elsewhere Online</option>
                        <option value="Paper Ad">Paper Ad</option>
                        <option value="Convention/Show">Convention/Show</option>
                        <option value="Phone">Phone</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'from_where_description'}>
                    <Form.Label>Please Provide More Details On How They Heard Of Us.</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Please Provide More Details On How They Heard Of Us"}
                        name={'from_where_description'}
                        value={from_where_description}
                        onChange={(e) => setFromWhereDescription(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'client_name'}>
                    <Form.Control
                        type={'text'}
                        placeholder={"Client Name"}
                        name={'client_name'}
                        value={client_name}
                        onChange={(e) => set_client_name(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'client_phone'}>
                    <Form.Control
                        type={'text'}
                        placeholder={"Client Phone"}
                        name={'client_phone'}
                        value={client_phone}
                        onChange={(e) => set_client_phone(e.target.value)}
                    />
                </Form.Group>

                <div className="container">
                    <div className="row">
                        <div className="col-sm">

                            <Form.Group controlId={'service_order'}>
                                <Form.Label>Service Order:</Form.Label>
                                <Form.Select
                                    value={service_order}
                                    onChange={(e) => set_service_order(e.target.value)}
                                >
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </Form.Select>

                            </Form.Group>

                        </div>
                        <div className="col-sm">

                            <Form.Group controlId={'requested_service'}>
                                <Form.Label>Required: Requested Service:</Form.Label>

                                <Form.Select
                                    value={requested_service}
                                    onChange={(e) => set_requested_service(e.target.value)}
                                >
                                    <option value="Radon New Construction">Radon New Construction</option>
                                    <option value="Single Family Testing">Single Family Testing</option>
                                    <option value="Single Family Radon Mitigation">Single Family Radon Mitigation
                                    </option>
                                    <option value="Multi-Family Testing">Multi-Family Testing</option>
                                    <option value="Multi-Family Mitigation">Multi-Family Mitigation</option>
                                    <option value="VOC">VOC</option>
                                    <option value="Other">Other</option>
                                </Form.Select>
                            </Form.Group>

                        </div>
                        <div className="col-sm">

                            <Form.Group controlId={'are_prints_available'}>
                                <Form.Label>Required: Are Prints Available:</Form.Label>
                                <Form.Select
                                    value={are_prints_available}
                                    onChange={(e) => set_are_prints_available(e.target.value)}
                                >
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </Form.Select>
                            </Form.Group>

                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm">

                            <Form.Group controlId={'is_it_an_environmental_firm'}>
                                <Form.Label>Required: Is it an environmental firm:</Form.Label>
                                <Form.Select
                                    value={is_it_an_environmental_firm}
                                    onChange={(e) => set_is_it_an_environmental_firm(e.target.value)}
                                >
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </Form.Select>
                            </Form.Group>

                        </div>
                        <div className="col-sm">
                            <Form.Group controlId={'is_the_state_involved'}>
                                <Form.Label>Required: Is The State Involved:</Form.Label>

                                <Form.Select
                                    className={'form-control form-control-mg'}
                                    value={is_the_state_involved}
                                    onChange={(e) => set_is_the_state_involved(e.target.value)}
                                >
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </Form.Select>

                            </Form.Group>
                        </div>
                        <div className="col-sm">
                            <Form.Group controlId={'state_id'}>
                                <Form.Label>State ID:</Form.Label>
                                <Form.Control
                                    type={'text'}
                                    placeholder={"Required: State ID"}
                                    name={'state_id'}
                                    value={state_id}
                                    onChange={(e) => set_state_id(e.target.value)}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </div>

                <br/>

                <div className={'form-group'}>
                    <input
                        type={'text'}
                        className={'form-control form-control-mg'}
                        placeholder={"Additional Comments"}
                        name={'additional_comments'}
                        value={additional_comments}
                        onChange={(e) => set_additional_comments(e.target.value)}
                    />
                </div>
                <br/>
                <Button className={'btn btn-success'} onClick={updateClientInfo}>Update Client</Button>

            </Form>
        </div>
    );
};

export default ClientUpdate;