import React from "react";

function EmployeeTraining() {
    return (
        <div className={"banner_heading"}>
            <h1>Create Employee Training Here</h1>
            <h2>Listed Employee Training Here</h2>
        </div>
    )
}

export default EmployeeTraining