import React, {useState, useEffect} from 'react';
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const OnSiteDetail = () => {

    const [onSite, setOnSite] = useState("")

    const { id } = useParams();

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const getSingleOnSite = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`onsite/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        setOnSite(data)

        // console.log(typeof data.prints)
    }

    useEffect(()=>{
        getSingleOnSite()
    }, [])


    return (
        <div className={"banner_heading"}>
            <h1>On-Site Detail</h1>
            <div>
                <p>Height to ceiling in feet: {onSite.height_to_ceiling_in_feet}</p>
                <p>Roof Access: {onSite.roof_access}</p>
                <p>Type of Roof: {onSite.type_of_roof}</p>
                <p>Drop Ceiling: {onSite.drop_ceiling}</p>
                <p>Type of Ceiling: {onSite.type_of_ceiling}</p>
                <p>Electrical: {onSite.electrical}</p>
                <p>Soil Type: {onSite.soil_type}</p>
                <p>Soil Removal: {onSite.soil_removal}</p>
                <p>Water on Site: {onSite.water_on_site}</p>
                <p>Lights on Site: {onSite.lights_on_site}</p>
                <p>Electricity on Site: {onSite.electricity_on_site}</p>
                <p>Bathrooms on Site: {onSite.bath_room_on_site}</p>
                <p>Permit Required: {onSite.permit_required}</p>
                <p>After Completion: {onSite.after_completion}</p>
                <p>Type of Permit: {onSite.type_of_permit}</p>
                <p>Who Completed OnSite: {onSite.who_completed_onsite}</p>
                <p>OnSite Notes: {onSite.onsite_notes}</p>

                {
                    onSite.photo1 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${onSite.photo1}`}><img className={'processImages'} src={`http://127.0.0.1:8000${onSite.photo1}`} alt={'photo'}/></a>) : ("No photo 1")
                }
                <br /> <br />
                {
                    onSite.photo2 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${onSite.photo2}`}><img className={'processImages'} src={`http://127.0.0.1:8000${onSite.photo2}`} alt={'photo 2'}/></a>) : ("No photo 2")
                }
                <br/> <br />
                {
                    onSite.photo3 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${onSite.photo3}`}><img className={'processImages'} src={`http://127.0.0.1:8000${onSite.photo3}`} alt={'photo 3'}/></a>) : ("No photo 3")
                }
                <br/> <br />
                {
                    onSite.photo4 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${onSite.photo4}`}><img className={'processImages'} src={`http://127.0.0.1:8000${onSite.photo4}`} alt={"photo 4"}/></a>) : ("No photo 4")
                }
                <br/> <br />
                {
                    onSite.photo5 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${onSite.photo5}`}><img className={'processImages'} src={`http://127.0.0.1:8000${onSite.photo5}`} alt={'photo 5'}/></a>) : ("No photo 5")
                }
                <br /> <br />
                {
                    onSite.photo6 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${onSite.photo6}`}><img className={'processImages'} src={`http://127.0.0.1:8000${onSite.photo6}`} alt={'photo 6'}/></a>) : ("No photo 6")
                }
                <br /> <br />
                {
                    onSite.photo7 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${onSite.photo7}`}><img className={'processImages'} src={`http://127.0.0.1:8000${onSite.photo7}`} alt={'photo 7'}/></a>) : ("No photo 7")
                }
                <br /> <br />
                {
                    onSite.photo8 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${onSite.photo8}`}><img className={'processImages'} src={`http://127.0.0.1:8000${onSite.photo8}`} alt={'photo 8'}/></a>) : ("No photo 8")
                }
                <br /> <br />
                {
                    onSite.photo9 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${onSite.photo9}`}><img className={'processImages'} src={`http://127.0.0.1:8000${onSite.photo9}`} alt={'photo 9'}/></a>) : ("No photo 9")
                }
                <br /> <br />
                {
                    onSite.photo10 ? (<a href={process.env.REACT_HSSbackend_PHOTOPATH+`${onSite.photo10}`}><img className={'processImages'} src={`http://127.0.0.1:8000${onSite.photo10}`} alt={'photo 10'}/></a>) : ("No photo 10")
                }
                <br />
                <br />
                <Link className={'btn btn-primary'} to={`/onsite/update/${id}/`}>Update OnSite</Link>

            </div>
        </div>
    )
}

export default OnSiteDetail

