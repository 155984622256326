import React from "react";
import {Col, Nav, Row} from "react-bootstrap";
import axios from "axios";
import PivotTableUI from "react-pivottable";
import TableRenderers from "react-pivottable/TableRenderers";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import Plot from "react-plotly.js";
import CustomInvoiceNotes from "../components/CustomInvoiceNotes";
import CustomReportsVideo from "../components/CustomReportsVideo";



const PlotlyRenderers = createPlotlyRenderers(Plot);

class CustomInvoiceReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            AI: [],
        };
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API+'invoices_custom_reports/',{
            headers:{
                "Accept":'application/json',
                "Content-Type":'application/json'
            },
        })
            .then(response=>{
                this.setState({AI: response.data});
            })
    }

    render() {

        const {AI} = this.state;

        return (
            <div className={"banner_heading"}>
                <h1>Custom Invoice Reports:</h1>
                <Nav className={'reports_nav_heading'} variant="tabs" defaultActiveKey="/finance_reports">
                    <Nav.Item>
                        <Nav.Link href="/invoiced_jobs_not_paid">Not Paid Invoices</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="/paid_invoices">Paid Invoices</Nav.Link>
                        </Nav.Item>
                    </Nav.Item>
                    <Nav.Link eventKey="disabled" disabled>
                        Custom Reports
                    </Nav.Link>
                </Nav>

                <div className={'reports_nav_heading'}>
                    <Row>
                        <Col><CustomInvoiceNotes /></Col>
                        <Col><CustomReportsVideo /></Col>
                    </Row>
                </div>


                <PivotTableUI
                    data={AI}
                    onChange={s => this.setState(s)}
                    renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                    {...this.state}
                />
            </div>
        );
    }
}

export default CustomInvoiceReport