import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Button, Form} from "react-bootstrap";
import {useSelector} from "react-redux";

const CreateCheckList = () => {

    let navigate = useNavigate();

    const [client, setClient] = useState()
    const [address, setAddress] = useState()

    const [forms_link, set_forms_link] = useState('')
    const [is_premeditation_done, set_is_premeditation_done] = useState('DEFAULT')
    const [is_active_mitigation_done, set_is_active_mitigation_done] = useState('DEFAULT')
    const [is_post_mitigation_done, set_is_post_mitigation_done] = useState('DEFAULT')
    const [are_gauges_on, set_are_gauges_on] = useState('DEFAULT')
    const [is_system_labeled, set_is_system_labeled] = useState('DEFAULT')
    const [is_om_and_m_done, set_is_om_and_m_done] = useState('DEFAULT')
    const [is_electrical_done, set_is_electrical_done] = useState('DEFAULT')
    const [is_fan_on, set_is_fan_on] = useState('DEFAULT')
    const [ready_to_invoice, set_ready_to_invoice] = useState('DEFAULT')
    const [none_of_the_above_apply, set_none_of_the_above_apply] = useState('DEFAULT')
    const [job_is_fully_completed, set_job_is_fully_completed] = useState('DEFAULT')

    const { id } = useParams();

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin
    let redirect = ''
    if (userInfo){
        if (userInfo.group === "AdminSuper"){
            redirect = '/active_work_orders'
        } else if (userInfo.group === "Finance") {
            redirect = '/finance_active_work_orders'
        } else if (userInfo.group === "Logistics") {
            redirect = '/logistics_active-work_orders'
        } else if (userInfo.group === "Tech") {
            redirect = '/tech_work_orders'
        }
    }

    const loadClient = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`clients/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data.id)
        setClient(data.id)
        setAddress(data.work_site_address)
    }

    useEffect(() => {
        loadClient()
    }, []);

    const AddCheckList = async () => {

        let formField = new FormData()

        formField.append('client', client)

        formField.append('forms_link', forms_link)
        formField.append('is_premeditation_done', is_premeditation_done)
        formField.append('is_active_mitigation_done', is_active_mitigation_done)
        formField.append('is_post_mitigation_done', is_post_mitigation_done)
        formField.append('are_gauges_on', are_gauges_on)
        formField.append('is_system_labeled', is_system_labeled)
        formField.append('is_om_and_m_done', is_om_and_m_done)
        formField.append('is_electrical_done', is_electrical_done)
        formField.append('is_fan_on', is_fan_on)
        formField.append('ready_to_invoice', ready_to_invoice)
        formField.append('none_of_the_above_apply', none_of_the_above_apply)
        formField.append('job_is_fully_completed', job_is_fully_completed)

        // console.log(formField)
        await axios ({
            method: 'post',
            url: process.env.REACT_APP_API+`clients/${client}/addchecklist/`,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            data: formField,
        }).then(() => {
            navigate(redirect)
        })
    }

    return(
        <div>
            <h1>Create Check List for: {address}</h1>

            <Form>

                <Form.Group controlId={'forms_link'}>
                    <Form.Label>Forms link:</Form.Label>
                    <Form.Control
                        type={'text'}
                        value={forms_link}
                        onChange={(e) => set_forms_link(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'is_premeditation_done'}>
                    <Form.Label>Is premeditation done:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_is_premeditation_done(e.target.value)}
                    >
                        <option value={"DEFAULT"} disabled>Is Premeditation Done</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'is_active_mitigation_done'}>
                    <Form.Label>Is active mitigation done:</Form.Label>
                    <Form.Select
                        value={'DEFAULT'}
                        onChange={(e) => set_is_active_mitigation_done(e.target.value)}
                    >
                        <option value={"DEFAULT"} disabled>Is active mitigation done</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'is_post_mitigation_done'}>
                    <Form.Label>Is post mitigation done:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_is_post_mitigation_done(e.target.value)}
                    >
                        <option value={"DEFAULT"} disabled>Is post mitigation done</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'are_gauges_on'}>
                    <Form.Label>Are gauges on:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_are_gauges_on(e.target.value)}
                    >
                        <option value={"DEFAULT"} disabled>Are Gauges On:</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'is_system_labeled'}>
                    <Form.Label>Is system labeled:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_is_system_labeled(e.target.value)}
                    >
                        <option value={"DEFAULT"} disabled>Is system labeled:</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'is_om_and_m_done'}>
                    <Form.Label>Is om and m done:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_is_om_and_m_done(e.target.value)}
                    >
                        <option value={"DEFAULT"} disabled>Is ON & M done:</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'is_electrical_done'}>
                    <Form.Label>Is electrical done:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_is_electrical_done(e.target.value)}
                    >
                        <option value={"DEFAULT"} disabled>Is Electrical Done:</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'is_fan_on'}>
                    <Form.Label>Is fan on:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_is_fan_on(e.target.value)}
                    >
                        <option value={"DEFAULT"} disabled>Is Fan On:</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'ready_to_invoice'}>
                    <Form.Label>Ready to invoice:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_ready_to_invoice(e.target.value)}
                    >
                        <option value={"DEFAULT"} disabled>Ready to invoice:</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'none_of_the_above_apply'}>
                    <Form.Label>None of the above apply:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_none_of_the_above_apply(e.target.value)}
                    >
                        <option value={"DEFAULT"} disabled>None of the above apply:</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'job_is_fully_completed'}>
                    <Form.Label>Job is fully completed:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_job_is_fully_completed(e.target.value)}
                    >
                        <option value={"DEFAULT"} disabled>Job is fully completed:</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <br/>

                    <Button className={'btn btn-success'} onClick={AddCheckList}>Add Check List</Button>

            </Form>

        </div>
    );

};


export default CreateCheckList