import React, {useState, useEffect} from 'react';
import {Form, Button} from "react-bootstrap";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const UpdateCheckList = () => {

    let navigate = useNavigate();
    // const [address, setAddress] = useState()

    const [client, setClient] = useState()
    const [useId, setId] = useState()


    const [forms_link, set_forms_link] = useState('')
    const [is_premeditation_done, set_is_premeditation_done] = useState('')
    const [is_active_mitigation_done, set_is_active_mitigation_done] = useState('')
    const [is_post_mitigation_done, set_is_post_mitigation_done] = useState('')
    const [are_gauges_on, set_are_gauges_on] = useState('')
    const [is_system_labeled, set_is_system_labeled] = useState('')
    const [is_om_and_m_done, set_is_om_and_m_done] = useState('')
    const [is_electrical_done, set_is_electrical_done] = useState('')
    const [is_fan_on, set_is_fan_on] = useState('')
    const [ready_to_invoice, set_ready_to_invoice] = useState('')
    const [none_of_the_above_apply, set_none_of_the_above_apply] = useState('')
    const [job_is_fully_completed, set_job_is_fully_completed] = useState('')

    const { id } = useParams();

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin
    let redirect = ''
    if (userInfo){
        if (userInfo.group === "AdminSuper"){
            redirect = '/active_work_orders'
        } else if (userInfo.group === "Finance") {
            redirect = '/finance_active_work_orders'
        } else if (userInfo.group === "Logistics") {
            redirect = '/logistics_active-work_orders'
        } else if (userInfo.group === "Tech") {
            redirect = '/tech_work_orders'
        }
    }

    const loadChecklist = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`check_list/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        });

        set_forms_link(data.forms_link)
        set_is_premeditation_done(data.is_premeditation_done)
        set_is_active_mitigation_done(data.is_active_mitigation_done)
        set_is_post_mitigation_done(data.is_post_mitigation_done)
        set_are_gauges_on(data.are_gauges_on)
        set_is_system_labeled(data.is_system_labeled)
        set_is_om_and_m_done(data.is_om_and_m_done)
        set_is_electrical_done(data.is_electrical_done)
        set_is_fan_on(data.is_fan_on)
        set_ready_to_invoice(data.ready_to_invoice)
        set_none_of_the_above_apply(data.none_of_the_above_apply)
        set_job_is_fully_completed(data.job_is_fully_completed)

        setId(data.id)
        setClient(data.client)

        // console.log(data)
        // console.log(data.id)
        // console.log(data.client)

    }

    useEffect(() => {
        loadChecklist();
    }, [])

    const UpdateCheckListInfo = async () =>{
        let formField = new FormData()
        formField.append('client', client)

        formField.append('forms_link', forms_link)
        formField.append('is_premeditation_done', is_premeditation_done)
        formField.append('is_active_mitigation_done', is_active_mitigation_done)
        formField.append('is_post_mitigation_done', is_post_mitigation_done)
        formField.append('are_gauges_on', are_gauges_on)
        formField.append('is_system_labeled', is_system_labeled)
        formField.append('is_om_and_m_done', is_om_and_m_done)
        formField.append('is_electrical_done', is_electrical_done)
        formField.append('is_fan_on', is_fan_on)
        formField.append('ready_to_invoice', ready_to_invoice)
        formField.append('none_of_the_above_apply', none_of_the_above_apply)
        formField.append('job_is_fully_completed', job_is_fully_completed)

        await axios({
            method: 'PUT',
            //TODO if this doesnt work try changing this URL
            url: process.env.REACT_APP_API+`check_list/update/${useId}/`,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            data: formField,
        }).then(() => {
            navigate(redirect)
        })
    }


    return (
        <div className={"banner_heading"}>

            <h1>Update CheckList:</h1>

            <Form>

                <Form.Group controlId={'forms_link'}>
                    <Form.Label>Forms link:</Form.Label>
                    <Form.Control
                        type={'text'}
                        value={forms_link}
                        onChange={(e) => set_forms_link(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'is_premeditation_done'}>
                    <Form.Label>Is premeditation done:</Form.Label>
                    <Form.Select
                        value={is_premeditation_done}
                        onChange={(e) => set_is_premeditation_done(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'is_active_mitigation_done'}>
                    <Form.Label>Is active mitigation done:</Form.Label>
                    <Form.Select
                        value={is_active_mitigation_done}
                        onChange={(e) => set_is_active_mitigation_done(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'is_post_mitigation_done'}>
                    <Form.Label>Is post mitigation done:</Form.Label>
                    <Form.Select
                        value={is_post_mitigation_done}
                        onChange={(e) => set_is_post_mitigation_done(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'are_gauges_on'}>
                    <Form.Label>Are gauges on:</Form.Label>
                    <Form.Select
                        value={are_gauges_on}
                        onChange={(e) => set_are_gauges_on(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'is_system_labeled'}>
                    <Form.Label>Is system labeled:</Form.Label>
                    <Form.Select
                        value={is_system_labeled}
                        onChange={(e) => set_is_system_labeled(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'is_om_and_m_done'}>
                    <Form.Label>Is om and m done:</Form.Label>
                    <Form.Select
                        value={is_om_and_m_done}
                        onChange={(e) => set_is_om_and_m_done(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'is_electrical_done'}>
                    <Form.Label>Is electrical done:</Form.Label>
                    <Form.Select
                        value={is_electrical_done}
                        onChange={(e) => set_is_electrical_done(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'is_fan_on'}>
                    <Form.Label>Is fan on:</Form.Label>
                    <Form.Select
                        value={is_fan_on}
                        onChange={(e) => set_is_fan_on(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'ready_to_invoice'}>
                    <Form.Label>Ready to invoice:</Form.Label>
                    <Form.Select
                        value={ready_to_invoice}
                        onChange={(e) => set_ready_to_invoice(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'none_of_the_above_apply'}>
                    <Form.Label>None of the above apply:</Form.Label>
                    <Form.Select
                        value={none_of_the_above_apply}
                        onChange={(e) => set_none_of_the_above_apply(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'job_is_fully_completed'}>
                    <Form.Label>Job is fully completed:</Form.Label>
                    <Form.Select
                        value={job_is_fully_completed}
                        onChange={(e) => set_job_is_fully_completed(e.target.value)}
                    >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <br/>

                    <Button className={'btn btn-success'} onClick={UpdateCheckListInfo}>Update Checklist</Button>

            </Form>


        </div>
    )
}

export default UpdateCheckList