import React, {createRef, useEffect, useRef, useState} from "react";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import dayGridPlugin from '@fullcalendar/daygrid'
import axios from "axios";
import {ClockLoader} from "react-spinners";



class Calender extends React.Component {
    constructor() {
        super();
        this.state={
            data:[],
            jobsAreLoading: true,

            soData: [],
            soAreLoading: true,
        }
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API+'open_scheduled_jobs/',{
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({data: response.data});
                this.setState({jobsAreLoading: false});
            })

        axios.get(process.env.REACT_APP_API+'open_service_orders/',{
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({soData: response.data});
                this.setState({soAreLoading: false});
            })
    }

    // handleDateClick = (arg) => { // bind with an arrow function
    //     alert(arg.dateStr)
    //     alert('Clicked on: ' + arg.dateStr);
    //     alert('Coordinates: ' + arg.jsEvent.pageX + ',' + arg.jsEvent.pageY);
    //     alert('Current view: ' + arg.view.type);
    //     // change the day's background color just for fun
    //     arg.dayEl.style.backgroundColor = 'red';
    // }

    handleEventClick = (arg) => {
        arg.jsEvent.preventDefault(); // don't let the browser navigate

        if (arg.event.id) {
            window.open(`/scheduled/update/${arg.event.extendedProps.client}/`, "_blank");
        }
    }

render() {

const {data, jobsAreLoading , soData, soAreLoading} = this.state;


    if (jobsAreLoading || soAreLoading) {
        return <div className={'loadingSpinner'}>Loading...<br/> <ClockLoader color={'#0d00ff'}/></div>
    }

    const renderEventContent = (data) => {
        return (

           <div>
               <b>{data.event.title} | <strong>Assigned:</strong> <i>{data.event.extendedProps.who}</i></b>
           </div>
    )}


    return(
        <section>
            <div>
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        // events={data}
                        eventSources={[
                            data,
                            soData,
                        ]}
                        selectable={true}
                        editable={true}
                        eventContent={renderEventContent}
                        // dateClick={this.handleDateClick}
                        eventClick={this.handleEventClick}
                    />
            </div>
        </section>
    )
}}

export default Calender