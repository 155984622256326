import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, {useState} from "react";

function CustomProposalNotes() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Notes about reports
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Report Notes for custom Proposal reports</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        🡺 Signed reports are made up of a proposals with an approved date & a invoice bill amount more then $0.00. <br/>
                        🡺 Non-signed Proposals reports are made up of proposals without an approved date. <br/>
                        🡺 Approved Jobs are made up of proposals without and approved date & a checklist that is not done <br/>
                    </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CustomProposalNotes;