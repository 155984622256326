import React, {Component, useEffect, useState} from "react";
import {Col, Row,} from "react-bootstrap";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import Moment from 'moment'
import {useSelector} from "react-redux";

// TODO: the refresh needs a timer on it. Otherwise it just runs all the time


const ClosedClientServiceOrders = () => {
    Moment.locale('en');
    const [info, setInfo] = useState()
    const [projectID, setProjectId] = useState()
    const [address, setAddress] = useState([])

    const {id} = useParams();
    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const loadClient = async () => {
        const {data} = await axios.get(process.env.REACT_APP_API+`closed_service_orders/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        setInfo(data)
    }

    const getSingleClient = async () => {
        const {data} = await axios.get(process.env.REACT_APP_API+`clients/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        setProjectId(data.project_ID)
        setAddress(data.work_site_address)
    }

    useEffect(() => {
        loadClient()
        getSingleClient()
    }, []);



    return (
        <div>

            <Link style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop:'5px'
            }} className={'btn btn-primary'} to={`/open_service_orders/`}>Active Service Orders Dashboard</Link>

            <br /><br />
            <h1>Hours for: {address}</h1>
            <h2>Project ID: {projectID}</h2>


            {info?.map(item =>
                <Row className={'HourPerRow'} key={item.id}>
                    {/*<h5>Created On: {item.created}</h5>*/}

                    <span className={'HoursSpan'}>
                    <h5>{Moment(item.created).format("[Created On:] MM/DD/YYYY  hh:mm:ss a")}</h5>
                        {
                            item.service_order_done!=='No'
                                ?
                                (
                                    <h5>
                                        {Moment(item.finished).format("[Finished On:] MM/DD/YYYY  hh:mm:ss a")}
                                    </h5>
                                )
                                :
                                ("Job Not yet completed")
                        }
                    </span>

                    <Col>
                        <Link className={'btn btn-info'} to={`/service_order/${item.id}/`}>
                            Detail Service Order
                        </Link>
                    </Col>
                    <Col>
                        <Link className={'btn btn-warning'} to={`/service_order/update/${item.id}/`}>
                            Update Service Order
                        </Link>
                    </Col>
                </Row>
            )}
        </div>
    );

}
export default ClosedClientServiceOrders;
