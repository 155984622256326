import React, {Component,} from "react";
import {Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import {Link} from "react-router-dom";
import {ClockLoader} from "react-spinners";
import Moment from "moment";


//TODO: the refresh needs a timer on it. Otherwise it just runs all the time

export class LogisticsActiveServiceOrders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // Active Service Orders
            ASO: [],
            serviceOrderIsLoading: true,
        }
    }


    componentDidMount() {
        axios.get(process.env.REACT_APP_API+'open_service_orders/', {
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            },
        })
            .then(response => {
                this.setState({ASO: response.data});
                this.setState({serviceOrderIsLoading: false});
            })
    }


    render() {

        const {
            ASO, serviceOrderIsLoading} = this.state;

        if (serviceOrderIsLoading) {
            return <div className={'loadingSpinner'}>Loading...<br/> <ClockLoader color={'#0d00ff'}/></div>
        }

        // let activeServiceOrders = ASO.map((client) => client.client)

        return (

            <Container fluid>

                <h1 style={{textAlign: 'center'}}>Active Service Orders</h1>

                <div
                    className={ASO}
                >
                    {ASO.map((aso) =>

                        <Row
                            className={"logisticsWorkOrderRow"}
                            key={aso.id}
                        >
                            <Col>
                                <div>
                                    <h6>Created: {Moment(aso.created).format("MM/DD/YYYY")}</h6>
                                    <Link className={'btn btn-info'}
                                          to={`/service_order/${aso.id}/`}> Detail Service-Order for {aso.client_name} </Link>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <h6>Created: {Moment(aso.created).format("MM/DD/YYYY")}</h6>
                                    <Link className={'btn btn-warning'}
                                          to={`/service_order/update/${aso.id}/`}>Schedule Service-Order for {aso.client_name} </Link>
                                </div>
                            </Col>
                        </Row>
                    )}
                </div>
            </Container>
        );
    }
}

export default LogisticsActiveServiceOrders;

