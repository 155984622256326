import React from "react";
import {Row, Col, Card, Button,} from "react-bootstrap";
import { useNavigate } from 'react-router-dom'

function TechDash() {

    let navigate = useNavigate();

    return (
        <div>
            <h1 className={"banner_heading"}>Tech Dash</h1>
            <br />
            <Row>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Manage Work Orders</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Manage Active Work Orders
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ () => navigate('/tech_work_orders')}
                            >Manage Work Orders</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Manage Service Orders</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Manage Active Service Orders
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ () => {navigate('/tech_service_orders')}}
                            >Service Orders</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Calender for Scheduled Jobs</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Access to Scheduled Job Calender showing which jobs are on what dates
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ () => navigate('/calender_scheduled_jobs') }
                            >Calender: Scheduled Jobs</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <br/><br/>
            <Row>
                <Col sm={12} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Header>Safety Data Sheets</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Access to our Safety Data Sheet Database
                            </Card.Text>
                            <Button variant="primary"
                                    onClick={ () => navigate('/sds')}
                            >Safety Data Sheets</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default TechDash