import React, {useState, useEffect} from 'react';
import {Form, Button, Row, Col} from "react-bootstrap";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const UpdateServiceOrder = () => {

    let navigate = useNavigate();
    const [client, setClient] = useState()
    const [useId, setId] = useState()
    const [address, setAddress] = useState()

    const [work_to_be_done, setWorkToBeDone] = useState('')
    const [who, setByWho] = useState('')
    const title = address
    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const [hours, setHours] = useState('')
    const [minutes, setMinutes] = useState('')
    const [service_order_done, setServiceOrderDone] = useState('DEFAULT')
    const [rework, setRework] = useState('DEFAULT')
    const [service_order_comments, setServiceOrderComments] = useState('')
    const [bill_amount, setBillAmount] = useState('')
    const [payment_made_in_full, setPaymentMadeInFull] = useState('DEFAULT')
    const [ready_to_invoice, setReadyToInvoice] = useState('DEFAULT')
    const [photo_1_description, setPhoto1Description] = useState('')
    const [photo_2_description, setPhoto2Description] = useState('')
    const [photo_3_description, setPhoto3Description] = useState('')
    const [photo_4_description, setPhoto4Description] = useState('')
    const [photo_5_description, setPhoto5Description] = useState('')
    const [photo1, set_photo1] = useState(null)
    const [photo2, set_photo2] = useState(null)
    const [photo3, set_photo3] = useState(null)
    const [photo4, set_photo4] = useState(null)
    const [photo5, set_photo5] = useState(null)

    const {id} = useParams();

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin
    let redirect = ''
    if (userInfo){
        if (userInfo.group === "AdminSuper"){
            redirect = '/open_service_orders'
        } else if (userInfo.group === "Finance") {
            redirect = '/finance_dash'
        } else if (userInfo.group === "Logistics") {
            redirect = '/logistics_dash'
        } else if (userInfo.group === "Tech") {
            redirect = '/tech_service_orders'
        }
    }

    const loadWorkHour = async () => {
        const {data} = await axios.get(process.env.REACT_APP_API+`service_order/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        });

        setAddress(data.address)
        setWorkToBeDone(data.work_to_be_done)
        setByWho(data.who)
        setStart(data.start)
        setEnd(data.end)
        setHours(data.hours)
        setMinutes(data.minutes)
        setServiceOrderDone(data.service_order_done)
        setRework(data.rework)
        setServiceOrderComments(data.service_order_comments)
        setBillAmount(data.bill_amount)
        setPaymentMadeInFull(data.payment_made_in_full)
        setPhoto1Description(data.photo_1_description)
        setPhoto2Description(data.photo_2_description)
        setPhoto3Description(data.photo_3_description)
        setPhoto4Description(data.photo_4_description)
        setPhoto5Description(data.photo_5_description)
        setReadyToInvoice(data.ready_to_invoice)
        set_photo1(data.photo1)
        set_photo2(data.photo2)
        set_photo3(data.photo3)
        set_photo4(data.photo4)
        set_photo5(data.photo5)

        setId(data.id)
        setClient(data.client)

    }


    useEffect(() => {
        loadWorkHour();
    }, [])

    const UpdateSO = async () => {
        let formField = new FormData()
        if (start===null){
            formField.append('start', '')
        } else {
            formField.append('start', start)
        }
        if (end===null){
            formField.append('end', '')
        } else {
            formField.append('end', end)
        }
        formField.append('client', client)
        formField.append('work_to_be_done', work_to_be_done)
        formField.append('title', title)
        formField.append('who', who)
        formField.append('hours', hours)
        formField.append('minutes', minutes)
        formField.append('service_order_done', service_order_done)
        formField.append('rework', rework)
        formField.append('service_order_comments', service_order_comments)
        formField.append('bill_amount', bill_amount)
        formField.append('payment_made_in_full', payment_made_in_full)
        formField.append('ready_to_invoice', ready_to_invoice)
        formField.append('photo_1_description', photo_1_description)
        formField.append('photo_2_description', photo_2_description)
        formField.append('photo_3_description', photo_3_description)
        formField.append('photo_4_description', photo_4_description)
        formField.append('photo_5_description', photo_5_description)

        if (photo1 !== null){
            formField.append('photo1', photo1)
        }

        if (photo2 !== null){
            formField.append('photo2', photo2)
        }

        if (photo3 !== null){
            formField.append('photo3', photo3)
        }

        if (photo4 !== null){
            formField.append('photo4', photo4)
        }

        if (photo5 !== null){
            formField.append('photo5', photo5)
        }


            await axios({
                method: 'PUT',
                //TODO if this doesnt work try changing this URL
                url: process.env.REACT_APP_API+`service_order/update/${useId}/`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                },
                data: formField,
            }).then(() => {
                navigate(redirect)
            })
    }


    return (
        <div className={"banner_heading"}>

            <h1>Update Service Order for: {address}</h1>

            <Form>
                <Form.Group controlId={'work_to_be_done'}>
                    <Form.Label>Work that needs to be done:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Work to be done."}
                        value={work_to_be_done}
                        onChange={(e) => setWorkToBeDone(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'start'}>
                    <Form.Label>Start Date S.O. Scheduled:</Form.Label>
                    <Form.Control
                        type={'date'}
                        name={'start'}
                        value={start}
                        onChange={(e) => setStart(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'end'}>
                    <Form.Label>End Date S.O. Scheduled:</Form.Label>
                    <Form.Control
                        type={'date'}
                        name={'end'}
                        value={end}
                        onChange={(e) => setEnd(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'who'}>
                    <Form.Label>Who's Working the Service Order:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"By Who?"}
                        value={who}
                        onChange={(e) => setByWho(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'hours'}>
                    <Form.Label>Hours:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"hours"}
                        value={hours}
                        onChange={(e) => setHours(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'minutes'}>
                    <Form.Label>Minutes:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"minutes"}
                        value={minutes}
                        onChange={(e) => setMinutes(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'service_order_done'}>
                    <Form.Label>Service Order Done:</Form.Label>
                    <Form.Select
                        value={service_order_done}
                        onChange={(e) => setServiceOrderDone(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Service Order Done?</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'rework'}>
                    <Form.Label>Rework:</Form.Label>
                    <Form.Select
                        value={rework}
                        onChange={(e) => setRework(e.target.value)}
                    >
                        <option value={'DEFAULT'} disabled>Rework?</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'service_order_comments'}>
                    <Form.Label>Service Order Comments:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Service Order Comments"}
                        value={service_order_comments}
                        onChange={(e) => setServiceOrderComments(e.target.value)}
                    />
                </Form.Group>

                {userInfo.group === "AdminSuper" || userInfo.group === "Finance"
                    ?
                    (<div>
                        <Form.Group controlId={'bill_amount'}>
                            <Form.Label>Bill Amount:</Form.Label>
                            <Form.Control
                                type={'text'}
                                placeholder={"Bill Amount"}
                                value={bill_amount}
                                onChange={(e) => setBillAmount(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId={'payment_made_in_full'}>
                            <Form.Label>Payment Made In Full:</Form.Label>
                            <Form.Select
                                value={payment_made_in_full}
                                onChange={(e) => setPaymentMadeInFull(e.target.value)}
                            >
                                <option value={'DEFAULT'} disabled>Payment Made In Full?</option>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId={'ready_to_invoice'}>
                            <Form.Label>Ready To Invoice:</Form.Label>
                            <Form.Select
                                value={ready_to_invoice}
                                onChange={(e) => setReadyToInvoice(e.target.value)}
                            >
                                <option value={'DEFAULT'} disabled>Ready To Invoice?</option>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                            </Form.Select>
                        </Form.Group>
                    </div>)
                    :
                    ('')
                }

                <Form.Group controlId={'photo1'}>
                    <Form.Label>Photo 1:</Form.Label>
                    {
                        photo1 ? (<img className={'processImages'} src={process.env.REACT_APP_API+`${photo1}`} alt={'photo1'}/>) : ("No Photo 1")
                    }
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_photo1(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo_1_description'}>
                    <Form.Label>Photo 1 Description:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Photo 1 Description"}
                        value={photo_1_description}
                        onChange={(e) => setPhoto1Description(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'photo2'}>
                    {
                        photo2 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo2}`} alt={'photo2'}/>) : ("No Photo 2")
                    }
                    <Form.Label>Photo 2:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_photo2(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo_2_description'}>
                    <Form.Label>Photo 2 Description:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Photo 2 Description"}
                        value={photo_2_description}
                        onChange={(e) => setPhoto2Description(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'photo3'}>
                    {
                        photo3 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo3}`} alt={'photo3'}/>) : ("No Photo 3")
                    }
                    <Form.Label>Photo 3:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_photo3(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo_3_description'}>
                    <Form.Label>Photo 3 Description:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Photo 3 Description"}
                        value={photo_3_description}
                        onChange={(e) => setPhoto3Description(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'photo4'}>
                    {
                        photo4 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo4}`} alt={'photo4'}/>) : ("No Photo 4")
                    }
                    <Form.Label>Photo 4:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_photo4(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo_4_description'}>
                    <Form.Label>Photo 4 Description:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Photo 4 Description"}
                        value={photo_4_description}
                        onChange={(e) => setPhoto4Description(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'photo5'}>
                    {
                        photo5 ? (<img className={'processImages'} src={process.env.REACT_APP_PHOTOFORUPDATE+`${photo5}`} alt={'photo5'}/>) : ("No Photo 5")
                    }
                    <Form.Label>Photo 5:</Form.Label>
                    <Form.Control
                        type={'file'}
                        onChange={(e) => set_photo5(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'photo_5_description'}>
                    <Form.Label>Photo 5 Description:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Photo 5 Description"}
                        value={photo_5_description}
                        onChange={(e) => setPhoto5Description(e.target.value)}
                    />
                </Form.Group>

                <br/>
                {/*<Link to={'/active_work_orders'}>*/}
                <Button className={'btn btn-success'} onClick={UpdateSO}>Update Service Order</Button>
                {/*</Link>*/}
            </Form>

        </div>
    )
}

export default UpdateServiceOrder