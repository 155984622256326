import React from "react";
import {Col, Nav, Row} from "react-bootstrap";
import axios from "axios";
import PivotTableUI from "react-pivottable";
import TableRenderers from "react-pivottable/TableRenderers";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import Plot from "react-plotly.js";
import CustomInvoiceNotes from "../components/CustomInvoiceNotes";
import CustomReportsVideo from "../components/CustomReportsVideo";

const PlotlyRenderers = createPlotlyRenderers(Plot);

class CustomServiceOrderReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            AI: [],
        };
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API+'custom_serviceorder_report/',{
            headers:{
                "Accept":'application/json',
                "Content-Type":'application/json'
            },
        })
            .then(response=>{
                this.setState({AI: response.data});
            })
    }

    render() {

        const {AI} = this.state;

        return (
            <div className={"banner_heading"}>
                <h1>Custom Service Order Reports:</h1>
                <Nav variant="tabs" defaultActiveKey="/finance_reports" className={'reports_nav_heading'}>
                    <Nav.Item>
                        <Nav.Link href="/open_service_order_report">Open Service Orders</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link  href="/closed_service_orders_report">Closed Service Orders</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="disabled" disabled>Custom Reports</Nav.Link>
                    </Nav.Item>
                </Nav>


                <PivotTableUI
                    data={AI}
                    onChange={s => this.setState(s)}
                    renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                    {...this.state}
                />
            </div>
        );
    }
}

export default CustomServiceOrderReport