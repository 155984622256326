import React, {Component} from "react";
import {Col, Container, Nav, Row,} from "react-bootstrap";
import axios from "axios";
import {Link} from "react-router-dom";
import {ClockLoader} from "react-spinners";
import PriorityUnassignedNotReviewed from "../components/PriorityUnassignedNotReviewed";
import PriorityAssignedNotReviewed from "../components/PriorityAssignedNotReviewed";


//TODO: the refresh needs a timer on it. Otherwise it just runs all the time

export class UnscheduledWorkOrders extends Component {

    componentDidMount() {
        <>
        <PriorityUnassignedNotReviewed />
        <PriorityAssignedNotReviewed />
        </>
    }


    render() {

        return (

            <Container fluid>

                <Nav variant="tabs" defaultActiveKey="/active_work_orders" className={'reports_nav_heading'}>
                    <Nav.Item>
                        <Nav.Link href="/unscheduled_work_orders" eventKey="disabled" disabled>Unreviewed Work
                            Orders</Nav.Link>
                    </Nav.Item>
                    <Nav.Link href={'/logistics_active-work_orders'}>
                        Reviewed Work Orders
                    </Nav.Link>
                    <Nav.Link href={'/calender_scheduled_jobs'}>
                        Calender For Scheduled Jobs
                    </Nav.Link>
                </Nav>
                <br/>

                <div>
                    <Row>
                        <Col>
                            <h5>Priority Unassigned, Not Reviewed</h5>
                            <PriorityUnassignedNotReviewed />

                        </Col>
                        <Col>
                            <h5>Priority Assigned, Not Reviewed</h5>
                            <br />
                            <PriorityAssignedNotReviewed />
                        </Col>
                    </Row>
                </div>
            </Container>
        );
    }
}

export default UnscheduledWorkOrders;
