import React, {useState, useEffect} from 'react';
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import Moment from "moment/moment";
import {useSelector} from "react-redux";

const DetailedScheduledJob = () => {

    const [scheduledJob, setScheduledJob] = useState("")

    const { id } = useParams();
    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;

    const getScheduledJob = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`scheduled_job/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        setScheduledJob(data)

    }


    useEffect(()=>{
        getScheduledJob()
    }, [])


    return (
        <div className={"banner_heading"}>
            <Link style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop:'5px'
            }} className={'btn btn-primary'} to={`/logistics_active-work_orders/`}>Logistics Scheduling Dashboard</Link>
            <br />
            <br />


            <h1>Scheduled Job Detail: </h1>
            <div>
                <p>Job Start: {scheduledJob.start}</p>
                <p>Job End: {scheduledJob.end}</p>
                <p>Who is assigned to this job: {scheduledJob.who}</p>
                <p>Job Status: {scheduledJob.job_status}</p>
                <p>Why the job Is On Hold: {scheduledJob.why}</p>
                <p>Details for job on hold: {scheduledJob.reason}</p>
                <p>title/client: {scheduledJob.title}</p>
                <p>Job was originally scheduled on: {Moment(scheduledJob.created).format("MM/DD/YYYY,  hh:mm:ss a")}</p>
                <br />
                <br />
                <Link className={'btn btn-primary'} to={`/scheduled/update/${scheduledJob.client}/`}>Update Job Schedule</Link>
            </div>
        </div>
    )
}

export default DetailedScheduledJob