import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, {useState} from "react";

function CustomInvoiceNotes() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Notes about reports
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Report Notes for custom invoice reports</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    🡺 Paid Invoice Reports are paid in full. <br/>
                    🡺 Not Paid Invoice Reports are NOT paid in full. <br/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CustomInvoiceNotes;

