import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Button, Form} from "react-bootstrap";
import {useSelector} from "react-redux";

const CreatePreSite = () => {

    let navigate = useNavigate();

    const [client, setClient] = useState()
    const [address, setAddress] = useState()
    
    const [image, setImage] = useState(null);
    const [prints2, setPrints2] = useState(null);
    const [prints3, setPrints3] = useState(null);
    const [prints4, setPrints4] = useState(null);
    const [prints5, setPrints5] = useState(null);

    const [lock_box_or_entry_code, set_lock_box_or_entry_code] = useState('')
    const [safety_equipment_needed, set_safety_equipment_needed] = useState('')
    const [general_notes, set_general_notes] = useState('')
    const [is_property_vacant, set_is_property_vacant] = useState('DEFAULT')
    const [site_hours, set_site_hours] = useState('')
    const [onsite_contact, set_onsite_contact] = useState('')
    const [onsite_contact_phone, set_onsite_contact_phone] = useState('')
    const [onsite_contact_email, set_onsite_contact_email] = useState('')

    const { id } = useParams();

    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;
       
       const loadClient = async () => {
           const { data } = await axios.get(process.env.REACT_APP_API+`clients/${id}/`, {
               headers: {
                   'Content-type': 'application/json',
                   Authorization: `Bearer ${userInfo.token}`
               }
           })
           // console.log(data.id)
           setClient(data.id)
           setAddress(data.work_site_address)
       }

    useEffect(() => {
        loadClient()
    }, []);

    const AddPreSite = async () => {

        let formField = new FormData()

        formField.append('client', client)
        formField.append('lock_box_or_entry_code', lock_box_or_entry_code)
        formField.append('safety_equipment_needed', safety_equipment_needed)
        formField.append('general_notes', general_notes)
        formField.append('is_property_vacant', is_property_vacant)
        formField.append('site_hours', site_hours)
        formField.append('onsite_contact', onsite_contact)
        formField.append('onsite_contact_phone', onsite_contact_phone)
        formField.append('onsite_contact_email', onsite_contact_email)

        if (image !== null){
            formField.append('prints', image)
        }

        if (prints2 !== null){
            formField.append('prints2', prints2)
        }

        if (prints3 !== null){
            formField.append('prints3', prints3)
        }

        if (prints4 !== null){
            formField.append('prints4', prints4)
        }

        if (prints5 !== null){
            formField.append('prints5', prints5)
        }

        await axios({
            method: 'post',
            url: process.env.REACT_APP_API+`clients/${client}/addpresite/`,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            data: formField
        }).then( () => {
            // console.log("HELLO")
            navigate('/active_work_orders')

        })

    }

    return(
        <div>
            <h1>Create Pre-Site for: {address}</h1>

            <Form>

                <Form.Group controlId={'lock_box_or_entry_code'}>
                    <Form.Label>Lock box or entry code:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Lock Box or Entry Code"}
                        value={lock_box_or_entry_code}
                        onChange={(e) => set_lock_box_or_entry_code(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'safety_equipment_needed'}>
                    <Form.Label>Safety equipment needed:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Safety Equipment Needed"}
                        value={safety_equipment_needed}
                        onChange={(e) => set_safety_equipment_needed(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'general_notes'}>
                    <Form.Label>General Notes:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"General Notes"}
                        value={general_notes}
                        onChange={(e) => set_general_notes(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'is_property_vacant'}>
                    <Form.Label>Is Property Vacant:</Form.Label>
                    <Form.Select
                        defaultValue={'DEFAULT'}
                        onChange={(e) => set_is_property_vacant(e.target.value)}
                    >
                        <option value="DEFAULT" disabled>Is property vacant</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId={'site_hours'}>
                    <Form.Label>Site hours:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Site Hours"}
                        value={site_hours}
                        onChange={(e) => set_site_hours(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'onsite_contact'}>
                    <Form.Label>Onsite Contact:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Onsite Contact"}
                        value={onsite_contact}
                        onChange={(e) => set_onsite_contact(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'onsite_contact_phone'}>
                    <Form.Label>Onsite Contact Phone number:</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"Onsite Contact Phone"}
                        value={onsite_contact_phone}
                        onChange={(e) => set_onsite_contact_phone(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'onsite_contact_email'}>
                    <Form.Label>Onsite Contact Email::</Form.Label>
                    <Form.Control
                        type={'email'}
                        placeholder={"Onsite Contact Email"}
                        value={onsite_contact_email}
                        onChange={(e) => set_onsite_contact_email(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'prints'}>
                    <Form.Label>Prints:</Form.Label>
                    <Form.Control
                    type={'file'}
                    // value={prints}
                    // multiple
                    onChange={(e) => setImage(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'prints2'}>
                    <Form.Label>Prints 2:</Form.Label>
                    <Form.Control
                        type={'file'}
                        // value={prints}
                        // multiple
                        onChange={(e) => setPrints2(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'prints3'}>
                    <Form.Label>Prints 3:</Form.Label>
                    <Form.Control
                        type={'file'}
                        // value={prints}
                        // multiple
                        onChange={(e) => setPrints3(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'prints4'}>
                    <Form.Label>Prints 4:</Form.Label>
                    <Form.Control
                        type={'file'}
                        // value={prints}
                        // multiple
                        onChange={(e) => setPrints4(e.target.files[0])}
                    />
                </Form.Group>

                <Form.Group controlId={'prints5'}>
                    <Form.Label>Prints 5:</Form.Label>
                    <Form.Control
                        type={'file'}
                        // value={prints}
                        // multiple
                        onChange={(e) => setPrints5(e.target.files[0])}
                    />
                </Form.Group>

                <br/>

                <Button className={'btn btn-success'} onClick={AddPreSite}>Add Pre-Site</Button>

            </Form>

        </div>
    );

};


export default CreatePreSite