import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Button, Form} from "react-bootstrap";
import {useSelector} from "react-redux";

const CreateScheduleJobEvent = () => {

    let navigate = useNavigate();

    const [client, setClient] = useState()
    const [address, setAddress] = useState()

    // const [title, setTitle] = useState('')
    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const [who, setWho] = useState('')
    // const [finished, setFinished] = useState('DEFAULT')
    // const [onHold, setOnHold] = useState('DEFAULT')
    // const [reason, setReason] = useState('')

    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;

    let title = address
    let job_status = "Active"
    let why = "N/A"
    let reason = "N/A"

    const { id } = useParams();

    const loadClient = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`clients/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data.id)
        setClient(data.id)
        setAddress(data.work_site_address)
    }

    useEffect(() => {
        loadClient()
    }, []);

    const ScheduleJob = async () => {

        if (who===''){
            alert("Assign employees to job")
        } else {
            let formField = new FormData()
            formField.append('client', client)
            formField.append('title', title)
            formField.append('start', start)
            formField.append('end', end)
            formField.append('who', who)
            formField.append('job_status', job_status)
            formField.append('why', why)
            formField.append('reason', reason)

            // console.log(formField)
            await axios ({
                method: 'post',
                url: process.env.REACT_APP_API+`clients/${client}/schedule_job/`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                },
                data: formField,
            }).then(() => {
                navigate('/logistics_active-work_orders')
            })
        }
    }

    return(
        <div>
            <h1>Schedule Job for: {address}</h1>
            <div style={{background: 'lightblue', textAlign: 'center', padding: '10px'}}>
            <h3>Note: End Date is the cut off date that the job will BE DONE. So if the job is supposed to be done on the 4th, end date is the 5th.</h3>
            </div>
            <Form>

                {/*<Form.Group controlId={'title'}>*/}
                {/*    <Form.Label>Title:</Form.Label>*/}
                {/*    <Form.Control*/}
                {/*        type={'text'}*/}
                {/*        placeholder={"title"}*/}
                {/*        value={title}*/}
                {/*        onChange={(e) => setTitle(e.target.value)}*/}
                {/*    />*/}
                {/*</Form.Group>*/}

                <Form.Group controlId={'start'}>
                    <Form.Label>Start Date:</Form.Label>
                    <Form.Control
                        type={'date'}
                        placeholder={"Start Date"}
                        value={start}
                        onChange={(e) => setStart(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'end'}>
                    <Form.Label>End Date:</Form.Label>
                    <Form.Control
                        type={'date'}
                        placeholder={"End Date"}
                        value={end}
                        onChange={(e) => setEnd(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId={'who'}>
                    <Form.Label>Who's Scheduled</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={"who"}
                        value={who}
                        onChange={(e) => setWho(e.target.value)}
                    />
                </Form.Group>

                {/*<Form.Group controlId={'finished'}>*/}
                {/*    <Form.Label>Finished:</Form.Label>*/}
                {/*    <Form.Select*/}
                {/*        defaultValue={'DEFAULT'}*/}
                {/*        onChange={(e) => setFinished(e.target.value)}*/}
                {/*    >*/}
                {/*        <option value={'DEFAULT'}>Job Finished?</option>*/}
                {/*        <option value="No">No</option>*/}
                {/*        <option value="Yes">Yes</option>*/}
                {/*    </Form.Select>*/}
                {/*</Form.Group>*/}

                {/*<Form.Group controlId={'onHold'}>*/}
                {/*    <Form.Label>On Hold:</Form.Label>*/}
                {/*    <Form.Select*/}
                {/*        defaultValue={'DEFAULT'}*/}
                {/*        onChange={(e) => setOnHold(e.target.value)}*/}
                {/*    >*/}
                {/*        <option value={'DEFAULT'}>On Hold?</option>*/}
                {/*        <option value="No">No</option>*/}
                {/*        <option value="Yes">Yes</option>*/}
                {/*    </Form.Select>*/}
                {/*</Form.Group>*/}

                {/*<Form.Group controlId={'reason'}>*/}
                {/*    <Form.Label>Reason:</Form.Label>*/}
                {/*    <Form.Control*/}
                {/*        type={'text'}*/}
                {/*        placeholder={"reason"}*/}
                {/*        value={reason}*/}
                {/*        onChange={(e) => setReason(e.target.value)}*/}
                {/*    />*/}
                {/*</Form.Group>*/}


                <br/>

                <Button className={'btn btn-success'} onClick={ScheduleJob}>Schedule Job</Button>

            </Form>

        </div>
    );

};


export default CreateScheduleJobEvent