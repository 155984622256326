import React, {useState, useEffect} from 'react';
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const InvoiceDetails = () => {

    const [invoice, setInvoice] = useState("")
    const [client, setClient] = useState('')

    const { id } = useParams();
    const userLogin = useSelector(state => state.userLogin);
    const {userInfo} = userLogin;

    const getSingleInvoice = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`invoices/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        setInvoice(data)
    }

    const getSingleClient = async () => {
        const { data } = await axios.get(process.env.REACT_APP_API+`clients/${id}/`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        })
        // console.log(data)
        setClient(data)
    }

    useEffect(()=>{
        getSingleInvoice()
        getSingleClient()
    }, [])


    return (
        <div className={"banner_heading"}>
            <h1>Invoice Detail: {client.work_site_address}</h1>
            {
                client.state_id!=="N/A"
                    ?
                    (<p>State ID: {client.state_id}</p>)
                    :
                    ("No State I.D.")
            }
            <div>
                <p>Bill amount: {invoice.bill_amount}</p>
                <p>Is Down payment made: {invoice.is_down_payment_made}</p>
                <p>Down-payment amount: {invoice.down_payment_amount}</p>
                <p>Current balance due: {invoice.current_balance_due}</p>
                <p>Payment made in full: {invoice.payment_made_in_full}</p>
                <p>Priority: {invoice.priority}</p>
                <p>Payment plan: {invoice.payment_plan}</p>
                <p>P.O.: {invoice.po}</p>
                <p>Work order number: {invoice.work_order_number}</p>
                <p>Project I.D.: {invoice.project_id}</p>
                <p>Payment notes: {invoice.payment_notes}</p>
                <br />
                <Link className={'btn btn-primary'} to={`/invoice/update/${id}/`}>Update Invoice</Link>

            </div>
        </div>
    )
}

export default InvoiceDetails